import {
  NgModule, Component, ElementRef, OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, Input, Output, Renderer2, EventEmitter,
  forwardRef, ViewChild, ChangeDetectorRef, TemplateRef, ContentChildren, QueryList, ContentChild, ChangeDetectionStrategy, ViewEncapsulation
} from '@angular/core';
import { trigger, style, transition, animate, AnimationEvent } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { DomHandler, ConnectedOverlayScrollHandler } from 'primeng/dom';
import { ObjectUtils, ZIndexUtils } from 'primeng/utils';
import { SharedModule, PrimeTemplate, Footer, Header, FilterService, PrimeNGConfig, TranslationKeys, OverlayService } from 'primeng/api';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TooltipModule } from 'primeng/tooltip';
import { RippleModule } from 'primeng/ripple';

export const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent), // JPM append "Component" to class name
  multi: true
};

// See https://github.com/primefaces/primeng/blob/master/src/app/components/multiselect/multiselect.ts
// for starting source ... we want to be able to tweak header template
// JPM look for "JPM" comments throughout the source to pick up on what changed

export interface MultiSelectFilterOptions {
  filter?: (value?: any) => void;
  reset?: () => void;
}

@Component({
  selector: 'ib-multiSelectItem',  // JPM swap "p-" prefix for "ib-"
  template: `
        <li class="p-multiselect-item" (click)="onOptionClick($event)" (keydown)="onOptionKeydown($event)" [attr.aria-label]="label"
            [attr.tabindex]="disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'p-highlight': selected, 'p-disabled': disabled}" pRipple>
            <div class="p-checkbox p-component">
                <div class="p-checkbox-box" [ngClass]="{'p-highlight': selected}">
                    <span class="p-checkbox-icon" [ngClass]="{'pi pi-check': selected}"></span>
                </div>
            </div>
            <span *ngIf="!template">{{label}}</span>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
        </li>
    `,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'p-element'
  }
})
export class MultiSelectItemComponent { // JPM append "Component" to class name

  @Input() option: any;

  @Input() selected: boolean;

  @Input() label: any;

  @Input() disabled: boolean;

  @Input() itemSize: number;

  @Input() template: TemplateRef<any>;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onKeydown: EventEmitter<any> = new EventEmitter();

  onOptionClick(event: Event) {
    this.onClick.emit({
      originalEvent: event,
      option: this.option
    });
  }

  onOptionKeydown(event: Event) {
    this.onKeydown.emit({
      originalEvent: event,
      option: this.option
    });
  }
}

@Component({
  selector: 'ib-multiSelect',  // JPM swap "p-" prefix for "ib-"
  template: `
        <div #container [ngClass]="{'p-multiselect p-component':true,
            'p-multiselect-open':overlayVisible,
            'p-multiselect-chip': display === 'chip',
            'p-focus':focus,
            'p-disabled': disabled}" [ngStyle]="style" [class]="styleClass"
            (click)="onMouseclick($event,in)">
            <div class="p-hidden-accessible">
                <input #in type="text" [attr.label]="label" readonly="readonly" [attr.id]="inputId" [attr.name]="name" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
                       [disabled]="disabled" [attr.tabindex]="tabindex" (keydown)="onKeydown($event)" aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible"
                       [attr.aria-labelledby]="ariaLabelledBy" role="listbox">
            </div>
            <div class="p-multiselect-label-container" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition" [positionStyle]="tooltipPositionStyle" [tooltipStyleClass]="tooltipStyleClass">
                <div class="p-multiselect-label" [ngClass]="{'p-placeholder': valuesAsString === (defaultLabel || placeholder), 'p-multiselect-label-empty': ((valuesAsString == null || valuesAsString.length === 0) && (placeholder == null || placeholder.length === 0)), 'p-multiselect-label-wrap': selectedItemsWordwrap}"> <!--JPM use new selectedItemsWordwrap input-->
                    <ng-container *ngIf="!selectedItemsTemplate">
                        <ng-container *ngIf="display === 'comma'">{{valuesAsString || 'empty'}}</ng-container>
                        <ng-container *ngIf="display === 'chip'">
                            <div #token *ngFor="let item of value; let i = index;" class="p-multiselect-token">
                                <span class="p-multiselect-token-label">{{findLabelByValue(item)}}</span>
                                <span *ngIf="!disabled" class="p-multiselect-token-icon pi pi-times-circle" (click)="removeChip(item, $event)"></span>
                            </div>
                            <ng-container *ngIf="!value || value.length === 0">{{placeholder || defaultLabel || 'empty'}}</ng-container>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: {$implicit: value}"></ng-container>
                </div>
                <i *ngIf="value != null && filled && !disabled && showClear" class="p-multiselect-clear-icon pi pi-times" (click)="clear($event)"></i>
            </div>
            <div [ngClass]="{'p-multiselect-trigger':true}">
                <span class="p-multiselect-trigger-icon" [ngClass]="dropdownIcon"></span>
            </div>
            <!-- JPM class z2000 to help z-index work with bootstrap -->
            <div *ngIf="overlayVisible" [ngClass]="['p-multiselect-panel p-component z2000']" [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                (@overlayAnimation.done)="onOverlayAnimationEnd($event)" [ngStyle]="panelStyle" [class]="panelStyleClass" (keydown)="onKeydown($event)" (click)="onOverlayClick($event)" >
                <div class="p-multiselect-header pt-0 pb-0" style="display:block;" *ngIf="showHeader"> <!-- JPM: Move p-header outside of div with class p-multiselect-header due to flex display -->
                    <ng-content select="p-header"></ng-content> <!-- JPM: Move p-header outside of div with class p-multiselect-header due to flex display -->
                </div>
                <div class="p-multiselect-header" *ngIf="showHeader">
                    <!--<ng-content select="p-header"></ng-content>--> <!-- JPM: Move p-header outside of div with class p-multiselect-header due to flex display -->
                    <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
                    <ng-container *ngIf="filterTemplate; else builtInFilterElement">
                        <ng-container *ngTemplateOutlet="filterTemplate; context: {options: filterOptions}"></ng-container>
                    </ng-container>
                    <ng-template #builtInFilterElement>
                        <div class="p-checkbox p-component" *ngIf="showToggleAll && !selectionLimit" [ngClass]="{'p-checkbox-disabled': disabled || toggleAllDisabled}">
                            <div class="p-hidden-accessible">
                                <input type="checkbox" readonly="readonly" [checked]="allChecked" (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()" (keydown.space)="toggleAll($event)" [disabled]="disabled || toggleAllDisabled">
                            </div>
                            <div class="p-checkbox-box" role="checkbox" [attr.aria-checked]="allChecked" [ngClass]="{'p-highlight':allChecked, 'p-focus': headerCheckboxFocus, 'p-disabled': disabled || toggleAllDisabled}" (click)="toggleAll($event)">
                                <span class="p-checkbox-icon" [ngClass]="{'pi pi-check':allChecked}"></span>
                            </div>
                        </div>
                        <div class="p-multiselect-filter-container" *ngIf="filter"> <!-- JPM: n/m ... style="width: 90%;" ... Width 90% since we don't use close button -->
                            <input #filterInput type="text" [attr.autocomplete]="autocomplete" role="textbox" [value]="filterValue||''" (input)="onFilterInputChange($event)" class="p-multiselect-filter p-inputtext p-component" [disabled]="disabled" [attr.placeholder]="filterPlaceHolder" [attr.aria-label]="ariaFilterLabel">
                            <span class="p-multiselect-filter-icon pi pi-search"></span>
                        </div>
                    <!-- JPM: ngIf when !actionIcon ... old change n/m ... Hide close button -->
                    <button *ngIf="!actionIcon" class="p-multiselect-close p-link" type="button" (click)="close($event)" pRipple>
                        <span class="p-multiselect-close-icon pi pi-times"></span>
                    </button>
                    <!-- JPM: only use close button above when no custom action icon specified -->
                    <button *ngIf="actionIcon" class="p-multiselect-close p-link" type="button" (click)="fireAction($event)" pRipple>
                        <ib-icon [icon]="actionIcon" [tooltip]="actionTooltip"></ib-icon>
                    </button>
                    </ng-template>
                </div>
                <div class="p-multiselect-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">
                    <ul class="p-multiselect-items p-component" [ngClass]="{'p-multiselect-virtualscroll': virtualScroll}" role="listbox" aria-multiselectable="true">
                        <ng-container *ngIf="group">
                            <ng-template ngFor let-optgroup [ngForOf]="optionsToRender">
                                <li class="p-multiselect-item-group">
                                    <span *ngIf="!groupTemplate">{{getOptionGroupLabel(optgroup)||'empty'}}</span>
                                    <ng-container *ngTemplateOutlet="groupTemplate; context: {$implicit: optgroup}"></ng-container>
                                </li>
                                <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: getOptionGroupChildren(optgroup)}"></ng-container>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="!group">
                            <ng-container *ngTemplateOutlet="itemslist; context: {$implicit: optionsToRender}"></ng-container>
                        </ng-container>
                        <ng-template #itemslist let-optionsToDisplay let-selectedOption="selectedOption">
                            <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                                <ng-template ngFor let-option let-i="index" [ngForOf]="optionsToDisplay">
                                    <ib-multiSelectItem [option]="option" [selected]="isSelected(option)" [label]="getOptionLabel(option)" [disabled]="isOptionDisabled(option)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                            [template]="itemTemplate"></ib-multiSelectItem>
                                </ng-template>
                            </ng-container>
                            <ng-template #virtualScrollList>
                                <cdk-virtual-scroll-viewport #viewport [ngStyle]="{'height': scrollHeight}" [itemSize]="itemSize" *ngIf="virtualScroll && !emptyOptions">
                                    <ng-container *cdkVirtualFor="let option of optionsToDisplay; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                        <ib-multiSelectItem [option]="option" [selected]="isSelected(option)" [label]="getOptionLabel(option)" [disabled]="isOptionDisabled(option)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                            [template]="itemTemplate" [itemSize]="itemSize"></ib-multiSelectItem>
                                    </ng-container>
                                </cdk-virtual-scroll-viewport>
                            </ng-template>
                            <li *ngIf="hasFilter() && emptyOptions" class="p-multiselect-empty-message">
                                <ng-container *ngIf="!emptyFilterTemplate && !emptyTemplate">
                                    {{emptyFilterMessageLabel}}
                                </ng-container>
                                <ng-container *ngIf="emptyFilterTemplate || emptyTemplate">
                                  <ng-container #emptyFilter *ngTemplateOutlet="emptyFilterTemplate || emptyTemplate"></ng-container>
                                </ng-container>
                            </li>
                            <li *ngIf="!hasFilter() && emptyOptions" class="p-multiselect-empty-message">
                                <ng-container *ngIf="!emptyTemplate">
                                    {{emptyMessageLabel}}
                                </ng-container>
                                <ng-container *ngIf="emptyTemplate">
                                  <ng-container #empty *ngTemplateOutlet="emptyTemplate"></ng-container>
                                </ng-container>
                            </li>
                        </ng-template>
                    </ul>
                </div>
                <div class="p-multiselect-footer" *ngIf="footerFacet || footerTemplate">
                    <ng-content select="p-footer"></ng-content>
                    <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
                </div>
            </div>
        </div>
    `,
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scaleY(0.8)' }),
        animate('{{showTransitionParams}}')
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({ opacity: 0 }))
      ])
    ])
  ],
  host: {
    'class': 'p-element p-inputwrapper',
    '[class.p-inputwrapper-filled]': 'filled',
    '[class.p-inputwrapper-focus]': 'focus || overlayVisible',
    '[class.p-multiselect-clearable]': 'showClear && !disabled'
  },
  providers: [MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./multiselect.css']
})
export class MultiSelectComponent implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor { // JPM append "Component" to class name
  @Input() style: any;

  @Input() styleClass: string;

  @Input() panelStyle: any;

  @Input() panelStyleClass: string;

  @Input() inputId: string;

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() group: boolean;

  @Input() filter: boolean = true;

  @Input() filterPlaceHolder: string;

  @Input() filterLocale: string;

  @Input() overlayVisible: boolean;

  @Input() tabindex: number;

  @Input() appendTo: any;

  @Input() dataKey: string;

  @Input() name: string;

  @Input() label: string;

  @Input() ariaLabelledBy: string;

  @Input() displaySelectedLabel: boolean = true;

  @Input() maxSelectedLabels: number = 3;

  @Input() selectionLimit: number;

  @Input() selectedItemsLabel: string = 'ellipsis';

  // JPM: New input that will word-wrap when true
  @Input() selectedItemsWordwrap: boolean = false;

  @Input() showToggleAll: boolean = true;

  @Input() emptyFilterMessage: string = 'No results found';

  @Input() emptyMessage: string = '';

  @Input() resetFilterOnHide: boolean = false;

  // @Input() dropdownIcon: string = 'pi pi-chevron-down';
  @Input() dropdownIcon: string = 'pi pi-angle-down'; // JPM IB change chevron to caret for default icon looks more like normal drop down icon

  @Input() optionLabel: string;

  @Input() optionValue: string;

  @Input() optionDisabled: string;

  @Input() optionGroupLabel: string;

  @Input() optionGroupChildren: string = "items";

  @Input() showHeader: boolean = true;

  @Input() autoZIndex: boolean = true;

  @Input() baseZIndex: number = 0;

  @Input() filterBy: string;

  @Input() virtualScroll: boolean;

  @Input() itemSize: number;

  @Input() showTransitionOptions: string = '.12s cubic-bezier(0, 0, 0.2, 1)';

  @Input() hideTransitionOptions: string = '.1s linear';

  @Input() ariaFilterLabel: string;

  @Input() filterMatchMode: string = "contains";

  @Input() tooltip: string = '';

  @Input() tooltipPosition: string = 'right';

  @Input() tooltipPositionStyle: string = 'absolute';

  @Input() tooltipStyleClass: string;

  @Input() autofocusFilter: boolean = true;

  @Input() display: string = 'comma';

  @Input() autocomplete: string = 'on';

  @Input() showClear: boolean = false;

  // JPM custom action icon, tooltip, and action event
  @Input() actionIcon: string = "";
  @Input() actionTooltip: string = "";
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  // JPM method to fire onAction event
  fireAction($event) {
    this.onAction.emit($event);
  }

  @ViewChild('container') containerViewChild: ElementRef;

  @ViewChild('filterInput') filterInputChild: ElementRef;

  @ViewChild('in') accessibleViewChild: ElementRef;

  @ContentChild(Footer) footerFacet;

  @ContentChild(Header) headerFacet;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onClear: EventEmitter<any> = new EventEmitter();

  @Output() onPanelShow: EventEmitter<any> = new EventEmitter();

  @Output() onPanelHide: EventEmitter<any> = new EventEmitter();

  @Input() scrollHeight: string = '200px';

  _defaultLabel: string;

  @Input() set defaultLabel(val: string) {
    this._defaultLabel = val;
    this.updateLabel();
  }

  get defaultLabel(): string {
    return this._defaultLabel;
  }

  _placeholder: string;

  @Input() set placeholder(val: string) {
    this._placeholder = val;
    this.updateLabel();
  }

  get placeholder(): string {
    return this._placeholder;
  }

  @Input() get options(): any[] {
    return this._options;
  }

  set options(val: any[]) {
    this._options = val;
    this.updateLabel();
  }

  @Input() get filterValue(): string {
    return this._filterValue;
  }

  set filterValue(val: string) {
    this._filterValue = val;
    this.activateFilter();
  }

  public value: any[];

  public _filteredOptions: any[];

  public onModelChange: Function = () => { };

  public onModelTouched: Function = () => { };

  overlay: HTMLDivElement;

  public valuesAsString: string;

  public focus: boolean;

  filled: boolean;

  public documentClickListener: any;

  public _filterValue: string;

  public filtered: boolean;

  public itemTemplate: TemplateRef<any>;

  public groupTemplate: TemplateRef<any>;

  public headerTemplate: TemplateRef<any>;

  public filterTemplate: TemplateRef<any>;

  public footerTemplate: TemplateRef<any>;

  public emptyFilterTemplate: TemplateRef<any>;

  public emptyTemplate: TemplateRef<any>;

  public selectedItemsTemplate: TemplateRef<any>;

  public headerCheckboxFocus: boolean;

  filterOptions: MultiSelectFilterOptions;

  _options: any[];

  maxSelectionLimitReached: boolean;

  scrollHandler: any;

  documentResizeListener: any;

  preventModelTouched: boolean;

  preventDocumentDefault: boolean;

  constructor(public el: ElementRef, public renderer: Renderer2, public cd: ChangeDetectorRef, public filterService: FilterService, public config: PrimeNGConfig, public overlayService: OverlayService) { }

  ngOnInit() {
    this.updateLabel();

    if (this.filterBy) {
      this.filterOptions = {
        filter: (value) => this.onFilterInputChange(value),
        reset: () => this.resetFilter()
      };
    }
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'group':
          this.groupTemplate = item.template;
          break;

        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;

        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'filter':
          this.filterTemplate = item.template;
          break;

        case 'emptyfilter':
          this.emptyFilterTemplate = item.template;
          break;

        case 'empty':
          this.emptyTemplate = item.template;
          break;

        case 'footer':
          this.footerTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  ngAfterViewInit() {
    if (this.overlayVisible) {
      this.show();
    }
  }

  ngAfterViewChecked() {
    if (this.filtered) {
      this.alignOverlay();

      this.filtered = false;
    }
  }

  getOptionLabel(option: any) {
    return this.optionLabel ? ObjectUtils.resolveFieldData(option, this.optionLabel) : (option.label != undefined ? option.label : option);
  }

  getOptionValue(option: any) {
    return this.optionValue ? ObjectUtils.resolveFieldData(option, this.optionValue) : (this.optionLabel || option.value === undefined ? option : option.value);
  }

  getOptionGroupLabel(optionGroup: any) {
    return this.optionGroupLabel ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupLabel) : (optionGroup.label != undefined ? optionGroup.label : optionGroup);
  }

  getOptionGroupChildren(optionGroup: any) {
    return this.optionGroupChildren ? ObjectUtils.resolveFieldData(optionGroup, this.optionGroupChildren) : optionGroup.items;
  }

  isOptionDisabled(option: any) {
    const disabled = this.optionDisabled ? ObjectUtils.resolveFieldData(option, this.optionDisabled) : (option.disabled !== undefined ? option.disabled : false);
    return (disabled || (this.maxSelectionLimitReached && !this.isSelected(option)));
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateLabel();
    this.updateFilledState();
    this.checkSelectionLimit();

    this.cd.markForCheck();
  }

  checkSelectionLimit() {
    if (this.selectionLimit && (this.value && this.value.length === this.selectionLimit)) {
      this.maxSelectionLimitReached = true;
    } else {
      this.maxSelectionLimitReached = false;
    }
  }

  updateFilledState() {
    this.filled = (this.value && this.value.length > 0);
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
    this.cd.markForCheck();
  }

  onOptionClick(event) {
    const option = event.option;
    if (this.isOptionDisabled(option)) {
      return;
    }

    const optionValue = this.getOptionValue(option);
    const selectionIndex = this.findSelectionIndex(optionValue);
    if (selectionIndex != -1) {
      this.value = this.value.filter((val, i) => i != selectionIndex);

      if (this.selectionLimit) {
        this.maxSelectionLimitReached = false;
      }
    } else {
      if (!this.selectionLimit || (!this.value || this.value.length < this.selectionLimit)) {
        this.value = [...this.value || [], optionValue];
      }

      this.checkSelectionLimit();
    }

    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event.originalEvent, value: this.value, itemValue: optionValue });
    this.updateLabel();
    this.updateFilledState();
  }

  isSelected(option) {
    return this.findSelectionIndex(this.getOptionValue(option)) != -1;
  }

  findSelectionIndex(val: any): number {
    let index = -1;

    if (this.value) {
      for (let i = 0; i < this.value.length; i++) {
        if (ObjectUtils.equals(this.value[i], val, this.dataKey)) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  get toggleAllDisabled(): boolean {
    const optionsToRender = this.optionsToRender;
    if (!optionsToRender || optionsToRender.length === 0) {
      return true;
    } else {
      for (const option of optionsToRender) {
        if (!this.isOptionDisabled(option)) { return false; }
      }

      return true;
    }
  }

  toggleAll(event) {
    if (this.disabled || this.toggleAllDisabled || this.readonly) {
      return;
    }

    const allChecked = this.allChecked;

    if (allChecked) {
      this.uncheckAll();
    } else { this.checkAll(); }

    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event, value: this.value });
    this.updateFilledState();
    this.updateLabel();
    event.preventDefault();
  }

  checkAll() {
    const optionsToRender = this.optionsToRender;
    const val: any[] = [];

    optionsToRender.forEach(opt => {
      if (!this.group) {
        const optionDisabled = this.isOptionDisabled(opt);
        if (!optionDisabled || (optionDisabled && this.isSelected(opt))) {
          val.push(this.getOptionValue(opt));
        }
      } else {
        const subOptions = this.getOptionGroupChildren(opt);

        if (subOptions) {
          subOptions.forEach(option => {
            const optionDisabled = this.isOptionDisabled(option);
            if (!optionDisabled || (optionDisabled && this.isSelected(option))) {
              val.push(this.getOptionValue(option));
            }
          });
        }
      }
    });

    this.value = val;
  }

  uncheckAll() {
    const optionsToRender = this.optionsToRender;
    const val: any[] = [];

    optionsToRender.forEach(opt => {
      if (!this.group) {
        const optionDisabled = this.isOptionDisabled(opt);
        if (optionDisabled && this.isSelected(opt)) {
          val.push(this.getOptionValue(opt));
        }
      } else {
        if (opt.items) {
          opt.items.forEach(option => {
            const optionDisabled = this.isOptionDisabled(option);
            if (optionDisabled && this.isSelected(option)) {
              val.push(this.getOptionValue(option));
            }
          });
        }
      }
    });

    this.value = val;
  }

  show() {
    if (!this.overlayVisible) {
      this.overlayVisible = true;
      this.preventDocumentDefault = true;
      this.cd.markForCheck();
    }
  }

  onOverlayClick(event) {
    this.overlayService.add({
      originalEvent: event,
      target: this.el.nativeElement
    });
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        this.appendOverlay();
        if (this.autoZIndex) {
          ZIndexUtils.set('overlay', this.overlay, this.baseZIndex + this.config.zIndex.overlay);
        }
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();
        this.bindScrollListener();

        if (this.filterInputChild && this.filterInputChild.nativeElement) {
          this.preventModelTouched = true;

          if (this.autofocusFilter) {
            this.filterInputChild.nativeElement.focus();
          }
        }

        this.onPanelShow.emit();
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  onOverlayAnimationEnd(event: AnimationEvent) {
    switch (event.toState) {
      case 'void':
        ZIndexUtils.clear(event.element);
        break;
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.overlay);
      } else { DomHandler.appendChild(this.overlay, this.appendTo); }

      if (!this.overlay.style.minWidth) {
        this.overlay.style.minWidth = DomHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
      }
    }
  }

  restoreOverlayAppend() {
    if (this.overlay && this.appendTo) {
      this.el.nativeElement.appendChild(this.overlay);
    }
  }

  alignOverlay() {
    if (this.overlay) {
      if (this.appendTo) {
        DomHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);
      } else { DomHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement); }
    }
  }

  hide() {
    this.overlayVisible = false;
    this.unbindDocumentClickListener();
    if (this.resetFilterOnHide) {
      this.resetFilter();
    }
    this.onPanelHide.emit();
    this.cd.markForCheck();
  }

  resetFilter() {
    if (this.filterInputChild && this.filterInputChild.nativeElement) {
      this.filterInputChild.nativeElement.value = '';
    }

    this._filterValue = null;
    this._filteredOptions = null;
  }

  close(event) {
    this.hide();
    event.preventDefault();
    event.stopPropagation();
  }

  clear(event) {
    this.value = null;
    this.updateLabel();
    this.updateFilledState();
    this.onClear.emit();
    this.onModelChange(this.value);
    event.stopPropagation();
  }

  onMouseclick(event: MouseEvent, input) {
    if (this.disabled || this.readonly || (<Node>event.target).isSameNode(this.accessibleViewChild.nativeElement)) {
      return;
    }

    this.onClick.emit(event);

    if (!this.isOverlayClick(event) && !DomHandler.hasClass(event.target, 'p-multiselect-token-icon')) {
      if (this.overlayVisible) {
        this.hide();
      } else {
        input.focus();
        this.show();
      }
    }
  }

  removeChip(chip: any, event: MouseEvent) {
    this.value = this.value.filter(val => !ObjectUtils.equals(val, chip, this.dataKey));
    this.onModelChange(this.value);
    this.checkSelectionLimit();
    this.onChange.emit({ originalEvent: event, value: this.value, itemValue: chip });
    this.updateLabel();
    this.updateFilledState();
  }

  isOverlayClick(event: MouseEvent) {
    const targetNode = <Node>event.target;
    return this.overlay ? (this.overlay.isSameNode(targetNode) || this.overlay.contains(targetNode)) : false;
  }

  isOutsideClicked(event: MouseEvent): boolean {
    return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target) || this.isOverlayClick(event));
  }

  onInputFocus(event) {
    this.focus = true;
    this.onFocus.emit({ originalEvent: event });
  }

  onInputBlur(event) {
    this.focus = false;
    this.onBlur.emit({ originalEvent: event });

    if (!this.preventModelTouched) {
      this.onModelTouched();
    }
    this.preventModelTouched = false;
  }

  onOptionKeydown(event) {
    if (this.readonly) {
      return;
    }

    switch (event.originalEvent.which) {

      // down
      case 40:
        var nextItem = this.findNextItem(event.originalEvent.target.parentElement);
        if (nextItem) {
          nextItem.focus();
        }

        event.originalEvent.preventDefault();
        break;

      // up
      case 38:
        var prevItem = this.findPrevItem(event.originalEvent.target.parentElement);
        if (prevItem) {
          prevItem.focus();
        }

        event.originalEvent.preventDefault();
        break;

      // enter
      case 13:
        this.onOptionClick(event);
        event.originalEvent.preventDefault();
        break;

      case 9:
        this.hide();
        break;
    }
  }

  findNextItem(item) {
    const nextItem = item.nextElementSibling;

    if (nextItem) {
      return DomHandler.hasClass(nextItem.children[0], 'p-disabled') || DomHandler.isHidden(nextItem.children[0]) || DomHandler.hasClass(nextItem, 'p-multiselect-item-group') ? this.findNextItem(nextItem) : nextItem.children[0];
    } else {
      return null;
    }
  }

  findPrevItem(item) {
    const prevItem = item.previousElementSibling;

    if (prevItem) {
      return DomHandler.hasClass(prevItem.children[0], 'p-disabled') || DomHandler.isHidden(prevItem.children[0]) || DomHandler.hasClass(prevItem, 'p-multiselect-item-group') ? this.findPrevItem(prevItem) : prevItem.children[0];
    } else {
      return null;
    }
  }

  onKeydown(event: KeyboardEvent) {
    switch (event.which) {
      // down
      case 40:
        if (!this.overlayVisible && event.altKey) {
          this.show();
          event.preventDefault();
        }
        break;

      // space
      case 32:
        if (!this.overlayVisible) {
          this.show();
          event.preventDefault();
        }
        break;

      // escape
      case 27:
        this.hide();
        break;
    }
  }

  updateLabel() {
    if (this.value && this.options && this.value.length && this.displaySelectedLabel) {
      let label = '';
      for (let i = 0; i < this.value.length; i++) {
        const itemLabel = this.findLabelByValue(this.value[i]);
        if (itemLabel) {
          if (label.length > 0) {
            label = label + ', ';
          }
          label = label + itemLabel;
        }
      }

      if (this.value.length <= this.maxSelectedLabels || this.selectedItemsLabel === 'ellipsis') {
        this.valuesAsString = label;
      } else {
        const pattern = /{(.*?)}/;
        if (pattern.test(this.selectedItemsLabel)) {
          this.valuesAsString = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
        } else {
          this.valuesAsString = this.selectedItemsLabel;
        }
      }
    } else {
      this.valuesAsString = this.placeholder || this.defaultLabel;
    }
  }

  findLabelByValue(val: any): string {
    if (this.group) {
      let label = null;

      for (let i = 0; i < this.options.length; i++) {
        const subOptions = this.getOptionGroupChildren(this.options[i]);
        if (subOptions) {
          label = this.searchLabelByValue(val, subOptions);

          if (label) {
            break;
          }
        }
      }

      return label;
    } else {
      return this.searchLabelByValue(val, this.options);
    }
  }

  searchLabelByValue(val: any, options: any[]): string {
    let label = null;

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const optionValue = this.getOptionValue(option);

      if (val == null && optionValue == null || ObjectUtils.equals(val, optionValue, this.dataKey)) {
        label = this.getOptionLabel(option);
        break;
      }
    }

    return label;
  }

  get allChecked(): boolean {
    const optionsToRender = this.optionsToRender;
    if (!optionsToRender || optionsToRender.length === 0) {
      return false;
    } else {
      let selectedDisabledItemsLength = 0;
      let unselectedDisabledItemsLength = 0;
      let selectedEnabledItemsLength = 0;
      let visibleOptionsLength = this.group ? 0 : this.optionsToRender.length;

      for (const option of optionsToRender) {
        if (!this.group) {
          const disabled = this.isOptionDisabled(option);
          const selected = this.isSelected(option);

          if (disabled) {
            if (selected) {
              selectedDisabledItemsLength++;
            } else {
              unselectedDisabledItemsLength++;
            }
          } else {
            if (selected) {
              selectedEnabledItemsLength++;
            } else {
              return false;
            }
          }
        } else {
          for (const opt of this.getOptionGroupChildren(option)) {
            const disabled = this.isOptionDisabled(opt);
            const selected = this.isSelected(opt);

            if (disabled) {
              if (selected) {
                selectedDisabledItemsLength++;
              } else {
                unselectedDisabledItemsLength++;
              }
            } else {
              if (selected) {
                selectedEnabledItemsLength++;
              } else {
                return false;
              }
            }

            visibleOptionsLength++;
          }
        }
      }

      return (visibleOptionsLength === selectedDisabledItemsLength
        || visibleOptionsLength === selectedEnabledItemsLength
        || selectedEnabledItemsLength && visibleOptionsLength === (selectedEnabledItemsLength + unselectedDisabledItemsLength + selectedDisabledItemsLength));
    }
  }

  get optionsToRender(): any[] {
    return this._filteredOptions || this.options;
  }

  get emptyOptions(): boolean {
    const optionsToRender = this.optionsToRender;
    return !optionsToRender || optionsToRender.length === 0;
  }

  get emptyMessageLabel(): string {
    return this.emptyMessage || this.config.getTranslation(TranslationKeys.EMPTY_MESSAGE);
  }

  get emptyFilterMessageLabel(): string {
    return this.emptyFilterMessage || this.config.getTranslation(TranslationKeys.EMPTY_FILTER_MESSAGE);
  }

  hasFilter() {
    return this._filterValue && this._filterValue.trim().length > 0;
  }

  onFilterInputChange(event) {
    this._filterValue = (<HTMLInputElement>event.target).value;
    this.activateFilter();
    this.onFilter.emit({ originalEvent: event, filter: this._filterValue });
    this.cd.detectChanges();
    this.alignOverlay();
  }

  activateFilter() {
    if (this.hasFilter() && this._options) {
      const searchFields: string[] = (this.filterBy || this.optionLabel || 'label').split(',');

      if (this.group) {
        const filteredGroups = [];
        for (const optgroup of this.options) {
          const filteredSubOptions = this.filterService.filter(this.getOptionGroupChildren(optgroup), searchFields, this.filterValue, this.filterMatchMode, this.filterLocale);
          if (filteredSubOptions && filteredSubOptions.length) {
            filteredGroups.push({ ...optgroup, ...{ [this.optionGroupChildren]: filteredSubOptions } });
          }
        }

        this._filteredOptions = filteredGroups;
      } else {
        this._filteredOptions = this.filterService.filter(this.options, searchFields, this._filterValue, this.filterMatchMode, this.filterLocale);
      }
    } else {
      this._filteredOptions = null;
    }
  }

  onHeaderCheckboxFocus() {
    this.headerCheckboxFocus = true;
  }

  onHeaderCheckboxBlur() {
    this.headerCheckboxFocus = false;
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

      this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
        if (!this.preventDocumentDefault && this.isOutsideClicked(event)) {
          this.hide();
        }

        this.preventDocumentDefault = false;
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  onWindowResize() {
    if (this.overlayVisible && !DomHandler.isTouchDevice()) {
      this.hide();
    }
  }

  bindScrollListener() {
    if (!this.scrollHandler) {
      this.scrollHandler = new ConnectedOverlayScrollHandler(this.containerViewChild.nativeElement, () => {
        if (this.overlayVisible) {
          this.hide();
        }
      });
    }

    this.scrollHandler.bindScrollListener();
  }

  unbindScrollListener() {
    if (this.scrollHandler) {
      this.scrollHandler.unbindScrollListener();
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.unbindScrollListener();
    this.overlay = null;
    this.onModelTouched();
  }

  ngOnDestroy() {
    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }

    if (this.overlay) {
      ZIndexUtils.clear(this.overlay);
    }

    this.restoreOverlayAppend();
    this.onOverlayHide();
  }

}

// JPM: Wrap in our own module
// @NgModule({
//  imports: [CommonModule, SharedModule, ScrollingModule, TooltipModule, RippleModule],
//  exports: [MultiSelect, SharedModule, ScrollingModule],
//  declarations: [MultiSelect, MultiSelectItem]
// })
// export class MultiSelectModule { }


// Previous version:
/*
import {
  NgModule, Component, ElementRef, OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, Input, Output, Renderer2, EventEmitter,
  forwardRef, ViewChild, ChangeDetectorRef, TemplateRef, ContentChildren, QueryList, ContentChild, ChangeDetectionStrategy
} from '@angular/core';
import { trigger, state, style, transition, animate, AnimationEvent } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { ObjectUtils } from 'primeng/utils';
import { SharedModule, PrimeTemplate, Footer, Header } from 'primeng/api';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterUtils } from 'primeng/utils';
import { TooltipModule } from 'primeng/tooltip';

export const MULTISELECT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MultiSelectComponent),
  multi: true
};


// See https://github.com/primefaces/primeng/blob/master/src/app/components/multiselect/multiselect.ts
// for starting source ... we want to be able to tweak header template
// JPM look for "JPM" comments throughout the source to pick up on what changed


@Component({
  selector: 'ib-multiSelectItem',
  template: `
        <li class="ui-multiselect-item ui-corner-all" (click)="onOptionClick($event)" (keydown)="onOptionKeydown($event)" [attr.aria-label]="option.label"
            [style.display]="visible ? 'block' : 'none'" [attr.tabindex]="option.disabled ? null : '0'" [ngStyle]="{'height': itemSize + 'px'}"
            [ngClass]="{'ui-state-highlight': selected, 'ui-state-disabled': (option.disabled || (maxSelectionLimitReached && !selected))}">
            <div class="ui-chkbox ui-widget">
                <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"
                    [ngClass]="{'ui-state-active': selected}">
                    <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check': selected}"></span>
                </div>
            </div>
            <span *ngIf="!template">{{option.label}}</span>
            <ng-container *ngTemplateOutlet="template; context: {$implicit: option}"></ng-container>
        </li>
    `
})
export class MultiSelectItemComponent { // JPM append "Component" to class name

  @Input() option: any;

  @Input() selected: boolean;

  @Input() disabled: boolean;

  @Input() visible: boolean;

  @Input() itemSize: number;

  @Input() template: TemplateRef<any>;

  @Input() maxSelectionLimitReached: boolean;

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onKeydown: EventEmitter<any> = new EventEmitter();

  onOptionClick(event: Event) {
    this.onClick.emit({
      originalEvent: event,
      option: this.option
    });
  }

  onOptionKeydown(event: Event) {
    this.onKeydown.emit({
      originalEvent: event,
      option: this.option
    });
  }
}

@Component({
  selector: 'ib-multiSelect',
  template: `
        <div #container [ngClass]="{'ui-multiselect ui-widget ui-state-default ui-corner-all':true,'ui-multiselect-open':overlayVisible,'ui-state-focus':focus,'ui-state-disabled': disabled}" [ngStyle]="style" [class]="styleClass"
            (click)="onMouseclick($event,in)">
            <div class="ui-helper-hidden-accessible">
                <input #in type="text" readonly="readonly" [attr.id]="inputId" [attr.name]="name" (focus)="onInputFocus($event)" (blur)="onInputBlur($event)"
                       [disabled]="disabled" [attr.tabindex]="tabindex" (keydown)="onKeydown($event)" aria-haspopup="listbox" [attr.aria-expanded]="overlayVisible"
                       [attr.aria-labelledby]="ariaLabelledBy" role="listbox">
            </div>
            <div class="ui-multiselect-label-container" [pTooltip]="tooltip" [tooltipPosition]="tooltipPosition" [positionStyle]="tooltipPositionStyle" [tooltipStyleClass]="tooltipStyleClass">
                <span class="ui-multiselect-label ui-corner-all" [ngClass]="{'ui-multiselect-label-wrap': selectedItemsWordwrap}">
                    <ng-container *ngIf="!selectedItemsTemplate">{{valuesAsString}}</ng-container>
                    <ng-container *ngTemplateOutlet="selectedItemsTemplate; context: {$implicit: value}"></ng-container>
                </span>
            </div>
            <div [ngClass]="{'ui-multiselect-trigger ui-state-default ui-corner-right':true}">
                <span class="ui-multiselect-trigger-icon ui-clickable" [ngClass]="dropdownIcon" style="color:#495057;"></span> <!-- JPM: Style color -->
            </div>
            <div *ngIf="overlayVisible" [ngClass]="['ui-multiselect-panel ui-widget ui-widget-content ui-corner-all ui-shadow']" [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}" (@overlayAnimation.start)="onOverlayAnimationStart($event)"
                [ngStyle]="panelStyle" [class]="panelStyleClass" (click)="panelClick=true" (keydown)="onKeydown($event)">
                <div class="ui-widget-header ui-corner-all ui-multiselect-header ui-helper-clearfix" [ngClass]="{'ui-multiselect-header-no-toggleall': !showToggleAll}" *ngIf="showHeader">
                <ng-content select="p-header"></ng-content>
                <div class="ui-chkbox ui-widget" *ngIf="showToggleAll && !selectionLimit">
                        <div class="ui-helper-hidden-accessible">
                            <input type="checkbox" readonly="readonly" [checked]="isAllChecked()" (focus)="onHeaderCheckboxFocus()" (blur)="onHeaderCheckboxBlur()" (keydown.space)="toggleAll($event)">
                        </div>
                        <div class="ui-chkbox-box ui-widget ui-corner-all ui-state-default" role="checkbox" [attr.aria-checked]="isAllChecked()" [ngClass]="{'ui-state-active':isAllChecked(), 'ui-state-focus': headerCheckboxFocus}" (click)="toggleAll($event)">
                            <span class="ui-chkbox-icon ui-clickable" [ngClass]="{'pi pi-check':isAllChecked()}"></span>
                        </div>
                    </div>
                    <div class="ui-multiselect-filter-container" *ngIf="filter" style="width: 90%;"> <!-- JPM: Width 90% since we don't use close button -->
                        <input #filterInput type="text" role="textbox" [value]="filterValue||''" (input)="onFilter()" class="ui-inputtext ui-widget ui-state-default ui-corner-all" [attr.placeholder]="filterPlaceHolder" [attr.aria-label]="ariaFilterLabel">
                        <span class="ui-multiselect-filter-icon pi pi-search"></span>
                    </div>
                    <!-- JPM: Hide close button -->
                    <!-- <a class="ui-multiselect-close ui-corner-all" tabindex="0" (click)="close($event)" (keydown.enter)="close($event)">
                        <span class="pi pi-times"></span>
                    </a> -->
                </div>
                <div class="ui-multiselect-items-wrapper" [style.max-height]="virtualScroll ? 'auto' : (scrollHeight||'auto')">
                    <ul class="ui-multiselect-items ui-multiselect-list ui-widget-content ui-widget ui-corner-all ui-helper-reset" role="listbox" aria-multiselectable="true">
                        <ng-container *ngIf="!virtualScroll; else virtualScrollList">
                            <ng-template ngFor let-option let-i="index" [ngForOf]="options">
                                <ib-multiSelectItem [option]="option" [selected]="isSelected(option.value)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                        [maxSelectionLimitReached]="maxSelectionLimitReached" [visible]="isItemVisible(option)" [template]="itemTemplate"></ib-multiSelectItem>
                            </ng-template>
                        </ng-container>
                        <ng-template #virtualScrollList>
                            <cdk-virtual-scroll-viewport #viewport [ngStyle]="{'height': scrollHeight}" [itemSize]="itemSize" *ngIf="virtualScroll && visibleOptions && visibleOptions.length">
                                <ng-container *cdkVirtualFor="let option of visibleOptions; let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">
                                    <ib-multiSelectItem [option]="option" [selected]="isSelected(option.value)" (onClick)="onOptionClick($event)" (onKeydown)="onOptionKeydown($event)"
                                        [maxSelectionLimitReached]="maxSelectionLimitReached" [visible]="isItemVisible(option)" [template]="itemTemplate" [itemSize]="itemSize"></ib-multiSelectItem>
                                </ng-container>
                            </cdk-virtual-scroll-viewport>
                        </ng-template>
                        <li *ngIf="filter && visibleOptions && visibleOptions.length === 0" class="ui-multiselect-empty-message">{{emptyFilterMessage}}</li>
                    </ul>
                </div>
                <div class="ui-multiselect-footer ui-widget-content" *ngIf="footerFacet">
                    <ng-content select="p-footer"></ng-content>
                </div>
            </div>
        </div>
    `,
  animations: [
    trigger('overlayAnimation', [
      state('void', style({
        transform: 'translateY(5%)',
        opacity: 0
      })),
      state('visible', style({
        transform: 'translateY(0)',
        opacity: 1
      })),
      transition('void => visible', animate('{{showTransitionParams}}')),
      transition('visible => void', animate('{{hideTransitionParams}}'))
    ])
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus'
  },
  providers: [MULTISELECT_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MultiSelectComponent implements OnInit, AfterViewInit, AfterContentInit, AfterViewChecked, OnDestroy, ControlValueAccessor { // JPM append "Component" to class name

  @Input() scrollHeight: string = '200px';

  _defaultLabel: string = 'Choose';

  @Input() set defaultLabel(val: string) {
    this._defaultLabel = val;
    this.updateLabel();
  }

  get defaultLabel(): string {
    return this._defaultLabel;
  }

  @Input() style: any;

  @Input() styleClass: string;

  @Input() panelStyle: any;

  @Input() panelStyleClass: string;

  @Input() inputId: string;

  @Input() disabled: boolean;

  @Input() readonly: boolean;

  @Input() filter: boolean = true;

  @Input() filterPlaceHolder: string;

  @Input() filterLocale: string;

  @Input() overlayVisible: boolean;

  @Input() tabindex: number;

  @Input() appendTo: any;

  @Input() dataKey: string;

  @Input() name: string;

  @Input() ariaLabelledBy: string;

  @Input() displaySelectedLabel: boolean = true;

  @Input() maxSelectedLabels: number = 3;

  @Input() selectionLimit: number;

  @Input() selectedItemsLabel: string = '{0} items selected';

  // JPM: New input that will word-wrap when true
  @Input() selectedItemsWordwrap: boolean = false;

  @Input() showToggleAll: boolean = true;

  @Input() emptyFilterMessage: string = 'No results found';

  @Input() resetFilterOnHide: boolean = false;

  //@Input() dropdownIcon: string = 'pi pi-chevron-down';
  @Input() dropdownIcon: string = 'pi pi-caret-down'; //JPM IB change chevron to caret for default icon looks more like normal drop down icon

  @Input() optionLabel: string;

  @Input() showHeader: boolean = true;

  @Input() autoZIndex: boolean = true;

  @Input() baseZIndex: number = 0;

  @Input() filterBy: string = 'label';

  @Input() virtualScroll: boolean;

  @Input() itemSize: number;

  @Input() showTransitionOptions: string = '225ms ease-out';

  @Input() hideTransitionOptions: string = '195ms ease-in';

  @Input() ariaFilterLabel: string;

  @Input() filterMatchMode: string = "contains";

  @Input() tooltip: string = '';

  @Input() tooltipPosition: string = 'right';

  @Input() tooltipPositionStyle: string = 'absolute';

  @Input() tooltipStyleClass: string;

  @Input() autofocusFilter: boolean = true;

  @ViewChild('container') containerViewChild: ElementRef;

  @ViewChild('filterInput') filterInputChild: ElementRef;

  @ContentChild(Footer) footerFacet;

  @ContentChild(Header) headerFacet;

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;

  @Output() onChange: EventEmitter<any> = new EventEmitter();

  @Output() onFocus: EventEmitter<any> = new EventEmitter();

  @Output() onBlur: EventEmitter<any> = new EventEmitter();

  @Output() onClick: EventEmitter<any> = new EventEmitter();

  @Output() onPanelShow: EventEmitter<any> = new EventEmitter();

  @Output() onPanelHide: EventEmitter<any> = new EventEmitter();

  public value: any[];

  public onModelChange: Function = () => { };

  public onModelTouched: Function = () => { };

  overlay: HTMLDivElement;

  public valuesAsString: string;

  public focus: boolean;

  filled: boolean;

  public documentClickListener: any;

  public selfClick: boolean;

  public panelClick: boolean;

  public filterValue: string;

  public visibleOptions: SelectItem[];

  public disabledSelectedOptions: SelectItem[] = [];

  public filtered: boolean;

  public itemTemplate: TemplateRef<any>;

  public selectedItemsTemplate: TemplateRef<any>;

  public headerCheckboxFocus: boolean;

  _options: any[];

  maxSelectionLimitReached: boolean;

  documentResizeListener: any;

  preventModelTouched: boolean;

  constructor(public el: ElementRef, public renderer: Renderer2, private cd: ChangeDetectorRef) { }

  @Input() get options(): any[] {
    return this._options;
  }

  set options(val: any[]) {
    let opts = this.optionLabel ? ObjectUtils.generateSelectItems(val, this.optionLabel) : val;
    this.visibleOptions = opts;
    this._options = opts;
    this.updateLabel();

    if (this.filterValue && this.filterValue.length) {
      this.activateFilter();
    }
  }

  ngOnInit() {
    this.updateLabel();
  }

  ngAfterContentInit() {
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'item':
          this.itemTemplate = item.template;
          break;

        case 'selectedItems':
          this.selectedItemsTemplate = item.template;
          break;

        default:
          this.itemTemplate = item.template;
          break;
      }
    });
  }

  ngAfterViewInit() {
    if (this.overlayVisible) {
      this.show();
    }
  }

  ngAfterViewChecked() {
    if (this.filtered) {
      this.alignOverlay();

      this.filtered = false;
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.updateLabel();
    this.updateFilledState();
    this.setDisabledSelectedOptions();
    this.checkSelectionLimit();

    this.cd.markForCheck();
  }

  checkSelectionLimit() {
    if (this.selectionLimit && (this.value && this.value.length === this.selectionLimit)) {
      this.maxSelectionLimitReached = true;
    }
    else {
      this.maxSelectionLimitReached = false;
    }
  }

  updateFilledState() {
    this.filled = (this.value && this.value.length > 0);
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  onOptionClick(event) {
    let option = event.option;
    if (option.disabled) {
      return;
    }

    const optionValue = option.value;
    let selectionIndex = this.findSelectionIndex(optionValue);
    if (selectionIndex != -1) {
      this.value = this.value.filter((val, i) => i != selectionIndex);

      if (this.selectionLimit) {
        this.maxSelectionLimitReached = false;
      }
    }
    else {
      if (!this.selectionLimit || (!this.value || this.value.length < this.selectionLimit)) {
        this.value = [...this.value || [], optionValue];
      }

      this.checkSelectionLimit();
    }

    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event.originalEvent, value: this.value, itemValue: optionValue });
    this.updateLabel();
    this.updateFilledState();
  }

  isSelected(value) {
    return this.findSelectionIndex(value) != -1;
  }

  findSelectionIndex(val: any): number {
    let index = -1;

    if (this.value) {
      for (let i = 0; i < this.value.length; i++) {
        if (ObjectUtils.equals(this.value[i], val, this.dataKey)) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  toggleAll(event: Event) {
    if (this.isAllChecked()) {
      if (this.disabledSelectedOptions && this.disabledSelectedOptions.length > 0) {
        let value = [];
        value = [...this.disabledSelectedOptions];
        this.value = value;
      }
      else {
        this.value = [];
      }
    }
    else {
      let opts = this.getVisibleOptions();
      if (opts) {
        let value = [];
        if (this.disabledSelectedOptions && this.disabledSelectedOptions.length > 0) {
          value = [...this.disabledSelectedOptions];
        }
        for (let i = 0; i < opts.length; i++) {
          let option = opts[i];

          if (!option.disabled) {
            value.push(opts[i].value);
          }
        }
        this.value = value;
      }
    }

    this.onModelChange(this.value);
    this.onChange.emit({ originalEvent: event, value: this.value });
    this.updateFilledState();
    this.updateLabel();
  }

  isAllChecked() {
    if (this.filterValue && this.filterValue.trim().length) {
      return this.value && this.visibleOptions && this.visibleOptions.length && this.isAllVisibleOptionsChecked();
    }
    else {
      let optionCount = this.getEnabledOptionCount();
      let disabledSelectedOptionCount = this.disabledSelectedOptions.length;

      return this.value && this.options && (this.value.length > 0 && this.value.length == optionCount + disabledSelectedOptionCount);
    }
  }

  isAllVisibleOptionsChecked() {
    if (!this.visibleOptions || this.visibleOptions.length === 0) {
      return false;
    }
    else {
      for (let option of this.visibleOptions) {
        if (!this.isSelected(option.value)) {
          return false;
        }
      }
      return true;
    }
  }

  getEnabledOptionCount(): number {
    if (this.options) {
      let count = 0;
      for (let opt of this.options) {
        if (!opt.disabled) {
          count++;
        }
      }

      return count;
    }
    else {
      return 0;
    }
  }

  setDisabledSelectedOptions() {
    if (this.options) {
      this.disabledSelectedOptions = [];
      if (this.value) {
        for (let opt of this.options) {
          if (opt.disabled && this.isSelected(opt.value)) {
            this.disabledSelectedOptions.push(opt.value);
          }
        }
      }
    }
  }

  show() {
    if (!this.overlayVisible) {
      this.overlayVisible = true;
    }
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        this.appendOverlay();
        if (this.autoZIndex) {
          this.overlay.style.zIndex = String(this.baseZIndex + (++DomHandler.zindex));
        }
        this.alignOverlay();
        this.bindDocumentClickListener();
        this.bindDocumentResizeListener();

        if (this.filterInputChild && this.filterInputChild.nativeElement) {
          this.preventModelTouched = true;

          if (this.autofocusFilter) {
            this.filterInputChild.nativeElement.focus();
          }
        }

        this.onPanelShow.emit();
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body')
        document.body.appendChild(this.overlay);
      else
        DomHandler.appendChild(this.overlay, this.appendTo);

      if (!this.overlay.style.minWidth) {
        this.overlay.style.minWidth = DomHandler.getWidth(this.containerViewChild.nativeElement) + 'px';
      }
    }
  }

  restoreOverlayAppend() {
    if (this.overlay && this.appendTo) {
      this.el.nativeElement.appendChild(this.overlay);
    }
  }

  alignOverlay() {
    if (this.overlay) {
      if (this.appendTo)
        DomHandler.absolutePosition(this.overlay, this.containerViewChild.nativeElement);
      else
        DomHandler.relativePosition(this.overlay, this.containerViewChild.nativeElement);
    }
  }

  hide() {
    this.overlayVisible = false;
    this.unbindDocumentClickListener();
    if (this.resetFilterOnHide) {
      this.filterInputChild.nativeElement.value = '';
      this.onFilter();
    }
    this.onPanelHide.emit();
  }

  close(event) {
    this.hide();
    event.preventDefault();
    event.stopPropagation();
  }

  onMouseclick(event, input) {
    if (this.disabled || this.readonly) {
      return;
    }

    this.onClick.emit(event);

    if (!this.panelClick) {
      if (this.overlayVisible) {
        this.hide();
      }
      else {
        input.focus();
        this.show();
      }
    }

    this.selfClick = true;
  }

  onInputFocus(event) {
    this.focus = true;
    this.onFocus.emit({ originalEvent: event });
  }

  onInputBlur(event) {
    this.focus = false;
    this.onBlur.emit({ originalEvent: event });

    if (!this.preventModelTouched) {
      this.onModelTouched();
    }
    this.preventModelTouched = false;
  }

  onOptionKeydown(event) {
    if (this.readonly) {
      return;
    }

    switch (event.originalEvent.which) {

      //down
      case 40:
        var nextItem = this.findNextItem(event.originalEvent.target.parentElement);
        if (nextItem) {
          nextItem.focus();
        }

        event.originalEvent.preventDefault();
        break;

      //up
      case 38:
        var prevItem = this.findPrevItem(event.originalEvent.target.parentElement);
        if (prevItem) {
          prevItem.focus();
        }

        event.originalEvent.preventDefault();
        break;

      //enter
      case 13:
        this.onOptionClick(event);
        event.originalEvent.preventDefault();
        break;
    }
  }

  findNextItem(item) {
    let nextItem = item.nextElementSibling;

    if (nextItem)
      return DomHandler.hasClass(nextItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(nextItem.children[0]) ? this.findNextItem(nextItem) : nextItem.children[0];
    else
      return null;
  }

  findPrevItem(item) {
    let prevItem = item.previousElementSibling;

    if (prevItem)
      return DomHandler.hasClass(prevItem.children[0], 'ui-state-disabled') || DomHandler.isHidden(prevItem.children[0]) ? this.findPrevItem(prevItem) : prevItem.children[0];
    else
      return null;
  }

  onKeydown(event: KeyboardEvent) {
    switch (event.which) {
      //down
      case 40:
        if (!this.overlayVisible && event.altKey) {
          this.show();
          event.preventDefault();
        }
        break;

      //space
      case 32:
        if (!this.overlayVisible) {
          this.show();
          event.preventDefault();
        }
        break;

      //escape
      case 27:
        this.hide();
        break;
    }
  }

  updateLabel() {
    if (this.value && this.options && this.value.length && this.displaySelectedLabel) {
      let label = '';
      for (let i = 0; i < this.value.length; i++) {
        let itemLabel = this.findLabelByValue(this.value[i]);
        if (itemLabel) {
          if (label.length > 0) {
            label = label + ', ';
          }
          label = label + itemLabel;
        }
      }

      if (this.value.length <= this.maxSelectedLabels) {
        this.valuesAsString = label;
      }
      else {
        let pattern = /{(.*?)}/;
        if (pattern.test(this.selectedItemsLabel)) {
          this.valuesAsString = this.selectedItemsLabel.replace(this.selectedItemsLabel.match(pattern)[0], this.value.length + '');
        } else {
          this.valuesAsString = this.selectedItemsLabel;
        }
      }
    }
    else {
      this.valuesAsString = this.defaultLabel;
    }
  }

  findLabelByValue(val: any): string {
    let label = null;
    for (let i = 0; i < this.options.length; i++) {
      let option = this.options[i];
      if (val == null && option.value == null || ObjectUtils.equals(val, option.value, this.dataKey)) {
        label = option.label;
        break;
      }
    }
    return label;
  }

  onFilter() {
    let inputValue = this.filterInputChild.nativeElement.value;
    if (inputValue && inputValue.length) {
      this.filterValue = inputValue;
      this.activateFilter();
    }
    else {
      this.filterValue = null;
      this.visibleOptions = this.options;
      this.filtered = false;
    }
  }

  activateFilter() {
    if (this.options && this.options.length) {
      let searchFields: string[] = this.filterBy.split(',');
      this.visibleOptions = FilterUtils.filter(this.options, searchFields, this.filterValue, this.filterMatchMode, this.filterLocale);
      this.filtered = true;
    }
  }

  isItemVisible(option: SelectItem): boolean {
    if (this.filterValue && this.filterValue.trim().length) {
      for (let i = 0; i < this.visibleOptions.length; i++) {
        if (this.visibleOptions[i].value == option.value) {
          return true;
        }
      }
    }
    else {
      return true;
    }
  }

  getVisibleOptions(): SelectItem[] {
    return this.visibleOptions || this.options;
  }

  onHeaderCheckboxFocus() {
    this.headerCheckboxFocus = true;
  }

  onHeaderCheckboxBlur() {
    this.headerCheckboxFocus = false;
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen('document', 'click', () => {
        if (!this.selfClick && !this.panelClick && this.overlayVisible) {
          this.hide();
        }

        this.selfClick = false;
        this.panelClick = false;
        this.cd.markForCheck();
      });
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  bindDocumentResizeListener() {
    this.documentResizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.documentResizeListener);
  }

  unbindDocumentResizeListener() {
    if (this.documentResizeListener) {
      window.removeEventListener('resize', this.documentResizeListener);
      this.documentResizeListener = null;
    }
  }

  onWindowResize() {
    if (!DomHandler.isAndroid()) {
      this.hide();
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.unbindDocumentResizeListener();
    this.overlay = null;
    this.onModelTouched();
  }

  ngOnDestroy() {
    this.restoreOverlayAppend();
    this.onOverlayHide();
  }

}

// JPM: Wrap in our own module
//@NgModule({
//  imports: [CommonModule, SharedModule, ScrollingModule, TooltipModule],
//  exports: [MultiSelect, SharedModule, ScrollingModule],
//  declarations: [MultiSelect, MultiSelectItem]
//})
//export class MultiSelectModule { }
*/
