import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ModalCommonOptions } from '../../modal/modal-common-options';
import { FormGroupFluentModel } from '../../form/form-fluent-model';
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { EventModelTyped, ButtonItem, Action, EventModel } from '../../ux-models';
import { AlertItemType } from '../../alert/alert-manager';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputStringListComponent),
  multi: true
};

@Component({
  selector: 'ib-input-string-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-string-list.component.html',
  styleUrls: ['./input-string-list.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputStringListComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() headerIcon: string = "";
  @Input() headerText: string = "";
  @Input() headerClass: string = "header";

  @Input() addButtonIcon: string = "";
  @Input() addButtonText: string = "";
  @Input() addButtonColor: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "default" = "primary";
  @Input() addButtonSize: "" | "lg" | "sm" | "xs" = "xs";
  @Input() addButtonClass: string = "";

  @Input() bulkAddButtonIcon: string = "plus-square";
  @Input() bulkAddButtonText: string = "";
  @Input() bulkAddButtonTooltip: string = "Add multiple items (one per line)";
  @Input() bulkAddButtonColor: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "default" = "primary";
  @Input() bulkAddButtonSize: "" | "lg" | "sm" | "xs" = "xs";
  @Input() bulkAddButtonClass: string = "";

  @Input() bulkEditButtonIcon: string = "pen-square";
  @Input() bulkEditButtonText: string = "";
  @Input() bulkEditButtonTooltip: string = "Edit multiple items (one per line)";
  @Input() bulkEditButtonColor: "primary" | "secondary" | "success" | "warning" | "danger" | "info" | "light" | "dark" | "default" = "primary";
  @Input() bulkEditButtonSize: "" | "lg" | "sm" | "xs" = "xs";
  @Input() bulkEditButtonClass: string = "";

  @Input() canBulkAdd: boolean = false;
  @Input() canBulkEdit: boolean = false;
  @Input() canEdit: boolean = true;
  @Input() canCopy: boolean = true;
  @Input() canDelete: boolean = true;
  @Input() canSort: boolean = true;
  @Input() canCopyListToClipboard: boolean = false;

  @Input() itemLabel: string = "";
  @Input() itemsLabel: string = "";
  @Input() itemClass: string = "";
  @Input() itemRows: number = 1;

  @Input() wrapperClass: string = "";
  @Input() itemsWrapperClass: string = "";
  @Input() modelIsJsonString: boolean = false;
  @Input() modelIsCsvString: boolean = false;

  /**
   * When true user is presented with a pick list select drop down not a text input control.
   */
  @Input() usePickList: boolean = false;

  list: string[] = [];
  actionButton: ButtonItem = null;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
    // super.ngOnInit();
    this.actionButton = this.getActionButton();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this.actionButton = this.getActionButton();
    // if (changes.ngModel) {
    //  if (this.modelIsJsonString) {
    //    console.error("input json value change", changes.ngModel);
    //    this.list = JSON.parse(this.value);
    //  } else if (this.modelIsCsvString) {
    //    console.error("input csv value change", changes.ngModel);
    //    this.list = Helper.parseCsvString(this.value);
    //  }
    // }
    if (this.itemLabel && !this.itemsLabel) {
      this.itemsLabel = Helper.plural(this.itemLabel, 2);
    }
    if ((this.canBulkAdd || this.canBulkEdit) && this.itemRows > 1) {
      console.warn("Bulk add and bulk edit are not allowed because items can be more than one line and bulk add/edit requires one item per line.  Use [itemRows]='1' to limit to one row per item.");
    }
  }

  isArray() {
    if (!this.value) {
      return false;
    }
    return Helper.isArray(this.value);
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (this.modelIsJsonString) {
      // console.error("input json value change", value);
      try {
        this.list = JSON.parse(value);
        if (!Helper.isArray(this.list)) {
          // console.error("value is not an array", this.list);
          this.list = [];
        }
      } catch (ex) {
        this.list = [];
        console.error("json parse error", value, ex);
      }
    } else if (this.modelIsCsvString) {
      // console.error("input csv value change", value);
      this.list = Helper.parseCsvString(value);
    } else if (!Helper.isArray(value)) {
      // console.error("value is not an array", value);
      super.writeValue([]);
    } else {
      // console.error("input list value change", value);
    }
  }

  add($event) {
    this.itemModal("", -1, "add");
  }

  itemModal(item: string, index: number, mode: "add" | "edit" | "view") {

    const label = this.itemLabel || "Item";
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = label;
    if (mode === "add") {
      options.title = "Add " + label;
    } else if (mode === "edit") {
      options.title = "Edit " + label;
    }

    // Build the form
    const group = new FormGroupFluentModel("block");
    if (this.usePickList) {
      group.HasInputSelect(label, "Input", "Item", this.optionsPickListId, "W", false, this.optionsPickList);
    } else if (this.itemRows <= 1) {
      group.HasInputString(label, label, "Input", "Item", "W");
      group.LastControl().OptionsPickList = this.optionsPickList;
    } else {
      group.HasInputLongString(label, label, "Input", "Item", this.itemRows, "W");
    }
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string } } = { Input: { Item: (new String(item)).toString() } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
      if (mode === "add") {
        if (this.modelIsJsonString) {
          this.list.push(event.data.Input.Item);
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list.push(event.data.Input.Item);
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value.push(event.data.Input.Item);
        }
        this.fireChange(null, null, true);
      } else if (mode === "edit") {
        if (this.modelIsJsonString) {
          this.list[index] = event.data.Input.Item;
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list[index] = event.data.Input.Item;
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value[index] = event.data.Input.Item;
        }
        this.fireChange(null, null, true);
      }
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }

  bulkItemModal($event, mode: "add" | "edit" | "view") {

    const label = this.itemsLabel || "Items";
    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.size = "large";
    options.title = `Multiple ${label} (One Per Line)`;
    if (mode === "add") {
      options.title = `Add Multiple ${label} (One Per Line)`;
    } else if (mode === "edit") {
      options.title = `Edit Multiple ${label} (One Per Line)`;
    }

    let item: string = "";
    if (mode === "edit") {
      if (this.modelIsJsonString || this.modelIsCsvString) {
        item = this.list.join("\r\n");
      } else {
        item = this.value.join("\r\n");
      }
    }

    // Build the form
    const group = new FormGroupFluentModel("block");
    group.HasInputLongString(label, label, "Input", "Item", 10, "W");
    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    form.Groups.push(group);

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const payload: { Input: { Item: string } } = { Input: { Item: item } };

    const promise = this.uxService.modal.showDynamicFormModal(options, form, payload);
    promise.then((event: EventModelTyped<{ Input: { Item: string } }>) => {
      if (mode === "edit") {
        // For bulk edit we clear the list and then push all items into the list
        this.list = [];
        this.value = [];
      }
      const items = event.data.Input.Item.split("\n");
      if (this.modelIsJsonString) {
        items.forEach(item => {
          this.list.push(item);
        });
        this.value = JSON.stringify(this.list);
      } else if (this.modelIsCsvString) {
        items.forEach(item => {
          this.list.push(item);
        });
        this.value = Helper.buildCsvString(this.list);
      } else {
        items.forEach(item => {
          this.value.push(item);
        });
      }
      this.fireChange(null, null, true);
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }


  copyListToClipboard($event) {
    const str = this.value.join("\r\n");
    Helper.copyToClipboard(str);
    this.appService.alertManager.addAlertMessage(AlertItemType.Success, "Copied to clipboard.", 3);
  }


  getActionButton(): ButtonItem {

    const label = this.itemLabel || "Item";
    const action = new ButtonItem("", "bars", "light", null);

    action.size = "xs";
    action.menuPlacement = ["bottom-right", "top-right"];
    action.options = [];
    if (this.canEdit) {
      action.options.push(new Action("edit", `Edit ${label}`, "pencil", "", ($event: EventModel) => {
        this.itemModal($event.data, $event.cargo.itemIndex, "edit");
      }));
    }
    if (this.canCopy) {
      action.options.push(new Action("copy", `Copy ${label}`, "copy", "", ($event: EventModel) => {
        if (this.modelIsJsonString) {
          this.list.push((new String($event.data)).toString());
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list.push((new String($event.data)).toString());
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value.push((new String($event.data)).toString());
        }
        this.fireChange($event.event, null, true);
      }));
    }
    if (this.canDelete) {
      action.options.push(new Action("delete", `Delete ${label}`, "times", "", ($event: EventModel) => {
        let message = `Delete this ${label}?`;
        if ($event.data) {
          message = `Delete ${label} ${Helper.left($event.data, 20, true)}?`;
        }
        const promise = this.uxService.modal.confirmDelete(message, null);
        promise.then((answer) => {
          if (this.modelIsJsonString) {
            this.list.splice($event.cargo.itemIndex, 1);
            this.value = JSON.stringify(this.list);
          } else if (this.modelIsCsvString) {
            this.list.splice($event.cargo.itemIndex, 1);
            this.value = Helper.buildCsvString(this.list);
          } else {
            this.value.splice($event.cargo.itemIndex, 1);
          }
          this.fireChange($event.event, null, true);
        }, (cancelled) => {
          // No action
        });
      }));
    }
    if (this.canSort) {
      action.options.push(new Action()); // divider
      action.options.push(new Action("move-top", "Move to Top", "angle-double-up", "", ($event: EventModel) => {
        if (this.modelIsJsonString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, 0).allItems;
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, 0).allItems;
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value = Helper.arrayElementMove(this.value, $event.cargo.itemIndex, 0).allItems;
        }
        this.fireChange($event.event, null, true);
      }));
      action.options.push(new Action("move-up", "Move Up", "angle-up", "", ($event: EventModel) => {
        if (this.modelIsJsonString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex - 1).allItems;
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex - 1).allItems;
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value = Helper.arrayElementMove(this.value, $event.cargo.itemIndex, $event.cargo.itemIndex - 1).allItems;
        }
        this.fireChange($event.event, null, true);
      }));
      action.options.push(new Action("move-down", "Move Down", "angle-down", "", ($event: EventModel) => {
        if (this.modelIsJsonString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex + 1).allItems;
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex + 1).allItems;
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value = Helper.arrayElementMove(this.value, $event.cargo.itemIndex, $event.cargo.itemIndex + 1).allItems;
        }
        this.fireChange($event.event, null, true);
      }));
      action.options.push(new Action("move-bottom", "Move to Bottom", "angle-double-down", "", ($event: EventModel) => {
        if (this.modelIsJsonString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex + 1000000).allItems;
          this.value = JSON.stringify(this.list);
        } else if (this.modelIsCsvString) {
          this.list = Helper.arrayElementMove(this.list, $event.cargo.itemIndex, $event.cargo.itemIndex + 1000000).allItems;
          this.value = Helper.buildCsvString(this.list);
        } else {
          this.value = Helper.arrayElementMove(this.value, $event.cargo.itemIndex, $event.cargo.itemIndex + 1000000).allItems;
        }
        this.fireChange($event.event, null, true);
      }));
    }

    return action;

  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.canSort) {
      if (this.modelIsJsonString) {
        this.list = Helper.arrayElementMove(this.list, event.previousIndex, event.currentIndex).allItems;
        this.value = JSON.stringify(this.list);
      } else if (this.modelIsCsvString) {
        this.list = Helper.arrayElementMove(this.list, event.previousIndex, event.currentIndex).allItems;
        this.value = Helper.buildCsvString(this.list);
      } else {
        this.value = Helper.arrayElementMove(this.value, event.previousIndex, event.currentIndex).allItems;
      }
      this.fireChange(event, null, true);
    }
  }

  trackByFn(index, item) {
    return index; // or item.id
  }

}
