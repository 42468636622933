import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { InputContactFormModalComponent } from '../input-contact-form-modal/input-contact-form-modal.component';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { ApiOperationType, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { AlertItemType } from '../../alert/alert-manager';

@Component({
  selector: 'ib-input-contact-picker-modal',
  templateUrl: './input-contact-picker-modal.component.html',
  styleUrls: ['./input-contact-picker-modal.component.css']
})
export class InputContactPickerModalComponent implements OnInit, OnChanges, OnDestroy {

  @Input() options: ModalCommonOptions = new ModalCommonOptions();
  @Input() data: any;
  @Input() filter: string = "";
  @Input() contactTypes: string[] = [Constants.ContactType.Directory, Constants.ContactType.Customer];
  @Input() allowClearSelection: boolean = true;
  @Input() allowAddContact: boolean = true;

  @Input() showAddressType: boolean = false;
  @Input() parentContactId: number = null;
  @Input() parentContactName: string = null;
  @Input() addressTypeFilter: string | string[] = null;
  @Input() addressTypeCaption: string = "Type";

  public currentContactType: string = "";
  public addressTypeDescription: string = "";

  public selectedContact: m5.ContactListViewModel = null;
  public selectedContacts: m5.ContactListViewModel[] = [];
  public tableOptions: TableOptions = null;
  public tableReloadCount: number = 0;

  /**
  We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
  only need to unsubscribe from this one shared subject in ngOnDestroy as it
  is passed as parameter to takeUntil.
  */
  private ngUnsubscribe = new Subject();

  constructor(
    protected contactService: ContactService,
    protected appService: AppService,
    protected apiService: ApiService,
    protected sanitizer: DomSanitizer,
    protected ngbModalService: NgbModal,
    public modal: NgbActiveModal) {
  }

  ngOnInit() {
    this.validateParameters();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.validateParameters();
  }

  ngOnDestroy() {
    /**
    We use takeUntil pattern (see https://stackoverflow.com/a/41177163) where we
    only need to unsubscribe from this one shared subject in ngOnDestroy as it
    is passed as parameter to takeUntil.
    */
    this.ngUnsubscribe.next(void 0);
    this.ngUnsubscribe.complete();
  }

  /**
   * Validate input parameters to make sure they are supported values.
   */
  protected validateParameters() {

    if (!this.options) {
      this.options = new ModalCommonOptions();
    }

    // We need text or icon on at least one of our buttons
    if (!this.options.okButtonText && !this.options.okButtonIcon && !this.options.cancelButtonText && !this.options.cancelButtonIcon) {
      this.options.okButtonText = "Ok";
    }

    // If we have a contact id then we have a contact currently selected we want frozen at the top of the table
    if (this.data && this.data.ContactId) {
      this.contactService.getContactListObject(this.data.ContactId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((contact) => {
        (contact as any).Selected = true;
        this.selectedContact = contact;
        this.selectedContacts = [contact];
        // console.error("frozen", this.frozenData);
      });
    }

    // If we have an address type filter then find a description to go with it.
    if (this.addressTypeFilter) {
      const addressType = this.getAddressType();
      this.addressTypeDescription = addressType;
      this.appService.getPickListText(addressType, Constants.PickList.ContactAddressType).pipe(takeUntil(this.ngUnsubscribe)).subscribe(description => {
        if (description) {
          this.addressTypeDescription = description;
          if (this.options) {
            if (this.parentContactName) {
              this.options.title = `Select ${this.addressTypeDescription} for ${this.parentContactName}`;
            } else {
              this.options.title = `Select ${this.addressTypeDescription}`;
            }
          }
        }
      });
    }

    let contactType = Helper.tryGetValue(this.data, x => x.ContactType, "", "");
    // If we don't have a contact type let's figure it out
    if (!contactType) {
      // Default will get updated when contact service look up below resolves
      if (this.contactTypes && this.contactTypes.length > 0) {
        contactType = this.contactTypes[0];
      } else {
        contactType = Constants.ContactType.Customer;
        Log.errorMessage(`No contact type specified and no list of valid contact types specified so defaulting to ${contactType}.`);
      }
      // See if we can sort out the contact type if we have an id but no type
      if (this.data && this.data.ContactId) {
        this.contactService.getContactListObject(this.data.ContactId).pipe(takeUntil(this.ngUnsubscribe)).subscribe((contact) => {
          if (contact) {
            contactType = contact.ContactType;
            this.data.ContactType = contactType;
            if (!this.contactTypes || this.contactTypes.length === 0) {
              this.contactTypes = [contactType];
            }
            this.tableOptions = this.getTableOptions(contactType);
          }
        });
      }
    }
    this.tableOptions = this.getTableOptions(contactType);

    // console.error(this.form);
    // console.error(this.data);

  }

  onRowSelected($event) {
    if ($event.data) {
      this.data.ContactId = $event.data.ContactId;
      this.data.ContactName = $event.data.ContactName;
      this.data.ContactType = $event.data.ContactType;
      this.data.ContactEmail = $event.data.Email;
      // Log.errorMessage("Contact Selected", this.data);
    } else {
      Log.errorMessage("No data included with event", $event);
    }
  }

  protected getAddressType(): string {
    let addressType = "";
    if (Helper.isArray(this.addressTypeFilter)) {
      if ((this.addressTypeFilter as string[]).length > 0) {
        addressType = (this.addressTypeFilter as string[])[0];
      }
    } else {
      addressType = this.addressTypeFilter as string;
    }
    return addressType;
  }

  getTableOptions(contactType: string): TableOptions {

    if (contactType) {
      this.currentContactType = contactType;
      if (contactType === Constants.ContactType.Contact && this.addressTypeDescription) {
        this.options.title = `Select ${this.addressTypeDescription}`;
      } else {
        this.options.title = `Select ${this.contactService.getContactTypeDescriptionLong(contactType)}`;
      }
    }

    // TODO accept api name and generic model type to see what properties we can turn into columns.
    const options = new TableOptions();
    options.tableId = null; // Don't save layout changes
    options.rowsPerPage = 10;
    options.apiName = "Contact";
    options.loadDataFromServer = true;
    options.theme = "striped";
    options.sort = "ContactName";
    let filter = `!IsContactScopeTerminated && ContactType == "${contactType}"`;
    if (this.parentContactId && contactType === Constants.ContactType.Contact) {
      filter += ` && ParentContactId == ${this.parentContactId}`;
    }
    if (this.addressTypeFilter && contactType === Constants.ContactType.Contact) {
      if (Helper.isArray(this.addressTypeFilter)) {
        filter += ` && AddressType IN ( "${Helper.buildCsvString(this.addressTypeFilter as string[], '","')}" )`;
      } else {
        filter += ` && AddressType == "${this.addressTypeFilter as string}"`;
      }
    }
    if (this.filter) {
      options.filter = `( ${this.filter} ) && ( ${filter} )`;
    } else {
      options.filter = filter;
    }
    options.rowsPerPageOptions = [5, 10, 15];
    options.rowSelectedAction = (row: any, selectedRows: any[], allRows: any[], cargo: any) => {
      // Set selected flag set off any of the rows visible and set on for the selected row
      if (allRows) {
        allRows.forEach((one) => {
          one.Selected = false;
        });
      }
      if (row) {
        row.Selected = true;
        this.selectedContact = row;
        this.selectedContacts = [row];
      }
    };
    options.lazyLoadAction = (rows: any[], cargo: any) => {
      if (rows) {
        rows.forEach((one) => {
          one.Selected = (one.ContactId === this.data.ContactId);
        });
      }
    };

    const contactIconData = IconHelper.iconDataFromContactType(contactType, false, true, true);
    // If we have more than one contact type allowed then we need a button to allow selecting the contact type
    if (this.contactTypes && this.contactTypes.length > 1) {
      options.actionButtonLeft1 = new ButtonItem(contactIconData.title, contactIconData.iconDescription, "secondary");
      options.actionButtonLeft1.size = "sm";
      this.contactTypes.forEach(type => {
        const iconData = IconHelper.iconDataFromContactType(type, false, true, true);
        options.actionButtonLeft1.options.push(new Action(type, iconData.title, iconData.iconDescription, "", (event) => {
          // console.error("changing contact type", event);
          // In our event the element has information about the action and the action id is our contact type
          this.tableOptions = this.getTableOptions(event.element.id);
          // Trigger reloading the table as we have a new contact type selected
          this.tableReloadCount++;
        }));
      });
    }

    options.columns.push(new TableColumnOptions("Selected", "", "boolean"));
    TableHelper.setColumnHeader(options.columns.slice(-1)[0], "", "check-square", "none", false);
    options.columns.push(new TableColumnOptions("ExternalContactId", "Id"));
    options.columns.push(new TableColumnOptions("ContactName", `${contactIconData.title} Name`));
    options.columns.push(new TableColumnOptions("FirstName"));
    options.columns.push(new TableColumnOptions("LastName"));
    if (this.showAddressType && contactType === Constants.ContactType.Contact && (!this.addressTypeFilter || Helper.isArray(this.addressTypeFilter))) {
      options.columns.push(new TableColumnOptions("AddressType", Helper.getFirstDefinedString(this.addressTypeCaption, "Type"), "picklist", Constants.PickList.ContactAddressType));
    }
    options.columns.push(new TableColumnOptions("Email", "Email", "email"));
    if (contactType === Constants.ContactType.Directory || contactType === Constants.ContactType.Group) {
      options.columns.push(new TableColumnOptions("", "Access", "function"));
      options.columns.slice(-1)[0].filterType = "none";
      options.columns.slice(-1)[0].sortable = false;
      options.columns.slice(-1)[0].render = (row: m5.ContactListViewModel) => {
        let html = "";
        let scopes: string[] = [];
        if (row && row.Flags && row.Flags.length > 0) {
          // Flags include more than just scope information so filter to only scope information for our purposes here
          scopes = row.Flags.filter(x => Helper.startsWith(x, "Scope:", true));
        }
        if (scopes.length === 0) {
          html = IconHelper.iconDataFromContactScope(Constants.ContactScope.InheritedFromGroup, false, false, true, "me-2").html;
        } else if (scopes.length === 1) {
          html = IconHelper.iconDataFromContactScope(scopes[0], false, false, true, "me-2").html;
        } else {
          scopes.forEach(scope => {
            // With more than 1 scope we hide the inherited scope and just focus on what we inherited
            if (scope === Constants.ContactScope.InheritedFromGroup || Helper.equals(scope, `Scope:${Constants.ContactScope.InheritedFromGroup}`, true)) {
              return; // return = continue
            }
            const one: string = IconHelper.iconDataFromContactScope(scope, false, false, true, "me-2").html;
            html += one;
          });
        }
        return this.sanitizer.bypassSecurityTrustHtml(html);
      };
    }
    options.columns.push(new TableColumnOptions("", "", "avatar"));
    TableHelper.setColumnHeader(options.columns.slice(-1)[0], "", "image", "none", false);

    if (this.allowClearSelection) {
      // event.data => { data: data, selectedData: selectedData, frozenData: frozenData, headerData: headerData }
      options.actionButtonRight1 = new ButtonItem(`Clear Selection`, "ban", "warning", (event) => {
        // console.error("clear selection", event);
        this.clearContact(event);
      });
      options.actionButtonRight1.size = "sm";
    }

    // Not going to be able to use existing modals for this as they are in library that lives upstream from us...
    if (this.allowAddContact && this.currentContactType !== Constants.ContactType.Group) {
      options.actionButtonRight2 = new ButtonItem(`Add ${contactIconData.title}`, "plus", "primary", (event) => {
        // console.error("add contact", event);
        this.addContact(event);
      });
      options.actionButtonRight2.size = "sm";
    }

    return options;

  }



  addContact(event: any) {

    const contactIconData = IconHelper.iconDataFromContactType(this.currentContactType, false, true, true);

    const options = new ModalCommonOptions();
    options.size = "largest"; // options are: large, small, 80%.  Default is large.
    options.titleContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
    options.titleIcon = contactIconData.iconDescription;
    options.title = `Add ${this.contactService.getContactTypeDescriptionLong(this.currentContactType)}`;
    if (this.currentContactType === Constants.ContactType.Contact && this.addressTypeDescription) {
      options.title = `Add ${this.addressTypeDescription}`;
    }
    options.okButtonContextColor = "primary"; // options are: primary, secondary, info, success, warning, danger, light, dark
    options.okButtonText = "Ok";
    options.cancelButtonContextColor = "secondary";
    options.cancelButtonText = "Cancel";
    options.validate = (options: ModalCommonOptions, okData: m5.ContactEditViewModel, errors: string[], modal: NgbActiveModal) => {
      // let dates = [];
      // if (this.data.BirthDate) {
      //  dates.push(moment(this.data.BirthDate));
      // }
      // if (this.data.AnniversaryDate) {
      //  dates.push(moment(this.data.AnniversaryDate));
      // }
      // let status = this.contactService.getSecurityPolicyStatus(this.policy, true, okData.Login,
      //  okData.NewPassword, okData.NewPasswordConfirmed,
      //  this.getContactName(), this.data.FirstName, this.data.LastName, dates);
      // let bad = status.filter(x => !x.Valid);
      // if (bad && bad.length > 0) {
      //  let message: string = "<ul>";
      //  bad.forEach(err => {
      //    message += `<li>${Helper.stringFormat(err.Message, err.Variables)}</li>`;
      //  });
      //  message += "</ul>";
      //  // Show the errors
      //  this.uxService.modal.alertDanger("Errors", message);
      //  return false;
      // }
      return true;
    };

    // Note that since forms allow interacting with different data object types, data is always a container of objects
    // and those objects are containers for properties.  For example: instead of data.CustomerName expect things
    // like data.Customer.Name, data.Invoice.Date, etc.
    const data: m5.ContactEditViewModel = new m5.ContactEditViewModel();
    data.ContactType = this.currentContactType;
    if (this.currentContactType === Constants.ContactType.Contact) {
      data.AddressType = this.getAddressType();
    }
    data.ParentContactId = this.parentContactId;

    const modalRef = this.ngbModalService.open(InputContactFormModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    modalRef.componentInstance.data = data;
    modalRef.componentInstance.contactType = this.currentContactType;

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModelTyped<m5.ContactEditViewModel>) => {
      // User hit ok so value.data is the data object.
      const apiProp = Api.Contact();
      const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Add);
      this.apiService.call(apiCall, value.data).subscribe((response: IApiResponseWrapperTyped<m5.ContactEditViewModel>) => {
        if (response.Data.Success) {
          this.clearContact(event);
          (<any>response.Data.Data).Selected = true;
          this.selectedContact = response.Data.Data;
          this.selectedContacts = [response.Data.Data];
          this.data.ContactId = this.selectedContact.ContactId;
          this.data.ContactName = this.selectedContact.ContactName;
          this.data.ContactType = this.selectedContact.ContactType;
          this.data.ContactEmail = this.selectedContact.Email;
        } else {
          this.appService.alertManager.addAlertFromApiResponse(response, apiCall);
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save
    });

  }



  clearContact(event) {
    this.data.ContactId = null;
    this.data.ContactName = "";
    this.data.ContactType = "";
    this.data.ContactEmail = "";
    this.selectedContact = null;
    this.selectedContacts = [];
    if (event.data.selectedData) {
      event.data.selectedData.Selected = false;
      event.data.selectedData = null;
    }
    if (event.data.headerData && event.data.headerData.length > 0) {
      event.data.headerData[0].Selected = false;
      event.data.headerData = [];
    }
  }




}
