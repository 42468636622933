import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef, AfterViewChecked, AfterViewInit, Directive, NgModuleRef } from '@angular/core';
import { TableModule, Table } from 'primeng/table';
import { SelectItem } from 'primeng/api';
import { ContextMenuModule } from 'primeng/contextmenu';
import { MenuItem } from 'primeng/api';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
// import { Column } from 'primeng/components/common/shared';
import { EventModel, EventElementModel, EventModelTyped, Action } from 'projects/common-lib/src/lib/ux-models';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import * as Enumerable from 'linq';
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { TableColumnOptions, PrimeColumn } from 'projects/common-lib/src/lib/table/table-column-options';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ApiProperties, Query, ApiOperationType, ApiCall, IApiResponseWrapper, IApiResponseWrapperTyped } from 'projects/core-lib/src/lib/api/ApiModels';
import { Api } from 'projects/core-lib/src/lib/api/Api';
import { ApiHelper } from 'projects/core-lib/src/lib/api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { SafeStyle, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { FilterSelectionData } from 'projects/common-lib/src/lib/filter/filter-selection-data';
import { QueryService } from 'projects/core-lib/src/lib/services/query.service';
import { StaticPickList } from 'projects/core-lib/src/lib/models/model-helpers';
import { TableService } from 'projects/core-lib/src/lib/services/table.service';
import { ModalCommonOptions } from '../modal/modal-common-options';
import { UxService } from '../services/ux.service';
import { TableConfigurationModalComponent } from './table-configuration-modal/table-configuration-modal.component';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { SystemService } from 'projects/core-lib/src/lib/services/system.service';

@Directive()
export abstract class TableBaseClass extends BaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {

  @Input() options: TableOptions = null;
  @Input() data: any[] = [];

  /**
   * Mostly this is used internally when loading data but it's available to be set externally for
   * scenarios where we are lazy loading data external to this component.
   * @see TableOptions.expandedRowEventHandler
   * @see TableOptions.expandedRowChildDataLoading
   */
  @Input() loading: boolean = false;

  /**
  Used to pass queries params from the URL such as sort and filter
   */
  @Input() routeQueries: any = {};

  /**
  Incrementing this input will alert the table that data was reloaded so it can handle any refresh needed internally.
  */
  @Input() reloadCount: number = 0;

  /**
   * An incrementor to trigger the collapse of all rows.
   */
  @Input() collapseRowsCount: number = 0;

  /**
   * For performance reasons our cell rendering uses ChangeDetectionStrategy.OnPush but if something external to
   * the table triggers a change that is not otherwise handled via an Input() parameter (e.g. something that
   * influences the output of a render function) then our cell rendering would not know change detection is needed.
   * This input provides us the ability to trigger change detection from the outside by incrementing the value
   * in those circumstances.
   */
  @Input() otherChangeCount: number = 0;

  @Output() rowReorder: EventEmitter<any> = new EventEmitter();
  @Output() filterChange: EventEmitter<any> = new EventEmitter();
  @Output() saveView: EventEmitter<any> = new EventEmitter();

  @Input() headerData: any[] = [];
  @Input() frozenData: any[] = [];

  viewPortHeight: number = Helper.getMaxComponentHeight(null, null, 200);

  /**
  Default options as submitted.  We have support for saving options to local storage
  so keeping copy of defaults allows us to revert to default if desired, etc.
  */
  public defaultOptions: TableOptions = null;

  public rowsPerPageOptions: { rows: string, label: string }[] = [
    { rows: "5", label: "5" },
    { rows: "10", label: "10" },
    { rows: "25", label: "25" },
    { rows: "50", label: "50" },
    { rows: "100", label: "100" },
    { rows: Constants.RowsToReturn.All.toString(), label: "All" }
  ];

  /**
   * If we have a custom configuration for the table we will store that here
   * and use it to update the table options.
   */
  public config: m5web.TableConfigurationViewModel = null;

  /**
   * Normally all row actions are encapsulated in the TableOptions.rowActionButton.options
   * and displayed on the row actions button but TableOptions.rowActionDisplayMode and
   * TableOptions.rowActionsForIconDisplay might call for some actions to have their own
   * icons instead of being in the menu and when that's the case we populate this array
   * of actions.
   */
  public rowActionsForIconDisplay: Action[] = [];
  /**
   * Original row actions attached to a row action button since in some cases they will
   * be removed from the button when displayed as icons.
   */
  public rowActionsOriginal: Action[] = [];
  public rowMenuWidth: string = "width:2.5em;";

  /**
  When row selection is turned on this is an array of selected rows.
  */
  public selectedData: any = null;
  public selectionMode: "single" | "multiple" = "single"; // | "multipleWithCheckboxes" = "single";
  public sortMode: "single" | "multiple" = "single";

  public contextMenu: MenuItem[] = [];
  public contextMenuRowIndex: number = -1;
  public contextMenuButtonHeaderRowIndex: number = -1;
  public contextMenuButtonRowIndex: number = -1;
  public contextMenuIsDynamic: boolean = false;
  public globalFilterText: string = "";

  /**
   * A flag that indicates when the table config option is active.  This may be
   * toggled by a hover event and may be used in our ui for visual display changes
   * when that option is active (e.g. icon changes, click event listener, etc.)
   */
  public tableConfigOptionActive: boolean = false;

  constructor(
    protected apiService: ApiService,
    protected appService: AppService,
    protected systemService: SystemService,
    protected queryService: QueryService,
    protected uxService: UxService,
    protected sanitizer: DomSanitizer,
    protected cdr: ChangeDetectorRef,
    protected elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.configure();
    this.getTableConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.options) {
      this.configure();
      this.getTableConfig();
    }

    if (changes.routeQueries) {
      this.applyUrlQueries();
    }
    // Any change should trigger otherChangeCount so our render component triggers ChangeDetectionStrategy.OnPush
    this.otherChangeCount++;
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.applyUrlQueries();
  }

  // ngAfterViewChecked() {
  // }

  public onFilterChange($event: EventModelTyped<FilterSelectionData>) {
    this.filterChange.emit($event);
  }

  public applyUrlQueries() { }

  public configure() {

    if (!this.options) {
      this.options = new TableOptions();
    }

    if (!this.options.columns || this.options.columns.length === 0) {
      this.options.columns = TableHelper.buildColumnOptionsFromData(this.data);
    } else {
      this.options.columns = TableHelper.assignColumnDataTypesFromData(this.options.columns, this.data);
    }

    if (this.options.rowsPerPageOptions && this.options.rowsPerPageOptions.length > 0) {
      let rowsPerPageOptions: number[] = Helper.arraySortNumbers(Helper.arrayDistinct(this.options.rowsPerPageOptions));
      if (!rowsPerPageOptions) {
        rowsPerPageOptions = [...this.options.rowsPerPageOptions];
      }
      this.rowsPerPageOptions = [];
      if (rowsPerPageOptions) {
        rowsPerPageOptions.forEach((rows: number) => {
          let label: string = rows.toString();
          if (rows === 0 || rows === Constants.RowsToReturn.All || rows >= 1_000_000) {
            label = "All";
          }
          this.rowsPerPageOptions.push({ rows: rows.toString(), label: label });
        });
      }
    }

    if (this.options.selectRows && this.selectionMode === "single") {
      this.selectionMode = "multiple"; // "multipleWithCheckboxes"; // "multiple";
      this.selectedData = [];
    } else if (!this.options.selectRows && this.selectionMode === "multiple") {
      this.selectionMode = "single";
      this.selectedData = null;
    }

    if (this.options.sortMode != "none") {
      this.sortMode = this.options.sortMode;
    } else {
      this.sortMode = "single";
    }

    // Figure out if we have any row actions that will be displayed as icons
    if ((!this.rowActionsOriginal || this.rowActionsOriginal.length === 0) && this.options?.rowActionButton?.options) {
      this.rowActionsOriginal = [...this.options.rowActionButton.options];
    }
    if (Helper.equals(this.options.rowActionDisplayMode, "icons", true)) {
      this.rowActionsForIconDisplay = this.rowActionsOriginal.filter(x => x.actionId !== "divider");
    } else if (Helper.equals(this.options.rowActionDisplayMode, "overflow", true) && this.options?.rowActionButton?.options &&
      this.options.rowActionsForIconDisplay && this.options.rowActionsForIconDisplay.length > 0) {
      this.rowActionsForIconDisplay = [];
      this.options.rowActionsForIconDisplay.forEach(actionId => {
        // Find this row action
        const action = this.rowActionsOriginal.find(x => x.actionId === actionId);
        if (action) {
          // Add the action and remove it from the row action button options (if it's still there)
          this.rowActionsForIconDisplay.push(action);
          const index = this.options.rowActionButton.options.findIndex(x => x.actionId === actionId);
          if (index > -1) {
            this.options.rowActionButton.options.splice(index, 1);
          }
        } else {
          console.warn(`Unable to find row action with action id "${actionId}".`);
        }
      });
    } else {
      this.rowActionsForIconDisplay = [];
      if (this.options?.rowActionButton) {
        // We may have removed items when in a different display mode
        this.options.rowActionButton.options = [...this.rowActionsOriginal];
      }
    }
    // Figure out what our width should be based on 2.5em per icon displayed
    let width: number = (this.rowActionsForIconDisplay.length * 2.5);
    if (this.options.rowActionDisplayMode === 'menu' || this.options.rowActionDisplayMode === 'overflow') {
      width += 2.5;
    }
    this.rowMenuWidth = `width:${width}em;`;


    // Convert from our row action menu to prime context menu
    this.prepareContextMenu(null);

  }


  public getTableConfig() {
    if (!this.options) {
      // We need options assigned so we know options.tableId before we can do this
      return;
    }
    if (this.config && this.config.TableConfigurationId && Helper.equals(this.config.Description, this.options.tableId, true)) {
      // We already have the config specified for this table id loaded
      return;
    }
    this.uxService.tableService.findTableConfig(this.options.tableId).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(config => {
        if (config) {
          this.config = config;
          // console.error("found table config", this.config);
          this.options = this.uxService.tableService.applyTableConfig(this.config, this.options);
          this.configure();
          this.otherChangeCount++;
        }
      });
  }

  public prepareContextMenu(data: any) {

    // If no row action button options then no context menu
    if (!this.options?.rowActionButton?.options || this.options?.rowActionButton?.options.length === 0) {
      return;
    }

    // If we already have a context menu and it's not dynamic based on data supplied or if we have no data supplied then we're done
    if (this.contextMenu && this.contextMenu.length > 0 && (!this.contextMenuIsDynamic || !data)) {
      return;
    }

    this.contextMenu = [];
    this.options.rowActionButton.options.forEach((action) => {
      if (!action.icon && !action.label) {
        // Divider but not if it's first in our list and not if it's right after another separator
        if (this.contextMenu.length === 0) {
          // We don't add a separator as first menu item
        } else if (this.contextMenu.slice(-1)[0].separator) {
          // We don't add a separator right after another separator
        } else {
          this.contextMenu.push({ separator: true });
        }
      } else {
        // If we have a visible function, permission, or role requirement then note that the context menu is dynamic so it can get reevaluated for each row
        if (action.visible || action.requiresPermissionAccessArea || action.requiresRole) {
          this.contextMenuIsDynamic = true;
        }
        let include: boolean = true;
        if (this.contextMenuIsDynamic && data) {
          // If we have a visible function and data let's see if it's visible here
          include = this.systemService.actionIsVisible(action, data, {});
        }
        if (include) {
          const icon = IconHelper.parseIcon(action.icon);
          const item: MenuItem = {
            label: action.label, icon: icon.calculatedClasses, command: (event) => {
              // console.error("context menu native event");
              // console.error(event);
              let index = this.contextMenuRowIndex;
              if (index === -1) {
                index = this.contextMenuButtonRowIndex;
              }
              if (index === -1) {
                index = this.data.findIndex(x => x === this.selectedData);
              }
              const cargo: any = {
                index: index,
                selectedData: this.selectedData
              };
              let row: any = null;
              if (index > -1) {
                row = this.data[index];
              } else {
                row = this.selectedData; // TODO could be array!
              }
              const model: EventModel = new EventModel("context-menu", event, row, null, cargo);
              this.contextMenuRowIndex = -1;
              action.action(model);
            }
          };
          this.contextMenu.push(item);
        }
      }
    });

  }



  public getRowStyle(row: any, extraStyles: string = ""): SafeStyle {
    let style: string = this.options.rowStyle || "";
    if (extraStyles) {
      if (style) {
        style = `${style};${extraStyles}`;
      } else {
        style = extraStyles;
      }
    }
    if (this.options.getRowStyle) {
      try {
        const more = this.options.getRowStyle(row);
        if (more) {
          if (style) {
            style = `${style};${more}`;
          } else {
            style = more;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }



  public getCellStyle(col: PrimeColumn, data: any, row: any, extraStyles: string = ""): SafeStyle {
    const options: TableColumnOptions = (<any>col).options;
    let style: string = options.style || "";
    if (extraStyles) {
      if (style) {
        style = `${style};${extraStyles}`;
      } else {
        style = extraStyles;
      }
    }
    if (options.width) {
      if (style) {
        style = `${style};width:${options.width};`;
      } else {
        style = `width:${options.width};`;
      }
    }
    if (options.getStyle) {
      try {
        const more = options.getStyle(data, row);
        if (more) {
          if (style) {
            style = `${style};${more}`;
          } else {
            style = more;
          }
        }
      } catch (err) {
        Log.errorMessage(err);
      }
    }
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }

  getModel(row: any, propertyName: string, index: number): any {
    let value: any = null;
    if (!row) {
      return "";
    }
    if (!propertyName) {
      return "";
    }
    if (Helper.equals(propertyName, "_index0", true)) {
      return index;
    } else if (Helper.equals(propertyName, "_index1", true)) {
      return (index + 1);
    }
    try {
      if (propertyName.includes(".")) {
        const parts: string[] = propertyName.split(".");
        if (parts.length === 4) {
          value = row[parts[0]][parts[1]][parts[2]][parts[3]];
        } else if (parts.length === 3) {
          value = row[parts[0]][parts[1]][parts[2]];
        } else if (parts.length === 2) {
          value = row[parts[0]][parts[1]];
        } else {
          value = row[propertyName];
        }
      } else {
        value = row[propertyName];
      }
    } catch (err) {
      const message = `Error accessing ${propertyName}:`; // ${JSON.stringify(err)}`;
      Log.errorMessage(message);
      Log.errorMessage(err);
      value = "";
    }
    // console.error(propertyName, value, row);
    return value;
  }

  onModelChange($event: any, row: any, propertyName: string) {
    try {
      if (propertyName.includes(".")) {
        const parts: string[] = propertyName.split(".");
        if (parts.length === 3) {
          if ($event.data) {
            row[parts[0]][parts[1]][parts[2]] = $event.data;
          } else {
            row[parts[0]][parts[1]][parts[2]] = $event;
          }
        } else if (parts.length === 2) {
          if ($event.data) {
            row[parts[0]][parts[1]] = $event.data;
          } else {
            row[parts[0]][parts[1]] = $event;
          }
        } else {
          if ($event.data) {
            row[propertyName] = $event.data;
          } else {
            row[propertyName] = $event;
          }
        }
      } else {
        if ($event.data) {
          row[propertyName] = $event.data;
        } else {
          row[propertyName] = $event;
        }
      }
    } catch (err) {
      const message = `Error accessing ${propertyName}:`; // ${JSON.stringify(err)}`;
      Log.errorMessage(message);
      Log.errorMessage(err);
    }
  }

  trackByFn(index, item) {
    if (!this.options || !this.options.primaryKey || !item) {
      // We don't have options or a primary key or an object to get the primary key from then index based
      // value is all we can do.  We don't want index to collide with a primary key so use index*-1000.
      return (-1000 * index);
    }
    if (!item[this.options.primaryKey]) {
      return (-1000 * index);
    }
    return item[this.options.primaryKey];
  }
  trackByIndex(index, item) {
    return index; // or item.id
  }


  public actionIsVisible(action: Action, rowData: any): boolean {
    return this.systemService.actionIsVisible(action, rowData, {});
  }


  public actionExecute(action: Action, $event: any, rowData: any, rowIndex: number = -1, headerIndex: number = -1) {
    const payload = new EventModel(action?.actionId, $event, rowData, null, { rowIndex: rowIndex, headerIndex: headerIndex });
    if (action.action) {
      action.action(payload);
    }
  }


  public showMenu(menu: any, $event: any, rowData: any, rowIndex: number = -1, headerIndex: number = -1) {
    // console.error("show menu", menu, $event, rowData);
    this.prepareContextMenu(rowData);
    this.contextMenuButtonRowIndex = rowIndex;
    this.contextMenuButtonHeaderRowIndex = headerIndex;
    if (this.contextMenu && this.contextMenu.length > 0) {
      // console.error('ready to toggle');
      menu.toggle($event);
    }
  }


  public getRowExpandedHtml(row: any, index: number): SafeHtml {

    if (!this.options.expandedRowHtmlBuilder) {
      return this.sanitizer.bypassSecurityTrustHtml("");
    }

    const cargo: any = { index: index };
    if (Helper.isArray(this.selectedData)) {
      cargo.selectedRows = this.selectedData;
    } else {
      cargo.selectedRows = [this.selectedData];
    }

    const html = this.options.expandedRowHtmlBuilder(row, cargo.selectedRows, this.data, cargo);
    return this.sanitizer.bypassSecurityTrustHtml(html);

  }




  protected isRowSelected(row: any): boolean {
    if (!row || !this.selectedData) {
      return false;
    }
    if (Helper.isArray(this.selectedData)) {
      if (this.options.primaryKey) {
        const matches = this.selectedData.filter(x => x[this.options.primaryKey] === row[this.options.primaryKey]);
        return (matches.length > 0);
      } else {
        const matches = this.selectedData.filter(x => JSON.stringify(x) === JSON.stringify(row));
        return (matches.length > 0);
      }
    } else {
      if (this.options.primaryKey) {
        return (this.selectedData[this.options.primaryKey] === row[this.options.primaryKey]);
      } else {
        return (JSON.stringify(this.selectedData) === JSON.stringify(row));
      }
    }
  }

  protected isRowMenuCell(event: EventModel | Event): boolean {

    if (!event) {
      return false;
    }

    let originalEvent: Event = null;
    if (event instanceof EventModel) {
      originalEvent = event.event;
    } else {
      originalEvent = event;
    }

    if (!originalEvent) {
      return false;
    }

    let element: Element = null;
    if (originalEvent.target instanceof Element) {
      element = originalEvent.target;
    } else {
      Log.debugMessage("isRowMenuCell event.target is not an Element.");
      console.log(originalEvent.target);
      return false;
    }

    //console.error("isRowMenuCell event = ", originalEvent, element);

    // We have one column in our table which might be our row menu.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    let isMenu: boolean = false;
    try {
      isMenu = Helper.elementOrAncestorContainsId(element, "rowMenuCell", "startsWith");
      if (!isMenu) {
        isMenu = Helper.elementOrAncestorContainsClass(element, "rowMenuCell");
      }
      if (!isMenu) {
        isMenu = Helper.elementOrAncestorContainsClass(element, "bars");
      }
    } catch (err) {
      Log.errorMessage("Error attempting to find 'rowMenuCell' in original event path, target class name, or target parent class name.");
      Log.errorMessage(err);
      Log.errorMessage(element);
      Log.errorMessage(originalEvent);
    }

    return isMenu;

  }

  protected isRowCheckboxCell(event): boolean {
    // We have one column in our table which might be our checkbox.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    return Helper.eventTargetContainsClass(event, "p-checkbox");
  }


  protected isRowExpandCell(event): boolean {
    // We have one column in our table which might be an expand row icon.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    return Helper.eventTargetContainsClass(event, "expand-row-icon");
  }

  protected isRowDragHandle(event): boolean {
    // We have one column in our table which might be a drag handle.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    return Helper.eventTargetContainsClass(event, "cdk-drag-handle");
  }

  protected isRowDropdownCell(event): boolean {
    // We have one column in our table which might be a dropdown action button.  In cases where that cell was
    // the target of the row select mouse event we need to know that so we can react appropriately.
    return Helper.eventTargetContainsClass(event, "dropdown-toggle");
  }

  protected isLink(event): boolean {
    // See if the column clicked on is a link as we may want to handle firing events differently.
    let link = false;
    // console.error("link check", event);
    try {
      // if (event.target && event.target.className) {
      //   if (Helper.contains(event.target.className, "expand-row-icon", true)) {
      //     link = true;
      //   }
      // } else if (event.srcElement && event.srcElement.className) {
      //   if (Helper.contains(event.srcElement.className, "expand-row-icon", true)) {
      //     link = true;
      //   }
      // } else if (event.toElement && event.toElement.className) {
      //   if (Helper.contains(event.toElement.className, "expand-row-icon", true)) {
      //     link = true;
      //   }
      // }
      if (event.target?.parentElement?.href) {
        link = true;
      } else if (event.target?.parentNode?.href) {
        link = true;
      } else if (event.target?.innerHTML) {
        if (Helper.contains(event.target.innerHTML, "href", true)) {
          link = true;
        }
      }
    } catch (err) {
      Log.errorMessage("Error attempting to find link in event information.");
      Log.errorMessage(err);
      Log.errorMessage(event);
    }
    return link;
  }


  saveViewToMenu($event) {
    this.saveView.emit($event);
  }

  /**
   * This method takes an array of data objects that had their display order updated via a move
   * or drag and drop operation and then saves it via an api call using either merge or edit
   * methods as defined in the table options object.
   * @param {any[]} changedData - a list of objects that had display order updated.
   * @returns an array of IApiResponseWrapper objects.
   */
  saveOrderChangesToApi(changedData: any[]): IApiResponseWrapper[] {

    if (!changedData || changedData.length === 0) {
      // No changed data to save to api
      return [];
    }
    if (!this.options?.apiProperties) {
      // No api properties so no action possible
      return [];
    }
    if (this.options.orderApiUpdateType !== "merge" && this.options.orderApiUpdateType !== "edit") {
      // No api update requested
      return [];
    }

    // Sort out if we're doing merge or edit based on request but also based on available config
    let apiUpdateType: "merge" | "edit" = "edit";
    if (this.options.orderApiUpdateType === "merge") {
      apiUpdateType = "merge";
      if (!this.options.orderPropertyName) {
        // We don't have an order property name so not sure why this got called since this
        // method is to update because of an order change but in any case we for sure cannot
        // do a merge operation without it.
        console.warn("Table options requested update type of 'merge' but order property name is not defined so switching to 'edit'.");
        apiUpdateType = "edit";
      } else if (!this.options.apiProperties.documentation?.objectPrimaryKey) {
        // If doing merge then we must have primary keys for our merge object
        console.warn("Table options requested update type of 'merge' but api properties did not have a primary key defined so switching to 'edit'.");
        apiUpdateType = "edit";
      }
    }

    // Get keys we will need for merge object
    const keys: string[] = [];
    if (apiUpdateType === "merge") {
      // Doing merge so we need primary keys and path keys so we have them for our merge object
      if (Helper.isArray(this.options.apiProperties.documentation.objectPrimaryKey)) {
        keys.push(...(this.options.apiProperties.documentation.objectPrimaryKey as string[]));
      } else {
        keys.push(this.options.apiProperties.documentation.objectPrimaryKey as string);
      }
      if (Helper.isArray(this.options.apiProperties.pathModelProperties)) {
        keys.push(...(this.options.apiProperties.pathModelProperties as string[]));
      } else {
        keys.push(this.options.apiProperties.pathModelProperties as string);
      }
    }

    let apiCall: ApiCall = null;
    if (apiUpdateType === "edit") {
      apiCall = ApiHelper.createApiCall(this.options.apiProperties, ApiOperationType.Edit);
    } else {
      apiCall = ApiHelper.createApiCall(this.options.apiProperties, ApiOperationType.Merge);
    }

    // We won't be waiting for api responses here but we will return all of the observables in
    // case our caller wants to wait for the results.
    const results: IApiResponseWrapper[] = [];

    changedData.forEach(data => {
      const response: Observable<IApiResponseWrapper> = null;
      if (apiUpdateType === "edit") {
        // Call edit and put results into array we'll be returning
        this.apiService.execute(apiCall, data).subscribe((result: IApiResponseWrapper) => {
          results.push(result);
        });
      } else {
        const mergeData: any = {};
        // Merge data needs keys
        keys.forEach(key => {
          mergeData[key] = data[key];
        });
        // Merge data needs the order value which is what actually changed
        mergeData[this.options.orderPropertyName] = data[this.options.orderPropertyName];
        // Call merge and put results into array we'll be returning
        this.apiService.execute(apiCall, mergeData).subscribe((result: IApiResponseWrapper) => {
          results.push(result);
        });
      }
    });

    return results;

  }



  onTableConfig($event) {

    const options: ModalCommonOptions = ModalCommonOptions.defaultDataEntryModalOptions();
    options.titleIcon = "cog (solid)";
    options.title = `Table Configuration`;

    // Get model documentation in case we need to auto add columns inside our modal
    let listModel: any = null;
    let listModelName: string = "";
    let fullModel: any = null;
    let fullModelName: string = "";
    if (this.options.apiProperties) {
      fullModel = this.options.apiProperties?.documentation?.responseDataModelObject || this.options.apiProperties?.documentation?.requestAndResponseDataModelObject;
      fullModelName = this.options.apiProperties?.documentation?.responseDataModelDocumentationName || this.options.apiProperties?.documentation?.requestAndResponseDataModelDocumentationName;
      const endpoint = this.options.apiProperties.endpoints.find(x => x.type === ApiOperationType.List);
      if (endpoint?.documentation) {
        listModel = endpoint.documentation.responseDataModelObject;
        listModelName = endpoint.documentation.responseDataModelDocumentationName;
      }
      if (!listModel) {
        listModel = fullModel;
      }
      if (!listModelName) {
        listModelName = fullModelName;
      }
    }

    const modalRef = this.uxService.modal.ngbModalService.open(TableConfigurationModalComponent, ModalCommonOptions.toNgbModalOptions(options));
    // Set @Input() properties for our component being used as the modal content
    modalRef.componentInstance.options = options;
    this.options.apiProperties.documentation;
    modalRef.componentInstance.listModel = listModel;
    modalRef.componentInstance.listModelName = listModelName;
    modalRef.componentInstance.fullModel = fullModel;
    modalRef.componentInstance.fullModelName = fullModelName;
    modalRef.componentInstance.saveView.subscribe(($event) => {
      this.saveView.emit($event);
    });
    if (this.config) {
      // Always work with a copy of the object in case the user makes changes but then hits cancel button
      modalRef.componentInstance.data = Helper.deepCopy(this.config);
    } else {
      // We don't have a config so use our table options as the starting point
      modalRef.componentInstance.data = this.uxService.tableService.buildTableConfig(this.options);
    }

    // Set actions when modal promise is resolved with either ok or cancel
    modalRef.result.then((value: EventModelTyped<m5web.TableConfigurationViewModel>) => {
      // User hit ok so value.data is the data object.
      this.uxService.tableService.saveTableConfig(value.data).pipe(takeUntil(this.ngUnsubscribe)).subscribe((config: m5web.TableConfigurationViewModel) => {
        if (config) {
          this.config = config;
          this.options = this.uxService.tableService.applyTableConfig(this.config, this.options);
          this.configure();
          this.otherChangeCount++;
        }
      });
    }, (reason) => {
      // User hit cancel so nothing to save but maybe the config got delete and we should remove it
      // console.error("modal cancel", reason);
      if (Helper.equals(reason?.eventType, "delete", true)) {
        // User deleted the config so null out our reference to it
        this.config = null;
      }
    });

  }

  // public logError(message, event) {
  //   if (message) {
  //     console.error(message);
  //   }
  //   if (event) {
  //     console.error(event);
  //   }
  // }


}


export interface TableState {
  filterCriteria?: any;
  filterSelectionData?: FilterSelectionData;
  globalFilterText?: string;
}
