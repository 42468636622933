import { Injectable } from '@angular/core';
import { AppService, UserSetCallback } from './app.service';
import { ApiService } from '../api/api.service';
import { AppCacheService } from './app-cache.service';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5core from "projects/core-lib/src/lib/models/ngModelsCore5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { Helper, Log } from '../helpers/helper';
import { Subject, BehaviorSubject, Observable, of, AsyncSubject } from 'rxjs';
import { ApiProperties, ApiCall, Query, ApiOperationType, IApiResponseWrapperTyped } from '../api/ApiModels';
import { Api } from '../api/Api';
import { ApiHelper } from '../api/ApiHelper';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { BaseService } from './base.service';
import { TableOptions } from '../../../../common-lib/src/lib/table/table-options';
import { TableHelper } from '../../../../common-lib/src/lib/table/table-helper';
import { TableColumnOptions } from '../../../../common-lib/src/lib/table/table-column-options';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiModuleWeb } from '../api/Api.Module.Web';
import { map, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CaseService extends BaseService implements UserSetCallback {

  caseLabel: string = "Case";
  casePluralLabel: string = "Cases";
  caseLabelExternal: string = "Case Number";

  caseTypePickList: m5core.PickListSelectionViewModel[] = [];
  casePriorityPickList: m5core.PickListSelectionViewModel[] = [];

  private caseTemplatesSubject = new BehaviorSubject<m5.CaseTemplateEditViewModel[]>([]);
  getCaseTemplates() { return this.caseTemplatesSubject.asObservable(); }
  private caseTemplates: m5.CaseTemplateEditViewModel[] = [];
  private caseTemplateApiProperties: ApiProperties = null;
  private caseTemplateApiCall: ApiCall = null;
  private caseTemplateQuery: Query = null;

  protected apiProperties: ApiProperties;
  protected apiCallList: ApiCall;
  protected apiCallFull: ApiCall;


  constructor(protected apiService: ApiService,
    protected appService: AppService,
    protected cache: AppCacheService,
    protected sanitizer: DomSanitizer) {

    super();

    try {
      this.appService.userSetCallbackRegister(this);
      this.apiProperties = Api.Case();
      this.apiCallList = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.List);
      this.apiCallList.silent = true;
      this.apiCallFull = ApiHelper.createApiCall(this.apiProperties, ApiOperationType.Get);
      this.apiCallFull.silent = true;
      this.caseLabel = this.appService.getLabel("Case", "Case");
      this.casePluralLabel = this.appService.getLabel("CasePlural", "Cases");
      this.caseLabelExternal = this.appService.getLabel("ExternalCaseNumber", "Case Number");
      this.appService.appInfoFeed().pipe(takeUntil(this.ngUnsubscribe)).subscribe((info: m5core.ApplicationInformationModel) => {
        // Update labels on app info update
        this.caseLabel = this.appService.getLabel("Case", "Case");
        this.casePluralLabel = this.appService.getLabel("CasePlural", "Cases");
        this.caseLabelExternal = this.appService.getLabel("ExternalCaseNumber", "Case Number");
      });
      this.refreshPickLists();
      this.refreshCaseTemplates();
    } catch (err) {
      Log.errorMessage("Exception refreshing pick lists and case templates from service constructor");
      Log.errorMessage(err);
    }

  }


  /**
   * Method defined in UserSetCallback interface.
   */
  public userSet(user: m5sec.AuthenticatedUserViewModel): void {
    // New user set is typically from a login which may impact these items so reload them
    this.refreshPickLists();
    this.refreshCaseTemplates();
  }


  public getCaseListObjectByCaseNumber(caseNumber: number | string, template: m5.CaseTemplateEditViewModel): Observable<m5.CaseListViewModel> {
    const query: Query = new Query();
    // TODO use template to determine if this should be internal or external case number for the lookup
    query.Filter = `CaseNumber == ${caseNumber}`;
    return this.apiService.execute(this.apiCallList, query).pipe(
      map((result: IApiResponseWrapperTyped<m5.CaseListViewModel[]>) => {
        if (result.Data.Success && result.Data.Data && result.Data.Data.length > 0) {
          return result.Data.Data[0];
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }


  public getCaseListObject(caseId: number): Observable<m5.CaseListViewModel> {
    const query: Query = new Query();
    query.Filter = `CaseId == ${caseId}`;
    return this.apiService.execute(this.apiCallList, query).pipe(
      map((result: IApiResponseWrapperTyped<m5.CaseListViewModel[]>) => {
        if (result.Data.Success && result.Data.Data && result.Data.Data.length > 0) {
          return result.Data.Data[0];
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }


  public getCaseFullObject(caseId: number): Observable<m5.CaseEditViewModel> {
    return this.apiService.execute(this.apiCallFull, caseId).pipe(
      map((result: IApiResponseWrapperTyped<m5.CaseEditViewModel>) => {
        if (result.Data.Success && result.Data.Data) {
          return result.Data.Data;
        } else {
          return null;
        }
      }),
      takeUntil(this.ngUnsubscribe));
  }



  refreshPickLists(reportErrors: boolean = true) {

    this.apiService.loadPickList(Constants.PickList.CaseType).subscribe(result => {
      if (result.Data.Success) {
        this.caseTypePickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

    this.apiService.loadPickList(Constants.PickList.CasePriority).subscribe(result => {
      if (result.Data.Success) {
        this.casePriorityPickList = result.Data.Data || [];
      } else if (reportErrors) {
        this.appService.alertManager.addAlertFromApiResponse(result, null);
      }
    });

  }


  /**
  * Loads case template list models.  The models are used internally but also provided
  * for subscribers to pick up on these updates.  This method can be called by service users
  * when they know new case templates have been added and the models need to be updated.
  * @param reportErrors
  */
  refreshCaseTemplates(reportErrors: boolean = true, ignoreCache: boolean = false): Observable<m5.CaseTemplateEditViewModel[]> {
    if (!this.caseTemplateApiProperties || !this.caseTemplateApiCall || !this.caseTemplateQuery) {
      this.caseTemplateApiProperties = Api.CaseTemplate();
      this.caseTemplateApiCall = ApiHelper.createApiCall(this.caseTemplateApiProperties, ApiOperationType.List);
      this.caseTemplateApiCall.silent = true; // We can do this in the background without eye candy
      this.caseTemplateApiCall.redirectToLoginOnAuthenticationErrors = false;
      this.caseTemplateQuery = new Query("Description", Constants.RowsToReturn.All);
      // We need things like which groups and roles can create a case using a template so get full model
      this.caseTemplateQuery.Expand = "full";
    }
    this.caseTemplateApiCall.cacheIgnoreOnRead = ignoreCache;
    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m5.CaseTemplateEditViewModel[]>();
    this.apiService.execute(this.caseTemplateApiCall, this.caseTemplateQuery).subscribe((result: IApiResponseWrapperTyped<m5.CaseTemplateEditViewModel[]>) => {
      if (result.Data.Success) {
        this.caseTemplates = Helper.arraySort(result.Data.Data, "Description");
        this.caseTemplatesSubject.next(this.caseTemplates);
        subject.next(this.caseTemplates);
        subject.complete();
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, this.caseTemplateApiCall);
        }
      }
    });
    return subject.asObservable();
  }


  loadCaseTemplate(caseModel: m5.CaseEditViewModel, reportErrors: boolean = true): Observable<m5.CaseTemplateEditViewModel> {
    if (!caseModel || !caseModel.CaseTemplateId) {
      return of(this.getDefaultCaseTemplate());
    }
    const apiProp = Api.CaseTemplate();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.silent = true; // We can do this in the background without eye candy
    apiCall.redirectToLoginOnAuthenticationErrors = false;
    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m5.CaseTemplateEditViewModel>();
    this.apiService.execute(apiCall, caseModel.CaseTemplateId).subscribe((result: IApiResponseWrapperTyped<m5.CaseTemplateEditViewModel>) => {
      if (result.Data.Success) {
        subject.next(result.Data.Data);
        subject.complete();
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
        subject.next(this.getDefaultCaseTemplate());
        subject.complete();
      }
    });
    return subject.asObservable();
  }


  loadCaseCustomForm(template: m5.CaseTemplateEditViewModel, reportErrors: boolean = true): Observable<m5web.FormEditViewModel> {
    if (!template || !template.CustomAttributeFormId) {
      return of(null);
    }
    const apiProp = ApiModuleWeb.Form();
    const apiCall = ApiHelper.createApiCall(apiProp, ApiOperationType.Get);
    apiCall.silent = true; // We can do this in the background without eye candy
    apiCall.redirectToLoginOnAuthenticationErrors = false;
    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<m5web.FormEditViewModel>();
    this.apiService.execute(apiCall, template.CustomAttributeFormId).subscribe((result: IApiResponseWrapperTyped<m5web.FormEditViewModel>) => {
      if (result.Data.Success) {
        subject.next(result.Data.Data);
        subject.complete();
      } else {
        if (reportErrors) {
          this.appService.alertManager.addAlertFromApiResponse(result, apiCall);
        }
        subject.next(null);
        subject.complete();
      }
    });
    return subject.asObservable();
  }




  loadRelatedCases(caseModel: m5.CaseEditViewModel, template: m5.CaseTemplateEditViewModel, reportErrors: boolean = true): Observable<RelatedCases> {

    if (!caseModel) {
      return of(null);
    }

    // We won't know if we have child cases until we try to load them
    // We do know depends on and see also cases since we have a list of case ids for them.
    const relatedCaseIds: number[] = [];
    if (caseModel.DependsOnList && caseModel.DependsOnList.length > 0) {
      relatedCaseIds.push(...caseModel.DependsOnList);
    }
    if (caseModel.SeeAlsoList && caseModel.SeeAlsoList.length > 0) {
      relatedCaseIds.push(...caseModel.SeeAlsoList);
    }
    const queryChildren = new Query();
    queryChildren.Filter = `ParentCaseId == ${caseModel.CaseId}`;
    if (relatedCaseIds.length > 0) {
      queryChildren.Filter += ` || CaseId IN ( ${Helper.buildCsvString(relatedCaseIds)} )`;
    }
    queryChildren.Size = Constants.RowsToReturn.All;

    // AsyncSubject: A Subject that only emits its last value upon completion
    const subject = new AsyncSubject<RelatedCases>();

    this.apiService.execute(this.apiCallList, queryChildren).subscribe((result: IApiResponseWrapperTyped<m5.CaseListViewModel[]>) => {
      if (result.Data.Success) {
        let children: m5.CaseListViewModel[] = [];
        let dependsOn: m5.CaseListViewModel[] = [];
        let seeAlso: m5.CaseListViewModel[] = [];
        // Children
        children = result.Data.Data.filter(x => x.ParentCaseId == caseModel.CaseId);
        // Depends On
        if (caseModel.DependsOnList && caseModel.DependsOnList.length > 0) {
          dependsOn = result.Data.Data.filter(x => caseModel.DependsOnList.includes(x.CaseId));
        }
        // See Also
        if (caseModel.SeeAlsoList && caseModel.SeeAlsoList.length > 0) {
          seeAlso = result.Data.Data.filter(x => caseModel.SeeAlsoList.includes(x.CaseId));
        }
        // Sort
        if (template?.SupportInternalCaseNumber) {
          children.sort((a, b) => a.CaseNumber - b.CaseNumber);
          dependsOn.sort((a, b) => a.CaseNumber - b.CaseNumber);
          seeAlso.sort((a, b) => a.CaseNumber - b.CaseNumber);
        } else if (template?.SupportExternalCaseNumber) {
          children.sort((a, b) => a.ExternalCaseNumber.localeCompare(b.ExternalCaseNumber));
          dependsOn.sort((a, b) => a.ExternalCaseNumber.localeCompare(b.ExternalCaseNumber));
          seeAlso.sort((a, b) => a.ExternalCaseNumber.localeCompare(b.ExternalCaseNumber));
        } else {
          children.sort((a, b) => a.Title.localeCompare(b.Title));
          dependsOn.sort((a, b) => a.Title.localeCompare(b.Title));
          seeAlso.sort((a, b) => a.Title.localeCompare(b.Title));
        }
        // Done
        subject.next({ Children: children, DependsOn: dependsOn, SeeAlso: seeAlso });
        subject.complete();
      } else {
        if (reportErrors) {
          console.error(result);
        }
        subject.next(null);
        subject.complete();
      }
    });

    return subject.asObservable();

  }




  getCannotChangeSupervisorMessage(cases: m5.CaseEditViewModel, template: m5.CaseTemplateEditViewModel): string {
    const cannotChangeSupervisorMessage: string = "The supervisor cannot be changed by you.  If it needs to be changed please ask the current supervisor, a case manager, or a system administrator to make the change.";
    if (cases && cases.SupervisorContactId == this.appService.user.ContactId) {
      // The supervisor can change to a different supervisor
      return "";
    } else if (this.appService.security.hasPermission(this.appService.user, Constants.AccessArea.CaseManagement, Constants.Permission.Edit)) {
      // Case management permission holders can change to a different supervisor
      return "";
    } else if (this.appService.security.hasSysAdminPermission(this.appService.user)) {
      // Sys admins can change to a different supervisor
      return "";
    } else if (cases && !cases.SupervisorContactId) {
      // We don't have a current supervisor so we allow setting it
      return "";
    } else if (template) {
      if (template.SupervisorAssignment === "M") {
        // Manually assigned supervisor isn't something we can change
        return cannotChangeSupervisorMessage;
      } else if (template.SupervisorAssignment === "S") {
        // Supervisor assigned supervisor isn't something we can change
        return cannotChangeSupervisorMessage;
      } else {
        // Automatic and unassigned supervisor flags can be changed
        return "";
      }
    }
  }


  getCannotChangeReviewerMessage(cases: m5.CaseEditViewModel, template: m5.CaseTemplateEditViewModel): string {
    const cannotChangeReviewerMessage: string = "The reviewer cannot be changed by you.  If it needs to be changed please ask the current reviewer, a case manager, or a system administrator to make the change.";
    if (cases && cases.SystemStatus !== "R") {
      // The case is not in review status so any message about not changing reviewer would not be appropriate
      return "";
    } else if (cases && cases.SystemStatus === "R" && cases.AssignedToContactId == this.appService.user.ContactId) {
      // The reviewer can change to a different reviewer
      return "";
    } else if (this.appService.security.hasPermission(this.appService.user, Constants.AccessArea.CaseManagement, Constants.Permission.Edit)) {
      // Case managers can change to a different reviewer
      return "";
    } else if (this.appService.security.hasSysAdminPermission(this.appService.user)) {
      // Sys admins can change to a different reviewer
      return "";
    } else if (cases && cases.SystemStatus === "R" && !cases.AssignedToContactId) {
      // We don't have a current reviewer so we allow setting it
      return "";
    } else if (template) {
      if (template.ReviewAssignment === "M") {
        // Manually assigned reviewer isn't something we can change
        return cannotChangeReviewerMessage;
      } else if (template.ReviewAssignment === "S") {
        // Supervisor assigned reviewer isn't something we can change
        return cannotChangeReviewerMessage;
      } else if (template.ReviewAssignment === "G") {
        // Group member assigned reviewer isn't something we can change
        // TODO we could make this change if we could filter the contact picker to members of the specified group.
        return cannotChangeReviewerMessage;
      } else {
        // Automatic and unassigned reviewer flags can be changed
        return "";
      }
    }
  }




  getDefaultCaseTemplate(): m5.CaseTemplateEditViewModel {
    const model = new m5.CaseTemplateEditViewModel();
    model.SupportChildCase = true;
    model.SupportInternalCaseNumber = true;
    model.SupportExternalCaseNumber = false;
    model.SupportContactCaseNumber = false;
    model.SupportType = true;
    model.SupportTypeStatic = false;
    model.SupportCategory = true;
    model.SupportCategoryStatic = false;
    model.SupportGroup = true;
    model.SupportGroupStatic = false;
    model.SupportPriority = true;
    model.SupportStatus = true;
    model.SupportProgress = true;
    model.SupportPercentDone = true;
    model.SupportRiskLevel = true;
    model.SupportCustomer = true;
    model.SupportCustomerSite = true;
    model.SupportChangeLogging = true;
    model.SupportCorrespondence = false;
    model.SupportAddingTaskLists = false;
    model.SupportSystemTasks = false;
    model.SupportTriggers = false;
    model.SupportAdvancedSettings = false;
    model.SupportExtendedAttributes = false;
    return model;
  }


  getTaskAndPriorityIconHtml(
    model: m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel | m5.CaseTemplateEditViewModel,
    caseTypePickList: m5core.PickListSelectionViewModel[],
    casePriorityPickList: m5core.PickListSelectionViewModel[]
  ): string {

    // Look for type icon but use type text if no icon available
    let typeHtml = model.Type;
    let matches = caseTypePickList.filter(x => Helper.equals(x.Value, model.Type, true));
    if (matches && matches.length > 0) {
      const value = matches[0];
      typeHtml = value.DisplayText;
      if (value.Icon) {
        const icon = IconHelper.iconDataFromIconDescription(value.Icon);
        icon.title = value.DisplayText || value.Value;
        if (value.IconColor) {
          icon.color = value.IconColor;
        }
        icon.fontSize = "1.5em";
        typeHtml = icon.html;
      }
    } else {
      typeHtml = model.Type;
    }

    // Look for priority icon (no text alternate desired here)
    let priorityHtml = "";
    matches = casePriorityPickList.filter(x => Helper.equals(x.Value, model.Priority, true));
    if (matches && matches.length > 0) {
      const value = matches[0];
      if (value.Icon) {
        const icon = IconHelper.iconDataFromIconDescription(value.Icon);
        icon.title = `Priority: ${value.DisplayText || value.Value}`;
        if (value.IconColor) {
          icon.color = value.IconColor;
        }
        icon.fontSize = "1.5em";
        priorityHtml = icon.html;
      }
    }

    // Combine icons
    let html = typeHtml;
    if (priorityHtml) {
      html = `${priorityHtml}&nbsp;${typeHtml}`;
    }

    return html;

  }


  getDefaultTableOptions(propertyNames: string = "", tableIdSuffix: string = ""): TableOptions {

    const options: TableOptions = TableHelper.buildDefaultTableOptions(Api.Case(), propertyNames, tableIdSuffix);

    options.columns = [];
    options.columns.push(new TableColumnOptions("Type", "Type", "function"));
    // options.columns.slice(-1)[0].filterType = "none";
    options.columns.slice(-1)[0].filterType = "multiselect";
    options.columns.slice(-1)[0].filterPickListId = Constants.PickList.CaseType;
    options.columns.slice(-1)[0].sortable = true; // false;
    options.columns.slice(-1)[0].render = (row: m5.CaseTemplateEditViewModel) => {
      let html: string = this.getTaskAndPriorityIconHtml(row, this.caseTypePickList, this.casePriorityPickList);
      // For cases we may also have correspondence reply needed flag
      if (((row as unknown) as m5.CaseListViewModel).CorrespondenceReplyNeeded) {
        const icon = IconHelper.iconDataFromIconDescription("envelope");
        icon.title = "Reply Needed";
        icon.color = "danger";
        icon.fontSize = "0.75rem";
        html = `${html}&nbsp;${icon.html}`;
      }
      return this.sanitizer.bypassSecurityTrustHtml(html);
    };
    options.columns.slice(-1)[0].supportLateRender = true;
    options.columns.slice(-1)[0].toolTipType = "tooltip";
    options.columns.slice(-1)[0].toolTipPlacement = "top-left";
    options.columns.slice(-1)[0].toolTipTextFunction = (row: m5.CaseListViewModel) => {
      if (row && row.Type) {
        return row.Type;
      } else {
        return "";
      }
    };
    if (this.appService.settings.UseInternalCaseNumber) {
      options.columns.push(new TableColumnOptions("CaseNumber", `${this.caseLabel} #`));
      this.getDefaultTableOptionsAssignCaseDescriptionPopover(options.columns.slice(-1)[0]);
    }
    if (this.appService.settings.UseExternalCaseNumber) {
      options.columns.push(new TableColumnOptions("ExternalCaseNumber", this.caseLabelExternal));
      this.getDefaultTableOptionsAssignCaseDescriptionPopover(options.columns.slice(-1)[0]);
    }
    options.columns.push(new TableColumnOptions("Title"));
    this.getDefaultTableOptionsAssignCaseDescriptionPopover(options.columns.slice(-1)[0]);
    options.columns.push(new TableColumnOptions("ContactName", "Contact"));
    // options.columns.slice(-1)[0].sortable = false;
    options.columns.push(new TableColumnOptions("AssignedToContactName", "Assigned To"));
    // options.columns.slice(-1)[0].sortable = false;
    // options.columns.push(new TableColumnOptions("Status", "Status"));
    options.columns.push(new TableColumnOptions("Status", "Status", "picklist", Constants.PickList.CaseStatus));
    options.columns.push(new TableColumnOptions("OpenedDateTime", "Opened", "date"));
    options.columns.push(new TableColumnOptions("DueDateTime", "Due", "date"));
    options.columns.push(new TableColumnOptions("ActualCloseDateTime", "Closed", "date"));

    return options;

  }


  caseTooltipTitle(row: m5.CaseListViewModel | m5.CaseEditViewModel): string {
    if (!row) {
      return "";
    }
    if (!this.appService.settings || this.appService.settings.UseInternalCaseNumber) {
      return `${row.CaseNumber} - ${Helper.left(row.Title, 30, true)}`;
    } else if (this.appService.settings.UseExternalCaseNumber) {
      return `${row.ExternalCaseNumber} - ${Helper.left(row.Title, 30, true)}`;
    } else {
      return Helper.left(row.Title, 30, true);
    }
  }

  caseTooltipBody(row: m5.CaseListViewModel | m5.CaseEditViewModel): string {
    if (!row) {
      return "";
    }
    if (row.Description) {
      return row.Description.replace(/\n/g, '<br/>');
    } else if (row.Title) {
      return row.Title;
    } else {
      return "";
    }
  }


  getDefaultTableOptionsAssignCaseDescriptionPopover(col: TableColumnOptions): void {
    if (!col) {
      return;
    }
    col.toolTipType = "popover";
    col.toolTipTitleFunction = (row: m5.CaseListViewModel | m5.CaseEditViewModel) => {
      return this.caseTooltipTitle(row);
      // if (row) {
      //  if (this.appService.settings.UseInternalCaseNumber) {
      //    return `${row.CaseNumber} - ${Helper.left(row.Title, 30, true)}`;
      //  } else if (this.appService.settings.UseExternalCaseNumber) {
      //    return `${row.ExternalCaseNumber} - ${Helper.left(row.Title, 30, true)}`;
      //  } else {
      //    return Helper.left(row.Title, 30, true);
      //  }
      // } else {
      //  return "";
      // }
    };
    col.toolTipTextFunction = (row: m5.CaseListViewModel | m5.CaseEditViewModel) => {
      return this.caseTooltipBody(row);
      // if (row && row.Description) {
      //  return row.Description.replace(/\n/g, '<br/>');
      // } else if (row && row.Title) {
      //  return row.Title;
      // } else {
      //  return "";
      // }
    };
  }


  ngOnDestroy() {
    super.ngOnDestroy();
    this.caseTemplatesSubject.complete();
  }

  getTemplateWorkflowChart(template: m5.CaseTemplateEditViewModel): string {
    let chartContent = `---\ntitle: Case Workflow\n---\nflowchart LR\nOPEN -- Request Review --> REVIEW\n`;


    // CLOSED
    chartContent += `REVIEW --> SUCCESS\nREVIEW --> FAILURE\n`;
    chartContent += `subgraph CLOSED\n    direction BT\nL(Status: Closed)\n`;
    if (template.AllowReopen) {
      chartContent += `E(Reopen Allowed)\n`;
    }
    chartContent += `end\n`;

    // REVIEW GRAPH

    if (template.CaseAssignment === "A") {
      chartContent += `SUCCESS --Case Assigned To Owner--> CLOSED\nFAILURE --Case Assigned To Owner--> OPEN\n`;
    } else if (template.CaseAssignment === "M") {
      chartContent += `SUCESS --Case Assigned Manually--> CLOSED\nFAILURE --Case Assigned Manually--> OPEN\n`;
    } else if (template.CaseAssignment === "U") {
      chartContent += `REVIEW --> SUCCESS\nREVIEW --> FAILURE\n`;
    }

    chartContent += `subgraph REVIEW\n    direction BT\n`;
    if (template.ReviewInstructions) {
      chartContent += `B(Instructions: ` + template.ReviewInstructions + `)\n`;
    }

    chartContent += `end\n`;

    // FAILURE
    if (template.StatusWhenReviewFail || template.ProgressWhenReviewFail) {
      chartContent += `subgraph FAILURE\n    direction BT\n`;
      if (template.StatusWhenReviewFail) {
        chartContent += `    M(Status: ` + template.StatusWhenReviewFail + `)\n`;
      }
      if (template.ProgressWhenReviewFail) {
        chartContent += `    N(Progress: ` + template.ProgressWhenReviewFail + `)\n`;
      }
      chartContent += `end\n`;
    }

    // SUCCESS
    if (template.StatusWhenReviewSuccess || template.ProgressWhenReviewSuccess) {
      chartContent += `subgraph SUCCESS\n    direction BT\n`;
      if (template.StatusWhenReviewSuccess) {
        chartContent += `    J(Status: ` + template.StatusWhenReviewSuccess + `)\n`;
      }
      if (template.ProgressWhenReviewSuccess) {
        chartContent += `    K(Progress: ` + template.ProgressWhenReviewSuccess + `)\n`;
      }
      chartContent += `end\n`;
    }

    // OPEN
    if (template.CloseRuleReviewRequired) {
      chartContent += `subgraph OPEN\n    direction BT\nA(Review Required)\n`;
      chartContent += `end\n`;
    } else {
      chartContent += `OPEN-- Close Case --> CLOSED\n`;
    }

    return chartContent;
  }

}


export interface RelatedCases {
  Children: m5.CaseListViewModel[];
  DependsOn: m5.CaseListViewModel[];
  SeeAlso: m5.CaseListViewModel[];
}
