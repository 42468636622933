// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';
import * as m5core from './ngModelsCore5';


/**
 * @name Multi-Factor Authentication Options
 * @description
 * MFA options for the specified contact.
 */
export class MultiFactorAuthenticationOptionsViewModel {

    /**
     * @name ContactId
     * @description
     * The contact id of the MFA user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name TotpEnabled
     * @description
     * When true TOTP MFA support has been enabled for this contact.  Note that this is read-only and not enabled or disabled by
     * changing this value.
     * @servertype Boolean
     * @type {boolean}
     */
    TotpEnabled: boolean = false;

    /**
     * @name PhoneNumbers
     * @description
     * A list of phone numbers that are valid MFA options.
     * @servertype string array
     * @type {string[]}
     */
    PhoneNumbers: string[] = [];

    /**
     * @name EmailAddresses
     * @description
     * A list of email addresses that are valid MFA options.
     * @servertype string array
     * @type {string[]}
     */
    EmailAddresses: string[] = [];

}



/**
 * @name RestrictionsEditViewModel
 */
export class RestrictionsEditViewModel {

    /**
     * @name RequiredModulesAll
     * @servertype string array
     * @type {string[]}
     */
    RequiredModulesAll: string[] = [];

    /**
     * @name RequiredModulesAny
     * @servertype string array
     * @type {string[]}
     */
    RequiredModulesAny: string[] = [];

    /**
     * @name RequiredPermissionsAll
     * @servertype RestrictionPermissionEditViewModel array
     * @type {RestrictionPermissionEditViewModel[]}
     */
    RequiredPermissionsAll: RestrictionPermissionEditViewModel[] = [];

    /**
     * @name RequiredPermissionsAny
     * @servertype RestrictionPermissionEditViewModel array
     * @type {RestrictionPermissionEditViewModel[]}
     */
    RequiredPermissionsAny: RestrictionPermissionEditViewModel[] = [];

    /**
     * @name RequiredGroupIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RequiredGroupIdsAll: number[] = [];

    /**
     * @name RequiredGroupIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RequiredGroupIdsAny: number[] = [];

    /**
     * @name RequiredRoleIdsAll
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAll: number[] = [];

    /**
     * @name RequiredRoleIdsAny
     * @servertype long array
     * @type {number[]}
     */
    RequiredRoleIdsAny: number[] = [];

}



/**
 * @name RestrictionPermissionEditViewModel
 */
export class RestrictionPermissionEditViewModel {

    /**
     * @name Id
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name PermissionAreaType
     * @servertype String
     * @type {string}
     */
    PermissionAreaType: string = "";

    /**
     * @name PermissionArea
     * @servertype String
     * @type {string}
     */
    PermissionArea: string = "";

    /**
     * @name Rights
     * @servertype string array
     * @type {string[]}
     */
    Rights: string[] = [];

    /**
     * @name AllRightsRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AllRightsRequired: boolean = false;

}



/**
 * @name Login Name Available Request
 * @description
 * The properties used for a login name available request.
 */
export class LoginNameAvailableRequestViewModel {

    /**
     * @name Login
     * @description
     * The proposed login name for the user.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Email
     * @description
     * The email address for the user.  This is used to help propose possible alternate login names.
     * @servertype String
     * @type {string}
     */
    Email: string = "";

    /**
     * @name FirstName
     * @description
     * The fist name for the user.  This is used to help propose possible alternate login names.
     * @servertype String
     * @type {string}
     */
    FirstName: string = "";

    /**
     * @name LastName
     * @description
     * The last name for the user.  This is used to help propose possible alternate login names.
     * @servertype String
     * @type {string}
     */
    LastName: string = "";

}



/**
 * @name Multi-Factor Authentication Request
 * @description
 * The properties used for a multi-factor authentication request.
 */
export class MultiFactorAuthenticationRequestViewModel {

    /**
     * @name ContactId
     * @description
     * The contact id of the user performing MFA.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name Login
     * @description
     * The login of the user performing MFA.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Method
     * @description
     * The multi-factor authentication method to use.
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Target
     * @description
     * The multi-factor authentication target to use.  This is a masked value
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Source
     * @description
     * The multi-factor authentication target source.  Since the target is masked this is used to identify the unmasked value.
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name Value
     * @description
     * The multi-factor authentication value.  Note that MFA often happens in two steps.  The first step is asking which method
     * to utilize for MFA and this property is empty for that step.  The second step is submission of the MFA code delivered to
     * the recipient and this value contains that code.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Properties
     * @description
     * Other properties related to the MFA request.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Security Policy
 * @description
 * The security policy to use for the stated contact type.
 */
export class SecurityPolicyViewModel {

    /**
     * @name SecurityPolicyId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SecurityPolicyId: number = 0;

    /**
     * @name Description
     * @description
     * The description for this security policy.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ContactType
     * @description
     * The contact type this policy applies to.  Contact type '_' (system) is used as default policy when contact type is unknown.
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactTypeDescription
     * @description
     * The description for the contact type this policy applies to.
     * @servertype String
     * @type {string}
     */
    ContactTypeDescription: string = "";

    /**
     * @name PartitionId
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name GroupIds
     * @description
     * If the policy is for specific groups this is the list of groups the policy applies to.  This allows some groups like sys
     * admin to have stronger password than others.
     * @servertype long array
     * @type {number[]}
     */
    GroupIds: number[] = [];

    /**
     * @name Priority
     * @description
     * A priority setting can be used to pick a security policy when there is more than one that is possible although policies found
     * via group will always have priority over policies found by contact type.  Priorities are ranked lowest to highest with 0
     * being top priority.
     * @servertype Int32
     * @type {number}
     */
    Priority: number = 0;

    /**
     * @name MinimumPasswordLength
     * @servertype Int32
     * @type {number}
     */
    MinimumPasswordLength: number = 0;

    /**
     * @name AlphaCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    AlphaCharactersRequired: boolean = false;

    /**
     * @name NumericCharactersRequired
     * @servertype Boolean
     * @type {boolean}
     */
    NumericCharactersRequired: boolean = false;

    /**
     * @name MixedCaseRequired
     * @servertype Boolean
     * @type {boolean}
     */
    MixedCaseRequired: boolean = false;

    /**
     * @name PunctuationRequired
     * @servertype Boolean
     * @type {boolean}
     */
    PunctuationRequired: boolean = false;

    /**
     * @name PasswordCannotContainLogin
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainLogin: boolean = false;

    /**
     * @name PasswordCannotContainName
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainName: boolean = false;

    /**
     * @name PasswordCannotContainImportantDates
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordCannotContainImportantDates: boolean = false;

    /**
     * @name DaysBeforePasswordChangeRequired
     * @servertype Int32
     * @type {number}
     */
    DaysBeforePasswordChangeRequired: number = 0;

    /**
     * @name PreviousPasswordsToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordsToBlockRepeating: number = 0;

    /**
     * @name PreviousPasswordDaysToBlockRepeating
     * @servertype Int32
     * @type {number}
     */
    PreviousPasswordDaysToBlockRepeating: number = 0;

    /**
     * @name FailureCountUntilLoginBlocked
     * @servertype Int32
     * @type {number}
     */
    FailureCountUntilLoginBlocked: number = 0;

    /**
     * @name SecondsToBlockLogin
     * @servertype Int32
     * @type {number}
     */
    SecondsToBlockLogin: number = 0;

    /**
     * @name AuditLoginFailureBadLoginName
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadLoginName: boolean = false;

    /**
     * @name AuditLoginFailureBadPassword
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadPassword: boolean = false;

    /**
     * @name AuditLoginFailureBadMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginFailureBadMultiFactorAuthentication: boolean = false;

    /**
     * @name AuditLoginBlocked
     * @servertype Boolean
     * @type {boolean}
     */
    AuditLoginBlocked: boolean = false;

    /**
     * @name AuditPasswordChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordChanged: boolean = false;

    /**
     * @name AuditPasswordReset
     * @servertype Boolean
     * @type {boolean}
     */
    AuditPasswordReset: boolean = false;

    /**
     * @name AuditNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewPublicIpAddress: boolean = false;

    /**
     * @name AuditNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    AuditNewDeviceId: boolean = false;

    /**
     * @name AuditSecuritySettingsChanged
     * @servertype Boolean
     * @type {boolean}
     */
    AuditSecuritySettingsChanged: boolean = false;

    /**
     * @name SecurityQuestionAnswerRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerRequired: boolean = false;

    /**
     * @name SecurityQuestionAnswerFreeForm
     * @servertype Boolean
     * @type {boolean}
     */
    SecurityQuestionAnswerFreeForm: boolean = false;

    /**
     * @name SecurityQuestionAnswerCount
     * @servertype Int32
     * @type {number}
     */
    SecurityQuestionAnswerCount: number = 0;

    /**
     * @name SecurityQuestions
     * @servertype string array
     * @type {string[]}
     */
    SecurityQuestions: string[] = [];

    /**
     * @name LoginRecoveryToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    LoginRecoveryToEmailAddress: boolean = false;

    /**
     * @name PasswordChangeRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordChangeRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetRequiresSecurityQuestionAnswer
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetRequiresSecurityQuestionAnswer: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginToEmailAddress: boolean = false;

    /**
     * @name PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress
     * @servertype Boolean
     * @type {boolean}
     */
    PasswordResetLinkBasedOnLoginAndSecurityQuestionToEmailAddress: boolean = false;

    /**
     * @name TokenLifetimeHours
     * @servertype Int32
     * @type {number}
     */
    TokenLifetimeHours: number = 0;

    /**
     * @name TokenSupportSilentRefresh
     * @servertype Boolean
     * @type {boolean}
     */
    TokenSupportSilentRefresh: boolean = false;

    /**
     * @name TokenRejectOnNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewPublicIpAddress: boolean = false;

    /**
     * @name TokenRejectOnNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    TokenRejectOnNewDeviceId: boolean = false;

    /**
     * @name SupportLoginOperatingSystem
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginOperatingSystem: boolean = false;

    /**
     * @name SupportLoginFacebook
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginFacebook: boolean = false;

    /**
     * @name SupportLoginTwitter
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginTwitter: boolean = false;

    /**
     * @name SupportLoginGoogle
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginGoogle: boolean = false;

    /**
     * @name SupportLoginMicrosoft
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginMicrosoft: boolean = false;

    /**
     * @name SupportLoginApple
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginApple: boolean = false;

    /**
     * @name SupportLoginLinkedIn
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginLinkedIn: boolean = false;

    /**
     * @name SupportLoginYahoo
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginYahoo: boolean = false;

    /**
     * @name SupportLoginInstagram
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginInstagram: boolean = false;

    /**
     * @name SupportLoginDropbox
     * @servertype Boolean
     * @type {boolean}
     */
    SupportLoginDropbox: boolean = false;

    /**
     * @name RequireMultiFactorAuthentication
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthentication: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewPublicIpAddress
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewPublicIpAddress: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForNewDeviceId
     * @servertype Boolean
     * @type {boolean}
     */
    RequireMultiFactorAuthenticationForNewDeviceId: boolean = false;

    /**
     * @name RequireMultiFactorAuthenticationForGroups
     * @servertype long array
     * @type {number[]}
     */
    RequireMultiFactorAuthenticationForGroups: number[] = [];

    /**
     * @name MultiFactorAuthenticationCodeLength
     * @servertype Int32
     * @type {number}
     */
    MultiFactorAuthenticationCodeLength: number = 0;

    /**
     * @name MultiFactorAuthenticationFailOpen
     * @servertype Boolean
     * @type {boolean}
     */
    MultiFactorAuthenticationFailOpen: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationEmail
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationEmail: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationSms
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationSms: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationVoice
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationVoice: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppTotp
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppTotp: boolean = false;

    /**
     * @name SupportMultiFactorAuthenticationAppDuo
     * @servertype Boolean
     * @type {boolean}
     */
    SupportMultiFactorAuthenticationAppDuo: boolean = false;

    /**
     * @name NotificationGroupIdNewAccountCreated
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewAccountCreated: number = 0;

    /**
     * @name NotificationGroupIdLoginRecovery
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdLoginRecovery: number = 0;

    /**
     * @name NotificationGroupIdPasswordChanged
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordChanged: number = 0;

    /**
     * @name NotificationGroupIdPasswordReset
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordReset: number = 0;

    /**
     * @name NotificationGroupIdPasswordResetLink
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdPasswordResetLink: number = 0;

    /**
     * @name NotificationGroupIdMultiFactorAuthenticationCode
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdMultiFactorAuthenticationCode: number = 0;

    /**
     * @name NotificationGroupIdNewMultiFactorAuthenticationMethod
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewMultiFactorAuthenticationMethod: number = 0;

    /**
     * @name NotificationGroupIdNewPublicIpAddress
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewPublicIpAddress: number = 0;

    /**
     * @name NotificationGroupIdNewDeviceId
     * @servertype Int64
     * @type {number}
     */
    NotificationGroupIdNewDeviceId: number = 0;

    /**
     * @name Comments
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Private
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Password Reset Link Request
 * @description
 * The properties used for a password reset link request.
 */
export class PasswordResetLinkRequestViewModel {

    /**
     * @name Login
     * @description
     * The login name that identifies the user who will have a password reset link sent to them.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name BaseUrl
     * @description
     * The base url that the action link id will be appended to for the password reset link.
     * @servertype String
     * @type {string}
     */
    BaseUrl: string = "";

    /**
     * @name QueryStringParameterName
     * @description
     * The optional query string parameter name.  If no query string parameter name is provided the password reset link is appended
     * to the path of the base url.
     * @servertype String
     * @type {string}
     */
    QueryStringParameterName: string = "";

    /**
     * @name ValidMinutes
     * @description
     * The number of minutes the link is valid.
     * @servertype Int32
     * @type {number}
     */
    ValidMinutes: number = 0;

    /**
     * @name ValidCount
     * @description
     * The number of times the link is valid.
     * @servertype Int32
     * @type {number}
     */
    ValidCount: number = 0;

}



/**
 * @name Login Request
 * @description
 * The login properties used for a login request.
 */
export class LoginRequest {

    /**
     * @name Login
     * @description
     * The login name for the login request.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Password
     * @description
     * The password for the login request.
     * @servertype String
     * @type {string}
     */
    Password: string = "";

    /**
     * @name RememberMe
     * @description
     * A flag indicating if the login results should be 'remembered' which can impact token lifetime.
     * @servertype Boolean
     * @type {boolean}
     */
    RememberMe: boolean = false;

}



/**
 * @name Authenticated User
 * @description
 * This holds information about an authenticated user.
 */
export class AuthenticatedUserViewModel {

    /**
     * @name ContactType
     * @description
     * The contact type of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactType: string = "";

    /**
     * @name ContactId
     * @description
     * The contact id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @description
     * The name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactFirstName
     * @description
     * The first name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactFirstName: string = "";

    /**
     * @name ContactLastName
     * @description
     * The last name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactLastName: string = "";

    /**
     * @name ContactEmail
     * @description
     * The email address of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name PreferredLanguage
     * @description
     * The preferred language for the authenticated user.
     * @servertype String
     * @type {string}
     */
    PreferredLanguage: string = "";

    /**
     * @name Flags
     * @description
     * The flags for the authenticated user.
     * @servertype string array
     * @type {string[]}
     */
    Flags: string[] = [];

    /**
     * @name ParentContactType
     * @description
     * The parent contact type of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ParentContactType: string = "";

    /**
     * @name ParentContactId
     * @description
     * The parent contact id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentContactId: number = null;

    /**
     * @name ParentContactName
     * @description
     * The parent name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ParentContactName: string = "";

    /**
     * @name PartitionId
     * @description
     * This partition id of the authenticated user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @description
     * A description of the authenticated user partition.
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Login
     * @description
     * The login name of the authenticated user.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name Token
     * @description
     * The token for the authenticated user.
     * @servertype String
     * @type {string}
     */
    Token: string = "";

    /**
     * @name ApiKey
     * @description
     * If an api-key was used this is the api-key of the authenticated user.
     * @servertype String
     * @type {string}
     */
    ApiKey: string = "";

    /**
     * @name PartnerTokens
     * @description
     * A list of partner tokens where the key is the token type and the value is the token.  This is used for authenticating with
     * external systems like the support system.
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    PartnerTokens: {[key: string]:  string} = {};

    /**
     * @name MultiFactorAuthenticationTarget
     * @description
     * If a multi-factor authentication value has been submitted this is the target of that submission.
     * @servertype IB.Web.API.Models.v5.AuthenticatedUserMultiFactorAuthenticationOptionViewModel
     * @type {AuthenticatedUserMultiFactorAuthenticationOptionViewModel}
     */
    MultiFactorAuthenticationTarget: AuthenticatedUserMultiFactorAuthenticationOptionViewModel = null;

    /**
     * @name MultiFactorAuthenticationOptions
     * @description
     * The multi-factor authentication options available for the user.
     * @servertype AuthenticatedUserMultiFactorAuthenticationOptionViewModel array
     * @type {AuthenticatedUserMultiFactorAuthenticationOptionViewModel[]}
     */
    MultiFactorAuthenticationOptions: AuthenticatedUserMultiFactorAuthenticationOptionViewModel[] = [];

    /**
     * @name MultiFactorAuthenticationSupportsAppTotp
     * @description
     * When true multi-factor authentication option allows use of a TOTP authentication app.  Since TOTP may not be provisioned
     * in the list of authentication options this provides insight if registration of an app is allowable.
     * @servertype Boolean
     * @type {boolean}
     */
    MultiFactorAuthenticationSupportsAppTotp: boolean = false;

    /**
     * @name Settings
     * @description
     * The user settings for the authenticated user.
     * @servertype IB.Web.API.Models.v5.UserSettings
     * @type {UserSettings}
     */
    Settings: UserSettings = null;

    /**
     * @name Groups
     * @description
     * A collection of groups for the authenticated user.
     * @servertype AuthenticatedUserGroupViewModel array
     * @type {AuthenticatedUserGroupViewModel[]}
     */
    Groups: AuthenticatedUserGroupViewModel[] = [];

    /**
     * @name Roles
     * @description
     * A collection of roles for the authenticated user.
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name Permissions
     * @description
     * A collection of permissions for the authenticated user.
     * @servertype AuthenticatedUserPermissionViewModel array
     * @type {AuthenticatedUserPermissionViewModel[]}
     */
    Permissions: AuthenticatedUserPermissionViewModel[] = [];

    /**
     * @name AuthenticationData
     * @description
     * The authentication data that represents the user authentication results.
     * @servertype IB.Web.API.Models.AuthenticationData
     * @type {m.AuthenticationData}
     */
    AuthenticationData: m.AuthenticationData = null;

    /**
     * @name AsOfUtc
     * @description
     * A UTC timestamp that represents the age of the user data.  This can be used to determine if cached data should be refreshed.
     * @servertype DateTime
     * @type {Date}
     */
    AsOfUtc: Date = null;

}



/**
 * @name Authenticated User Multi-Factor Authentication Option
 * @description
 * This holds information about a multi-factor authentication option for a user.
 */
export class AuthenticatedUserMultiFactorAuthenticationOptionViewModel {

    /**
     * @name Method
     * @servertype String
     * @type {string}
     */
    Method: string = "";

    /**
     * @name Target
     * @servertype String
     * @type {string}
     */
    Target: string = "";

    /**
     * @name Source
     * @servertype String
     * @type {string}
     */
    Source: string = "";

    /**
     * @name DefaultOption
     * @servertype Boolean
     * @type {boolean}
     */
    DefaultOption: boolean = false;

}



/**
 * @name User Settings
 * @description
 * This holds user settings.
 */
export class UserSettings {

    /**
     * @name ApplicationSecurityPreferences
     * @description
     * Application security preferences for the user.
     * @servertype IB.Web.API.Models.v5.UserSettingsApplicationSecurityPreferences
     * @type {UserSettingsApplicationSecurityPreferences}
     */
    ApplicationSecurityPreferences: UserSettingsApplicationSecurityPreferences = null;

    /**
     * @name ApplicationSettings
     * @description
     * Application settings for the user.
     * @servertype IB.Web.API.Models.v5.ApplicationSettings
     * @type {ApplicationSettings}
     */
    ApplicationSettings: ApplicationSettings = null;

    /**
     * @name AsOfUtc
     * @description
     * A UTC timestamp that represents the age of the user settings data.  This can be used to determine if cached settings should
     * be refreshed.
     * @servertype DateTime
     * @type {Date}
     */
    AsOfUtc: Date = null;

}



/**
 * @name User Settings Application Security Preferences
 * @description
 * This holds user settings related to application security preferences.
 */
export class UserSettingsApplicationSecurityPreferences {

    /**
     * @name ExternalErrorTracking
     * @description
     * An Analytics Depth value: None, Anonymous, Identifier, Name.
     * @servertype String
     * @type {string}
     */
    ExternalErrorTracking: string = "";

    /**
     * @name ExternalAnalyticsForUsage
     * @description
     * An Analytics Depth value: None, Anonymous, Identifier, Name.
     * @servertype String
     * @type {string}
     */
    ExternalAnalyticsForUsage: string = "";

    /**
     * @name ExternalAnalyticsForScreenCapture
     * @description
     * An Analytics Depth value: None, Anonymous, Identifier, Name.
     * @servertype String
     * @type {string}
     */
    ExternalAnalyticsForScreenCapture: string = "";

    /**
     * @name InAppAnalyticsForUsage
     * @description
     * An Analytics Depth value: None, Anonymous, Identifier, Name.
     * @servertype String
     * @type {string}
     */
    InAppAnalyticsForUsage: string = "";

}



/**
 * @name Application Settings
 * @description
 * This holds application settings.
 */
export class ApplicationSettings {

    /**
     * @name QuickStartCompletedDateTime
     * @description
     * The date time at which the user completed the user quick start wizard.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QuickStartCompletedDateTime: Date = null;

    /**
     * @name PortalHomePage
     * @description
     * The users portal home page.
     * @servertype String
     * @type {string}
     */
    PortalHomePage: string = "";

}



/**
 * @name Authenticated User Group
 * @description
 * This holds information about a group for an authenticated user.
 */
export class AuthenticatedUserGroupViewModel {

    /**
     * @name GroupId
     * @description
     * The group id of the group.
     * @servertype Int64
     * @type {number}
     */
    GroupId: number = 0;

    /**
     * @name GroupDescription
     * @description
     * A description of the group.
     * @servertype String
     * @type {string}
     */
    GroupDescription: string = "";

    /**
     * @name Description
     * @description
     * A description of the group membership assignment.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Status
     * @description
     * The status of this group member.  Possible values include:
     * P = Pending (Request to join is pending group owner approval)
     * A = Active (Member is active in group)
     * I = Inactive (Member is defined as inactive based on group business rules)
     * H = Hold (Member is on hold and cannot access group resources until status is changed)
     * E = Expired (Member is out of valid date range for group membership)
     * L = Leave Requested (Member request to leave the group is pending group owner approval and is treated as active until request
     * is processed)
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name GroupOwner
     * @description
     * When true the member is a group owner.
     * @servertype Boolean
     * @type {boolean}
     */
    GroupOwner: boolean = false;

    /**
     * @name CanImpersonateGroup
     * @description
     * When true the member is allowed to impersonate the group and actions taken in the context of the group can appear to have
     * been done by the group as opposed to the member.
     * @servertype Boolean
     * @type {boolean}
     */
    CanImpersonateGroup: boolean = false;

    /**
     * @name ContactGroupMembershipId
     * @description
     * The id of the group membership object.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactGroupMembershipId: number = 0;

    /**
     * @name Reasons
     * @description
     * A list of reasons the user was assigned this group.
     * @servertype AuthenticatedUserAssignmentReason array
     * @type {AuthenticatedUserAssignmentReason[]}
     */
    Reasons: AuthenticatedUserAssignmentReason[] = [];

}



/**
 * @name AuthenticatedUserAssignmentReason
 */
export class AuthenticatedUserAssignmentReason {

    /**
     * @name Reason
     * @description
     * The reason the user has this assignment.
     * @servertype IB.Web.API.Models.v5.AuthenticatedUserReasonHasAssignment Enum
     * @type {AuthenticatedUserReasonHasAssignment}
     */
    Reason: AuthenticatedUserReasonHasAssignment = null;

    /**
     * @name ReasonDescription
     * @description
     * A description explaining why the user has this assignment.
     * @servertype String
     * @type {string}
     */
    ReasonDescription: string = "";

    /**
     * @name ReasonContactId
     * @description
     * If the assignment was due to a group this user belongs to this is the contact id of the group.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReasonContactId: number = null;

}



/**
 * @name AuthenticatedUserReasonHasAssignment Enum
 * @readonly
 * @enum {number}
 */
export enum AuthenticatedUserReasonHasAssignment {

    /**
     * Unknown
     */
    Unknown = 0,

    /**
     * Assigned To Contact
     */
    AssignedToContact = 1,

    /**
     * Assigned To Group Contact Belongs To
     */
    AssignedToGroupContactBelongsTo = 2,

    /**
     * Assigned To Contact Type Automatically
     */
    AssignedToContactTypeAutomatically = 3

}



/**
 * @name Authenticated User Role
 * @description
 * This holds information about a role for an authenticated user.
 */
export class AuthenticatedUserRoleViewModel {

    /**
     * @name RoleId
     * @description
     * The role id of the role.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = 0;

    /**
     * @name Description
     * @description
     * A description of the role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RoleRatio
     * @description
     * A ratio that can be used to assign a 'weight' to the role for this user.
     * @servertype Int32
     * @type {number}
     */
    RoleRatio: number = 0;

    /**
     * @name Reasons
     * @description
     * A list of reasons the user was assigned this role.
     * @servertype AuthenticatedUserAssignmentReason array
     * @type {AuthenticatedUserAssignmentReason[]}
     */
    Reasons: AuthenticatedUserAssignmentReason[] = [];

}



/**
 * @name Authenticated User Permission
 * @description
 * This holds information about a permission for an authenticated user.
 */
export class AuthenticatedUserPermissionViewModel {

    /**
     * @name AreaType
     * @servertype String
     * @type {string}
     */
    AreaType: string = "";

    /**
     * @name Area
     * @servertype String
     * @type {string}
     */
    Area: string = "";

    /**
     * @name Rights
     * @servertype String
     * @type {string}
     */
    Rights: string = "";

    /**
     * @name Roles
     * @description
     * A list of roles that contributed to this permission setting.
     * @servertype AuthenticatedUserRoleViewModel array
     * @type {AuthenticatedUserRoleViewModel[]}
     */
    Roles: AuthenticatedUserRoleViewModel[] = [];

    /**
     * @name ContributingRoles
     * @description
     * A string of comma separated roles that contributed to this permission setting.
     * @servertype String
     * @type {string}
     */
    ContributingRoles: string = "";

    /**
     * @name ImplicitAccess
     * @description
     * When true the permission was granted as part of implicit access rights.
     * @servertype Boolean
     * @type {boolean}
     */
    ImplicitAccess: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments regarding this security area assignment.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

}



/**
 * @name Login Recovery Request
 * @description
 * The properties used for a login recovery request.
 */
export class LoginRecoveryRequestViewModel {

    /**
     * @name Email
     * @description
     * The email address to use for finding login name(s).
     * @servertype String
     * @type {string}
     */
    Email: string = "";

}



/**
 * @name Login Change Request
 * @description
 * The properties used for a login change request.
 */
export class LoginChangeRequest {

    /**
     * @name ContactId
     * @description
     * The contact id of the user for this login change.
     * @servertype Int64
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name CurrentLogin
     * @description
     * The current login name.
     * @servertype String
     * @type {string}
     */
    CurrentLogin: string = "";

    /**
     * @name NewLogin
     * @description
     * The desired new login name.
     * @servertype String
     * @type {string}
     */
    NewLogin: string = "";

}



/**
 * @name Password Change Request
 * @description
 * The properties used for a login change request.
 */
export class PasswordChangeRequest {

    /**
     * @name Login
     * @description
     * The login name for the user.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name CurrentPassword
     * @description
     * The current password.
     * @servertype String
     * @type {string}
     */
    CurrentPassword: string = "";

    /**
     * @name NewPassword
     * @description
     * The new password.
     * @servertype String
     * @type {string}
     */
    NewPassword: string = "";

    /**
     * @name NewPasswordConfirmed
     * @description
     * The new password confirmed.
     * @servertype String
     * @type {string}
     */
    NewPasswordConfirmed: string = "";

    /**
     * @name ClientInformation
     * @description
     * Client information about the system requesting the password change.  Used for audit purposes.
     * @servertype String
     * @type {string}
     */
    ClientInformation: string = "";

    /**
     * @name ForcedReset
     * @description
     * Flag if the password is being forced to reset by a system administrator.
     * @servertype Boolean
     * @type {boolean}
     */
    ForcedReset: boolean = false;

}



/**
 * @name Password View Request
 * @description
 * Information regarding a password view request.
 */
export class PasswordViewRequest {

    /**
     * @name Login
     * @description
     * The login name for the user.
     * @servertype String
     * @type {string}
     */
    Login: string = "";

    /**
     * @name ClientInformation
     * @description
     * Client information about the system requesting the password change.  Used for audit purposes.
     * @servertype String
     * @type {string}
     */
    ClientInformation: string = "";

}



/**
 * @name PermissionEditViewModel
 */
export class PermissionEditViewModel {

    /**
     * @name SecurityArea
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name ReadSingle
     * @servertype Boolean
     * @type {boolean}
     */
    ReadSingle: boolean = false;

    /**
     * @name Read
     * @servertype Boolean
     * @type {boolean}
     */
    Read: boolean = false;

    /**
     * @name Add
     * @servertype Boolean
     * @type {boolean}
     */
    Add: boolean = false;

    /**
     * @name Edit
     * @servertype Boolean
     * @type {boolean}
     */
    Edit: boolean = false;

    /**
     * @name Delete
     * @servertype Boolean
     * @type {boolean}
     */
    Delete: boolean = false;

    /**
     * @name Output
     * @servertype Boolean
     * @type {boolean}
     */
    Output: boolean = false;

    /**
     * @name Execute
     * @servertype Boolean
     * @type {boolean}
     */
    Execute: boolean = false;

    /**
     * @name Full
     * @servertype Boolean
     * @type {boolean}
     */
    Full: boolean = false;

}



/**
 * @name RoleDetailEditViewModel
 * @description
 * The Role Detail table contains information about security access areas and rights for the specified role.
 */
export class RoleDetailEditViewModel {

    /**
     * @name RoleDetailId
     * @description
     * The id of the Role Detail.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleDetailId: number = null;

    /**
     * @name RoleId
     * @description
     * The id of the role this detail belongs to.
     * @servertype Int64
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name SecurityAreaType
     * @description
     * This is the security area type.  Possible values include:
     * TB = Table
     * TG = Table Group
     * RP = Report
     * RG = Report Group
     * PA = Permission Area
     * SI = Sensitive Information
     * @servertype String
     * @type {string}
     */
    SecurityAreaType: string = "PA";

    /**
     * @name SecurityArea
     * @description
     * The security area that is part of this role.  This is based on the security area type and could be a table name, table group,
     * report, report group or permission area (standard or custom).  For security area type of sensitive information this is a
     * category of sensitive information as defined in a pick list and used in the data table support object's sensitive information
     * handler data.
     * @servertype String
     * @type {string}
     */
    SecurityArea: string = "";

    /**
     * @name AllowReadSingle
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowReadSingle: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowRead
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowAdd
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowEdit
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowDelete
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowOutput
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowExecute
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name AllowFull
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    AllowFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyReadSingle
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyReadSingle: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyRead
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyRead: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyAdd
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyAdd: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyEdit
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyEdit: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyDelete
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyDelete: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyOutput
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyOutput: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyExecute
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyExecute: RoleDetailPermissionEditViewModel = null;

    /**
     * @name DenyFull
     * @servertype IB.Web.API.Models.v5.RoleDetailPermissionEditViewModel
     * @type {RoleDetailPermissionEditViewModel}
     */
    DenyFull: RoleDetailPermissionEditViewModel = null;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name RoleDetailPermissionEditViewModel
 */
export class RoleDetailPermissionEditViewModel {

    /**
     * @name Enabled
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name ScopeNone
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNone: boolean = false;

    /**
     * @name ScopeOwner
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeOwner: boolean = false;

    /**
     * @name ScopeSupervisor
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeSupervisor: boolean = false;

    /**
     * @name ScopeNexus
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeNexus: boolean = false;

    /**
     * @name ScopeGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeGroup: boolean = false;

    /**
     * @name ScopeChildGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeChildGroup: boolean = false;

    /**
     * @name ScopeGroupMembers
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeGroupMembers: boolean = false;

    /**
     * @name ScopeChildGroupMembers
     * @servertype Boolean
     * @type {boolean}
     */
    ScopeChildGroupMembers: boolean = false;

}



/**
 * @name RoleListViewModel
 * @description
 * The Role table contains information about roles.  Roles are used to manage security credentials.
 */
export class RoleListViewModel {

    /**
     * @name RoleId
     * @description
     * The id of the Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name ExternalRoleId
     * @description
     * An optional external role id for this role.
     * @servertype String
     * @type {string}
     */
    ExternalRoleId: string = "";

    /**
     * @name Description
     * @description
     * The description for this role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Anonymous
     * @description
     * When true the role is used to define access for anonymous users.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForGroup: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name AutomaticForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForCustomer: boolean = false;

    /**
     * @name AutomaticForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForAgent: boolean = false;

    /**
     * @name AutomaticForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForVendor: boolean = false;

    /**
     * @name AutomaticForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForDirectory: boolean = false;

    /**
     * @name AutomaticForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForGroup: boolean = false;

    /**
     * @name AutomaticForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForServiceProvider: boolean = false;

}



/**
 * @name RoleEditViewModel
 * @description
 * The Role table contains information about roles.  Roles are used to manage security credentials.
 */
export class RoleEditViewModel {

    /**
     * @name RoleId
     * @description
     * The id of the Role.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    RoleId: number = null;

    /**
     * @name ExternalRoleId
     * @description
     * An optional external role id for this role.
     * @servertype String
     * @type {string}
     */
    ExternalRoleId: string = "";

    /**
     * @name Description
     * @description
     * The description for this role.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true the role is enabled.  Setting to false provides an easy way to cause a role to be temporarily ignored until later
     * enabled again.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name Anonymous
     * @description
     * When true the role is used to define access for anonymous users.
     * @servertype Boolean
     * @type {boolean}
     */
    Anonymous: boolean = false;

    /**
     * @name ValidForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForCustomer: boolean = false;

    /**
     * @name ValidForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForAgent: boolean = false;

    /**
     * @name ValidForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForVendor: boolean = false;

    /**
     * @name ValidForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForDirectory: boolean = false;

    /**
     * @name ValidForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForGroup: boolean = false;

    /**
     * @name ValidForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    ValidForServiceProvider: boolean = false;

    /**
     * @name AutomaticForCustomer
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForCustomer: boolean = false;

    /**
     * @name AutomaticForAgent
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForAgent: boolean = false;

    /**
     * @name AutomaticForVendor
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForVendor: boolean = false;

    /**
     * @name AutomaticForDirectory
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForDirectory: boolean = false;

    /**
     * @name AutomaticForGroup
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForGroup: boolean = false;

    /**
     * @name AutomaticForServiceProvider
     * @servertype Boolean
     * @type {boolean}
     */
    AutomaticForServiceProvider: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Details
     * @description
     * List of details for the role.
     * @servertype RoleDetailEditViewModel array
     * @type {RoleDetailEditViewModel[]}
     */
    Details: RoleDetailEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



