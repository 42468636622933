import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCommonOptions } from 'projects/common-lib/src/lib/modal/modal-common-options';
import { TableOptions } from 'projects/common-lib/src/lib/table/table-options';
import { TableColumnOptions } from 'projects/common-lib/src/lib/table/table-column-options';
import { TableHelper } from 'projects/common-lib/src/lib/table/table-helper';
import { Log, Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { ButtonItem, Action, EventModelTyped } from 'projects/common-lib/src/lib/ux-models';
import { IconHelper } from 'projects/common-lib/src/lib/image/icon/icon-helper';
import { Subject } from 'rxjs';
import { ContactService } from 'projects/core-lib/src/lib/services/contact.service';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { DataEditorFormModalWrapperBaseClass } from 'projects/common-lib/src/lib/ux/data-editor/data-editor-form-modal-wrapper-base-class';

@Component({
  selector: 'ib-input-contact-form-modal',
  templateUrl: './input-contact-form-modal.component.html',
  styleUrls: ['./input-contact-form-modal.component.css']
})
export class InputContactFormModalComponent extends DataEditorFormModalWrapperBaseClass<m5.ContactEditViewModel> implements OnInit, OnChanges, OnDestroy {

  @Input() contactType: string = null;

  instantMessengerButton1: ButtonItem = null;
  instantMessengerButton2: ButtonItem = null;


  constructor(
    protected contactService: ContactService,
    protected appService: AppService,
    protected apiService: ApiService,
    protected sanitizer: DomSanitizer,
    public modal: NgbActiveModal) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.instantMessengerButton1 = this.getInstantMessengerButton();
    this.instantMessengerButton2 = this.getInstantMessengerButton();
  }
  // ngOnChanges(changes: SimpleChanges) {
  //  super.ngOnChanges(changes);
  // }
  // ngOnDestroy() {
  //  super.ngOnDestroy();
  // }

  onClickEmail($event: any, email: string) {
    Helper.openEmail(email);
  }

  onClickCall($event: any, phone: string) {
    Helper.openCall(phone);
  }

  onMessengerClickChat($event: any, network: string, address: string) {
    Helper.openInstantMessengerChat(network, address, null);
  }

  onMessengerClickCall($event: any, network: string, address: string) {
    Helper.openInstantMessengerCall(network, address, null);
  }

  onClickBrowse($event: any, site: string) {
    Helper.openUrl(site, true);
  }

  getInstantMessengerButton(): ButtonItem {

    const button = new ButtonItem("Chat", "comment", "outline-dark");
    button.size = "sm";
    /*
    "G = Google" + Environment.NewLine +
    "S = Skype" + Environment.NewLine +
    "I = ICQ" + Environment.NewLine +
    "F = Facebook Messenger" + Environment.NewLine +
    "W = WhatsApp" + Environment.NewLine +
    "C = WeChat" + Environment.NewLine +
    "O = Other" + Environment.NewLine +
    "U = Unknown" );
     */
    button.getLabel = (data: m5.ContactEditViewModel, cargo: any) => {
      if (!data) {
        return "Chat";
      }
      let network = data.ImNetwork;
      if (cargo && cargo.alternate) {
        network = data.AlternateImNetwork;
      }
      if (Helper.equals(network, "G", true)) {
        return "Google";
      } else if (Helper.equals(network, "S", true)) {
        return "Skype";
      } else if (Helper.equals(network, "I", true)) {
        return "ICQ";
      } else if (Helper.equals(network, "F", true)) {
        return "Facebook";
      } else if (Helper.equals(network, "W", true)) {
        return "WhatsApp";
      } else if (Helper.equals(network, "C", true)) {
        return "WeChat";
      } else if (Helper.equals(network, "O", true)) {
        return "Other";
      } else {
        return "Unknown";
      }
    };
    button.getIcon = (data: m5.ContactEditViewModel, cargo: any) => {
      if (!data) {
        return "comment";
      }
      let network = data.ImNetwork;
      if (cargo && cargo.alternate) {
        network = data.AlternateImNetwork;
      }
      if (Helper.equals(network, "G", true)) {
        return "google (brand)";
      } else if (Helper.equals(network, "S", true)) {
        return "skype (brand)";
      } else if (Helper.equals(network, "I", true)) {
        return "comment";
      } else if (Helper.equals(network, "F", true)) {
        return "facebook-messenger (brand)";
      } else if (Helper.equals(network, "W", true)) {
        return "whatsapp (brand)";
      } else if (Helper.equals(network, "C", true)) {
        return "weixin (brand)";
      } else {
        return "comment";
      }
    };

    button.options.push(new Action("goggle", "Google", "google (brand)", "", (event: EventModelTyped<m5.ContactEditViewModel>) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "G";
      } else {
        this.data.ImNetwork = "G";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("skype", "Skype", "skype (brand)", "", (event) => {
      console.error("cargo", event.cargo);
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "S";
      } else {
        this.data.ImNetwork = "S";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("icq", "ICQ", "comment", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "I";
      } else {
        this.data.ImNetwork = "I";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("facebook", "Facebook", "facebook-messenger (brand)", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "F";
      } else {
        this.data.ImNetwork = "F";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("whatsapp", "WhatsApp", "whatsapp (brand)", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "W";
      } else {
        this.data.ImNetwork = "W";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("wechat", "WeChat", "weixin (brand)", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "C";
      } else {
        this.data.ImNetwork = "C";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("other", "Other", "comment", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "O";
      } else {
        this.data.ImNetwork = "O";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));
    button.options.push(new Action("unknown", "Unknown", "comment", "", (event) => {
      if (event && event.cargo && event.cargo.alternate) {
        this.data.AlternateImNetwork = "U";
      } else {
        this.data.ImNetwork = "U";
      }
      button.label = button.getLabel(this.data, event.cargo);
      button.icon = button.getIcon(this.data, event.cargo);
    }));

    return button;

  }

}
