import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ElementRef, Inject, Optional, Renderer2, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';
import { ɵgetDOM as getDOM } from '@angular/platform-browser';
import createTextMaskInputElement from 'lib/input-mask/core/src/createTextMaskInputElement';

/**
 * Defines possible mask config values and their defaults.
 * From https://github.com/text-mask/text-mask/tree/master/angular2
 */
export class TextMaskConfig {
  // An array of either characters or Regex, one for each character of mask, or a function that returns the same
  mask: Array<string | RegExp> | ((raw: string) => Array<string | RegExp>) | false = [];
  guide?: boolean = true;
  placeholderChar?: string = '_';
  pipe?: (conformedValue: string, config: TextMaskConfig) => false | string | object = undefined;
  keepCharPositions?: boolean = true;
  showMask?: boolean;
}


export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputTextComponent),
  multi: true
};

@Component({
  selector: 'ib-input-text',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  host: {
    '(input)': '_handleInput($event.target.value)',
    '(blur)': 'onTouched()',
    '(_compositionstart)': '_compositionStart()',
    '(_compositionend)': '_compositionEnd($event.target.value)'
  }
})
export class InputTextComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public maxlength: number = 524288; // default
  @Input() public minlength: number;

  @Input() public type: string = "text"; // text, email, password
  public inputType: string = "text";

  /**
   * An object containing options for input-masking. If null, no masking will be used.
   * Any properties not provided will be set with the TextMaskConfig class defaults
   */
  @Input() textMaskConfig: TextMaskConfig = null;

  /** An object created by createTextMaskInputElement which handles masking logic. */
  private textMaskInputElement: any;
  /** The HTML Input element to be masked. */
  private inputElement: HTMLInputElement;
  /** Whether the user is creating a composition string (IME events). */
  private _composing = false;

  constructor(
    protected apiService: ApiService,
    protected uxService: UxService,
    // The following three contrustor params exist only for input masking purposes.
    private _renderer: Renderer2,
    private _elementRef: ElementRef,
    @Optional() @Inject(COMPOSITION_BUFFER_MODE) private _compositionMode: boolean) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.type) {
      if (Helper.equals(this.type, "text", true)) {
        this.inputType = "text";
      } else if (Helper.equals(this.type, "string", true)) {
        this.inputType = "text";
      } else if (Helper.equals(this.type, "email", true)) {
        this.inputType = "email";
      } else if (Helper.equals(this.type, "password", true)) {
        this.inputType = "password";
      } else {
        Log.errorMessage(`Unexpected text input type setting ${this.type}.  Expected values include: "text", "email", "password".  If no other appropriate input control exists please use "text".`);
        this.inputType = "text";
      }
    } else {
      this.inputType = "text";
    }

    // Forms created from database may have 0 for max length which should be interpreted as no max
    if (!this.maxlength) {
      this.maxlength = 524288; // default for html input
    }

    // Configure masking if textMaskConfig input was provided
    if (this.textMaskConfig) {
      this.configureMask();
    }

  }


  writeValue(value: any) {
    super.writeValue(value);

    // Update the mask if necessary
    if (this.textMaskConfig) {
      this.writeValueMask(value);
    }
  }


  // All of the following methods only relate to input-masking. They are slightly modified from
  // the custom Directive inside the 'angular2-text-mask' library written by M.K. Safi.
  // https://github.com/text-mask/text-mask/tree/master/angular2
  //
  // The original directive could not be used in conjunction with this component because
  // it clashes with our custom value accessor.
  //
  // Instead, the logic from that directive has been added here, and the core library
  // has been referenced from 'lib/input-mask/core/src/createTextMaskInputElement'.
  //
  // See 'lib/_readme.txt' for more info

  /**
   * Performs masking operations that should be triggered by ngOnChanges
   */
  private configureMask() {
    if (this._compositionMode == null) {
      this._compositionMode = !this._isAndroid();
    }
    // Add any default values not specified by input
    this.textMaskConfig = { ...new TextMaskConfig(), ...this.textMaskConfig };

    this._setupMask(true);
    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(this.inputElement.value);
    }
  }

  /**
   * Handles masking a new value when updated via ngModel
   * @param value
   */
  private writeValueMask(value) {
    this._setupMask();

    // set the initial value for cases where the mask is disabled
    const normalizedValue = value == null ? '' : value;
    this._renderer.setProperty(this.inputElement, 'value', normalizedValue);

    if (this.textMaskInputElement !== undefined) {
      this.textMaskInputElement.update(value);
    }
  }

  /**
   * Handles masking a new value when typed by the user
   * @param value
   */
  private _handleInput(value) {
    if (!this._compositionMode || (this._compositionMode && !this._composing)) {
      this._setupMask();

      if (this.textMaskInputElement !== undefined) {
        this.textMaskInputElement.update(value);

        // get the updated value
        value = this.inputElement.value;
        this.onChangeCallback(value);
      }
    }
  }

  private _setupMask(create = false) {
    if (!this.inputElement) {
      this.inputElement = this._elementRef.nativeElement.getElementsByTagName('INPUT')[0];
    }

    if (this.inputElement && create) {
      this.textMaskInputElement = createTextMaskInputElement(
        Object.assign({ inputElement: this.inputElement }, this.textMaskConfig)
      );
    }

  }

  _compositionStart(): void {
    this._composing = true;
  }

  _compositionEnd(value: any): void {
    this._composing = false;
    this._compositionMode && this._handleInput(value);
  }

  // These two methods are used by the textMaskInputElement to report changes
  onChange = (value: any) => {
    this.onChangeCallback(value);
  };
  onTouched = () => {
    this.onTouchedCallback();
  };


  /**
   * We must check whether the agent is Android because composition events
   * behave differently between iOS and Android.
   */
  _isAndroid(): boolean {
    const userAgent = getDOM() ? getDOM().getUserAgent() : '';
    return /android (\d+)/.test(userAgent.toLowerCase());
  }

}
