<ng-progress></ng-progress>
<div class="site-wrapper-dock">
  <div [ngStyle]="backgroundImageNgStyle">
    <!-- This must be within so we can see the background image, since this is a transparent feature. -->
    <ib-image-dock *ngIf="nav.showStandardSiteElements && menuItems.length > 0"
                    [position]="position"
                    [menuItems]="menuItems"></ib-image-dock>
    
    <div [ngClass]="{'site-container-dock-right': position === 'right',
                     'site-container-dock-left': position === 'left',
                     'site-container-dock-top': position === 'top',
                     'site-container-dock-bottom': position === 'bottom'}">
      <div class="container-fluid">
        <ib-alert></ib-alert>
        <router-outlet></router-outlet>
      </div>
     </div>
  </div>

  <div *ngIf="nav.showStandaloneFooter || nav.showStandardSiteElements">
    <footer class="{{themeClasses}} ib-dock-nav-bottom"
            [ngStyle]="{'border-top': adjustLightThemeExtendedFooter === false ? 'none' : 'initial'}"
            [ngClass]="{'mobile': nav.isMobile()}">
      <div class="ib-nav-bottom-content">
        <ng-container *ngFor="let footer of nav.footers; last as isLast">
          <span [innerHtml]="footer | safe: 'html'"></span>
          <span *ngIf="!isLast">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
        </ng-container>

        <div class="float-end">
          <!-- support -->
          <div *ngIf="showHelpMenu && nav.showStandardSiteElements"
               class="float-start pt-1">
            <ib-help-button [helpTopic]="helpTopic"
                            [dropdownPlacement]="'top-right'"
                            [tooltipPlacement]="'top-right'"
                            dropDownWrapperClass="ib-nav-top-content-item"
                            dropDownButtonClass="ib-nav-top-content-button">
            </ib-help-button>
          </div>

          <!-- user options -->
          <div *ngIf="nav.showStandardSiteElements"
                class="float-start pt-1">
            <ib-user-options [dropdownPlacement]="'top-right'"
                             [avatarTooltipPlacement]="'top-right'"
                             [buttonTooltipPlacement]="'top-right'"></ib-user-options>
          </div>
        </div>

      </div>
    </footer>
  </div>

  <!-- This positions on top of the footer to give it an extended look when image dock is mounted on the bottom -->
  <div *ngIf="nav.showStandardSiteElements && position === 'bottom' && menuItems.length > 0"
        class="{{themeClasses}}"
        [ngStyle]="{'border-top': adjustLightThemeExtendedFooter === false ? '1px solid #c8c8c8' : 'initial'}"
        [ngClass]="{'footer-extension-transparent': hasExtendedFooter === false,
                    'footer-extension': hasExtendedFooter === true }">
  </div>
</div>