import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgModel } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { NgbDateAdapter, NgbDateStruct, NgbDateNativeAdapter, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { NgbDateIsoStringAdapterService } from 'projects/common-lib/src/lib/input/input-date/ngb-date-iso-string-adapter.service';
import { format } from 'date-fns';
import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputDateComponent),
  multi: true
};

// NOTE: ngb suggests that the probably we want the below providers in our main app module.
@Component({
  selector: 'ib-input-date',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, { provide: NgbDateAdapter, useClass: NgbDateIsoStringAdapterService }]
})
export class InputDateComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.

  @Input() public type: string = "date"; // date, time, datetime

  @Input() public timeOfDay: string = "";
  @Input() public includeSeconds: boolean = true;
  @Input() public stepHours: number = 1;
  @Input() public stepMinutes: number = 1;
  @Input() public stepSeconds: number = 1;

  public inputType: string = "date";

  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
    // Trigger desired styling by forcing an icon name here
    this.suffixIcon = "calendar-alt";
  }

  ngOnInit() {
    // Trigger desired styling by forcing an icon name here
    this.suffixIcon = "calendar-alt";
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    // Trigger desired styling by forcing an icon name here
    this.suffixIcon = "calendar-alt";
    // configure is called from ngOnChanges in the InputBaseComponent
    // this.configure();
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (this.type) {
      if (Helper.equals(this.type, "date", true)) {
        this.inputType = "date";
      } else if (Helper.equals(this.type, "time", true)) {
        this.inputType = "time";
      } else if (Helper.equals(this.type, "datetime", true)) {
        this.inputType = "datetime";
      } else {
        Log.errorMessage(`Unexpected date input type setting ${this.type}.  Expected values include: "date", "time", "datetime".  If no other appropriate input control exists please use "date".`);
        this.inputType = "date";
      }
    } else {
      this.inputType = "date";
    }
  }

  preventInput(event) {
    event.preventDefault();
  }

  toggleDatePicker(element) {
    if (this.readonly || this.disabled) {
      return;
    }
    element.toggle();
  }

  toggleTimePicker(element, event) {
    if (this.readonly || this.disabled) {
      return;
    }
    element.toggle(event);
  }

  // Bug Fix Notes: This should not be called with the 3 number inputs because it overrides the natural
  // ability of the input fields to work with the delete key and would overwrite our entire current
  // ngModel value to null every time the user hit delete/backspace in a time field. Hitting delete
  // in the datepicker will still set the time values to null unless additional logic is added to
  // change the format of this.value depending on the current value but it starts getting
  // more complicated and it's easier to deal with the time fields being set to null on date delete.
  public fireKeyUp(event: KeyboardEvent, control: NgModel) {
    super.fireKeyUp(event, control);

    // For date pickers where the value is not required we allow the delete key and backspace key
    // to set the value to null.
    if (this.required) {
      return;
    }

    // See if key was delete (key code 46) or backspace (key code 8)
    if (event.keyCode === 46 || event.keyCode === 8) {
      this.value = null;
    }
  }

  validateTimeFormat(time: string): boolean {
    // Checks that timeOfDay is in a HH:MM:SS format
    const val = /(?:[01][0-9]|2[0-3]):(?:[0-5][0-9]):(?:[0-5][0-9])/.test(time);
    return val;
  }

  setTimeOfDay(): void {
    // If a valid value was passed in as 'timeOfDay' then the time is set to it
    if (this.inputType == "date" && this.timeOfDay) {
      if (this.validateTimeFormat(this.timeOfDay)) {
        this.hour = parseInt(this.timeOfDay.substring(0, 2), 10);
        this.minute = parseInt(this.timeOfDay.substring(3, 5), 10);
        this.second = parseInt(this.timeOfDay.substring(6, 8), 10);
      }
    }
  }

  get date(): string {
    return this.value;
  }

  /**
  Set just date portion of iso date time string to our internal value.  time selector is separate
  input controls so we don't want to overwrite the time portion in this setter.
  */
  set date(date: string) {
    let d: Date;
    if (this.value) {
      d = new Date(this.value);
    } else {
      d = new Date();
    }
    // We may have a date set but want to maintain our time
    const update = new Date(date);
    d.setFullYear(update.getUTCFullYear());
    d.setMonth(update.getUTCMonth());
    d.setDate(update.getUTCDate());
    this.value = format(d, Constants.DateFormat.FnsIsoDateTime);
  }

  // TODO ALL BELOW NEEDS TESTING
  get hour(): number {
    if (this.value) {
      const d = new Date(this.value);
      return d.getHours();
    } else {
      return 12;
    }
  }

  set hour(hour: number) {
    let d: Date;
    if (this.value) {
      d = new Date(this.value);
    } else {
      d = new Date();
    }
    d.setHours(hour);
    this.value = format(d, Constants.DateFormat.FnsIsoDateTime);
  }

  get minute(): number {
    if (this.value) {
      const d = new Date(this.value);
      return d.getMinutes();
    } else {
      return 0;
    }
  }

  set minute(minute: number) {
    let d: Date;
    if (this.value) {
      d = new Date(this.value);
    } else {
      d = new Date();
    }
    d.setMinutes(minute);
    this.value = format(d, Constants.DateFormat.FnsIsoDateTime);
  }

  get second(): number {
    if (this.value) {
      const d = new Date(this.value);
      return d.getSeconds();
    } else {
      return 0;
    }
  }

  set second(second: number) {
    let d: Date;
    if (this.value) {
      d = new Date(this.value);
    } else {
      d = new Date();
    }
    d.setSeconds(second);
    this.value = format(d, Constants.DateFormat.FnsIsoDateTime);
  }

}
