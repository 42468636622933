import * as Constants from "projects/core-lib/src/lib/helpers/constants";
import * as m from "projects/core-lib/src/lib/models/ngCoreModels";
import * as m5 from "projects/core-lib/src/lib/models/ngModels5";
import * as m5web from "projects/core-lib/src/lib/models/ngModelsWeb5";
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';

export class FormHelper {

  /**
   *
   * @param group
   * @param label
   * @param objectName
   * @param propertyName
   * @param propertyDataType
   * @param width
   * @param propertyPickListId
   */
  public static addControlToGroup(
    group: m5web.FormControlGroupEditViewModel,
    label: string,
    objectName: string,
    propertyName: string,
    propertyDataType: string,
    width: string = "W",
    propertyPickListId: string = ""
  ): m5web.FormControlGroupEditViewModel {
    const control: m5web.FormControlEditViewModel = new m5web.FormControlEditViewModel();
    control.ControlType = "S";
    control.Label = label;
    control.ObjectName = objectName;
    control.PropertyName = propertyName;
    control.PropertyDataType = propertyDataType;
    if (Helper.equals(propertyDataType, "string", true)) {
      control.ControlType = "InputText";
    } else if (Helper.equals(propertyDataType, "integer", true) || Helper.equals(propertyDataType, "float", true)) {
      control.ControlType = "InputNumber";
    } else if (Helper.equals(propertyDataType, "datetime", true) || Helper.equals(propertyDataType, "date", true) || Helper.equals(propertyDataType, "time", true)) {
      control.ControlType = "InputDateTime";
    } else if (Helper.equals(propertyDataType, "boolean", true)) {
      control.ControlType = "InputCheckbox";
    }
    control.Width = width;
    control.OptionsPickListId = propertyPickListId;
    if (!group.Controls) {
      group.Controls = [];
    }
    group.Controls.push(control);
    return group;
  }


  public static getInputControlName(controlModel: m5web.FormControlEditViewModel): string {
    const name: string = Helper.getFirstDefinedString(controlModel.Label, controlModel.Watermark, controlModel.HelpText, controlModel.PropertyName, Helper.createBase36Guid());
    return name.replace(":", "").replace("*", "");
  }


  /**
   * We get input control values on a continual basis since it represents the model being changed
   * but we only need to log errors one time not on each access so this array of ids that have
   * triggered errors can help us not spam the log with errors.
   */
  public static recentErrorFormControlIds: number[] = [];

  /**
   * Get the value from data for the specified control.
   * @param controlModel
   * @param data
   * @param logErrors
   * @returns
   */
  public static getInputControlValue(controlModel: m5web.FormControlEditViewModel, data: any, propertyName: string = "", logErrors: boolean = true): any {

    if (!controlModel) {
      Log.errorMessage("Control model object is null.");
      return "";
    }
    if (!controlModel.ObjectName && logErrors) {
      if (!FormHelper.recentErrorFormControlIds.includes(controlModel.FormControlId)) {
        Log.errorMessage(`Control Id ${controlModel.FormControlId}: No ObjectName specified.`);
        FormHelper.recentErrorFormControlIds.push(controlModel.FormControlId);
      }
      return "";
    }
    if (!propertyName) {
      // Default property name to use when none is specified is the property name in the control model.
      // We allow others to be requested for specific scenarios where we want secondary property values
      // for the same control.
      propertyName = controlModel.PropertyName;
    }
    if (!propertyName && logErrors) {
      // We would have defaulted to controlModel.PropertyName above so this error message is correct.
      Log.errorMessage(`Control Id ${controlModel.FormControlId}: No PropertyName specified.`);
      return "";
    }


    let value: any = "";
    try {
      if (controlModel.ObjectGrandchildName) {
        value = data[controlModel.ObjectName][controlModel.ObjectChildName][controlModel.ObjectGrandchildName][propertyName];
      } else if (controlModel.ObjectChildName) {
        value = data[controlModel.ObjectName][controlModel.ObjectChildName][propertyName];
      } else {
        value = data[controlModel.ObjectName][propertyName];
      }
    } catch (err) {
      let message = `Control Id ${controlModel.FormControlId}: Error accessing ${controlModel.ObjectName}.${controlModel.ObjectChildName}.${controlModel.ObjectGrandchildName}.${propertyName}:`; // ${JSON.stringify(err)}`;
      message = message.replace("...", ".").replace("..", ".").replace("..", ".");
      if (logErrors) {
        if (!FormHelper.recentErrorFormControlIds.includes(controlModel.FormControlId)) {
          Log.errorMessage(message);
          if (err) {
            Log.errorMessage(err);
          }
          FormHelper.recentErrorFormControlIds.push(controlModel.FormControlId);
        }
      }
      value = "";
    }

    return value;

  }


  /**
   * Get object from the data object referenced by this control.
   * @param controlModel
   * @param data
   * @param logErrors
   * @returns
   */
  public static getInputControlObject(controlModel: m5web.FormControlEditViewModel, data: any, logErrors: boolean = true): any {

    if (!controlModel.ObjectName && logErrors) {
      if (!FormHelper.recentErrorFormControlIds.includes(controlModel.FormControlId)) {
        Log.errorMessage(`Control Id ${controlModel.FormControlId}: No ObjectName specified.`);
        FormHelper.recentErrorFormControlIds.push(controlModel.FormControlId);
      }
      return "";
    }

    let value: any = null;
    try {
      if (controlModel.ObjectGrandchildName) {
        value = data[controlModel.ObjectName][controlModel.ObjectChildName][controlModel.ObjectGrandchildName];
      } else if (controlModel.ObjectChildName) {
        value = data[controlModel.ObjectName][controlModel.ObjectChildName];
      } else {
        value = data[controlModel.ObjectName];
      }
    } catch (err) {
      let message = `Control Id ${controlModel.FormControlId}: Error accessing ${controlModel.ObjectName}.${controlModel.ObjectChildName}.${controlModel.ObjectGrandchildName}:`; // ${JSON.stringify(err)}`;
      message = message.replace("...", ".").replace("..", ".").replace("..", ".");
      if (logErrors) {
        if (!FormHelper.recentErrorFormControlIds.includes(controlModel.FormControlId)) {
          Log.errorMessage(message);
          if (err) {
            Log.errorMessage(err);
          }
          FormHelper.recentErrorFormControlIds.push(controlModel.FormControlId);
        }
      }
      value = null;
    }

    return value;

  }


  public static fromControlTypeToDataType(controlType: string): string {

    if (Helper.equals(controlType, "InputText", true) || Helper.equals(controlType, "InputTextArea", true)) {
      return "String";
    } else if (Helper.equals(controlType, "InputNumber", true)) {
      return "Integer"; // could be Float
    } else if (Helper.equals(controlType, "InputDateTime", true)) {
      return "DateTime"; // could be Date or Time
    } else if (Helper.equals(controlType, "InputCheckbox", true)) {
      return "Boolean";
    } else if (Helper.equals(controlType, "InputContact", true) || Helper.equals(controlType, "InputAsset", true)) {
      return "Integer";
    } else {
      return "String";
    }

  }

  /*
    table.AddColumn( "PropertyDataType" , "VarChar(100)" , "'String'" , ColumnProperty.None , "Property Data Type" ,
                    "The data type of this property.  Possible values include:" + Environment.NewLine +
                    //"Inferred = Inferred from object and property" + Environment.NewLine +
                    "String = String" + Environment.NewLine +
                    "Integer = Integer" + Environment.NewLine +
                    "Float = Float" + Environment.NewLine +
                    //"Number = Number (not known if integer or floating point)" + Environment.NewLine +
                    "DateTime = DateTime" + Environment.NewLine +
                    "Date = Date" + Environment.NewLine +
                    "Time = Time" + Environment.NewLine +
                    "Boolean = Boolean" + Environment.NewLine +
                    "ListString = List of Strings" + Environment.NewLine +
                    "ListObject = List of Objects" + Environment.NewLine +
                    "Object = Object" + Environment.NewLine +
                    "Other = Other" + Environment.NewLine +
                    "Unknown = Unknown" );
    table.AddColumn( "ControlType" , "VarChar(100)" , "'InputText'" , ColumnProperty.None , "Control Type" ,
                    "Type of control.  Possible options include:" + Environment.NewLine +
                    //"Standard = Standard (The standard control for the data type defined by the property data type.)" + Environment.NewLine +
                    "Text = Text (Text from the contents value or asset id.)" + Environment.NewLine +
                    "HTML = HTML (HTML text from the contents value or asset id.)" + Environment.NewLine +
                    "Image = Image (Image from the asset id or asset url.)" + Environment.NewLine +
                    "Video = Video (Video from the asset id or asset url.)" + Environment.NewLine +
                    "Attachment = Attachment (Attachment to be associated with the specified object.)" + Environment.NewLine +
                    "FormButtons = Form Buttons (Buttons defined for the form - e.g. Save and Cancel buttons.)" + Environment.NewLine +
                    "Button = Button (Button to execute the click event.  Button text is taken from contents value.  " +
                                "Save buttons are automatically added for the form and are not needed as a control.)" + Environment.NewLine +
                    "Form = Form (An embedded form referenced in the embedded form id.)" + Environment.NewLine +
                    "InputText = Text Input (A single line text input.)" + Environment.NewLine +
                    "InputNumber = Number Input (A numeric input.)" + Environment.NewLine +
                    "InputTags = Tags Input (Tags for the specified object.)" + Environment.NewLine +
                    "InputTextArea = Text Area Input (A multiple line text area input.)" + Environment.NewLine +
                    "InputSelect = Select Input (A drop down selection input.)" + Environment.NewLine +
                    "InputMultiSelect = MultiSelect Input (A multiple selection input.)" + Environment.NewLine +
                    "InputCheckbox = Checkbox Input (A check box input.)" + Environment.NewLine +
                    "InputRadio = Radio Input (A radio input.)" + Environment.NewLine +
                    "InputDateTime = Date Time Input (A date time picker input.)" + Environment.NewLine +
                    "InputContactPicker = Contact Picker Input (A contact picker component.)" + Environment.NewLine +
                    "InputAssetPicker = Asset Picker Input (An asset picker component.)" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    "" + Environment.NewLine +
                    //"Component = Component (A component reference document in contents and properties.)" + Environment.NewLine +
                    "" );
  */

  public static buildFormFromPropertyMetaData(properties: m.PropertyMetaDataViewModel[], objectName: string): m5web.FormEditViewModel {

    if (!properties) {
      properties = [];
    }
    properties.sort((a, b) => a.InputDisplayOrder - b.InputDisplayOrder);

    const form: m5web.FormEditViewModel = new m5web.FormEditViewModel();
    const group: m5web.FormControlGroupEditViewModel = new m5web.FormControlGroupEditViewModel();
    group.GroupType = "A";
    group.GroupScope = "B";

    // TODO support multiple groups?

    properties.forEach(property => {
      if (property.IsHidden) {
        // No form control for hidden properties
        return; // i.e. continue
      }
      const control: m5web.FormControlEditViewModel = new m5web.FormControlEditViewModel();
      control.Label = property.InputLabel || property.Description;
      control.Description = property.InputDescription;
      control.ObjectName = objectName;
      control.PropertyName = property.PropertyName;
      control.PropertyDescriptionName = property.Description;
      control.Required = property.IsRequired;
      control.IncludeRequiredIcon = property.IsRequired;
      control.ReadOnly = property.IsReadOnly;
      control.Flags = property.Flags;
      if (!control.Flags) {
        control.Flags = [];
      }
      control.Properties = property.Cargo;
      if (!control.Properties) {
        control.Properties = {};
      }
      control.OptionsPickListId = property.PickListId;
      control.OptionsPickListFilter = property.PickListFilter;
      if (property.PickList && property.PickList.length > 0) {
        control.OptionsPickList = Helper.arraySort(property.PickList, "DisplayText");
      }
      control.OptionsIncludeNone = property.PickListIncludeOptionForNone;
      // Use helper functions that accept string or enum since we are typed as enum but api returns string so we need to check type before comparing.
      control.OptionsValueIsInteger = FormHelper.typeCodeIsInteger(property.DataType);
      if (FormHelper.typeCodeIsInteger(property.DataType)) {
        control.PropertyDataType = "Integer";
        control.ControlType = "InputNumber";
      } else if (FormHelper.typeCodeIsFloat(property.DataType)) {
        control.PropertyDataType = "Float";
        control.ControlType = "InputNumber";
      } else if (FormHelper.typeCodeIsDateTime(property.DataType)) {
        control.PropertyDataType = "DateTime";
        control.ControlType = "InputDateTime";
      } else if (FormHelper.typeCodeIsBoolean(property.DataType)) {
        control.PropertyDataType = "Boolean";
        control.ControlType = "InputCheckbox";
      } else if (FormHelper.typeCodeIsString(property.DataType) && property.IsCollection) {
        control.PropertyDataType = "ListString";
        control.ControlType = "InputStringList";
        // For adding single item to list we want the singular version in the control watermark.
        control.Watermark = Helper.plural(property.InputLabel || property.Description, 1, false);
      } else if (FormHelper.typeCodeIsObject(property.DataType) && property.IsCollection) {
        control.PropertyDataType = "ListObject";
        control.ControlType = "InputObjectList";
        if (property.Properties && property.Properties.length > 0) {
          control.Properties.objectProperties = property.Properties;
        }
      } else {
        control.PropertyDataType = "String";
        control.ControlType = "InputText";
      }
      // Convert from string to enum.  Typed as enum but api returns string so we need to check type before comparing.
      let inputControlType: m.InputControlTypeOption = property.InputControlType;
      if (typeof property.InputControlType === "string") {
        inputControlType = m.InputControlTypeOption[property.InputControlType as string];
      }
      // If InputControlType is Auto then we made that assignment above
      if (inputControlType === m.InputControlTypeOption.CheckBox) {
        control.ControlType = "InputCheckbox";
      } else if (inputControlType === m.InputControlTypeOption.Date) {
        control.ControlType = "InputDate";
      } else if (inputControlType === m.InputControlTypeOption.DateTime) {
        control.ControlType = "InputDateTime";
      } else if (inputControlType === m.InputControlTypeOption.Integer) {
        control.ControlType = "InputNumber";
      } else if (inputControlType === m.InputControlTypeOption.Float) {
        control.ControlType = "InputNumber";
      } else if (inputControlType === m.InputControlTypeOption.DropDown) {
        control.ControlType = "InputSelect";
      } else if (inputControlType === m.InputControlTypeOption.DropDownList) {
        control.ControlType = "InputSelect";
      } else if (inputControlType === m.InputControlTypeOption.MultiSelectDropDownList) {
        control.ControlType = "InputMultiSelect";
        if (!property.IsCollection) {
          Log.warningMessage(`${property.PropertyName} is configured for multi-select input but is not flagged as a collection data type.`);
        }
      } else if (inputControlType === m.InputControlTypeOption.ListOfStrings) {
        control.ControlType = "InputStringList";
      } else if (inputControlType === m.InputControlTypeOption.ListOfObjects) {
        control.ControlType = "InputObjectList";
      } else if (inputControlType === m.InputControlTypeOption.SingleLineEditor) {
        control.ControlType = "InputText";
      } else if (inputControlType === m.InputControlTypeOption.SingleLineEditorAutoComplete) {
        control.ControlType = "InputText";
      } else if (inputControlType === m.InputControlTypeOption.MultiLineEditor) {
        control.ControlType = "InputTextArea";
      }
      let inputControlWidth: m.InputControlWidthOption = property.InputControlWidth;
      if (typeof property.InputControlWidth === "string") {
        inputControlWidth = m.InputControlTypeOption[property.InputControlWidth as string];
      }
      if (inputControlWidth === m.InputControlWidthOption.Full) {
        control.Width = "F";
      } else if (inputControlWidth === m.InputControlWidthOption.Large) {
        control.Width = "W";
      } else if (inputControlWidth === m.InputControlWidthOption.Medium) {
        control.Width = "M";
      } else if (inputControlWidth === m.InputControlWidthOption.Small) {
        control.Width = "N";
      } else if (inputControlWidth === m.InputControlWidthOption.XSmall) {
        control.Width = "T";
      } else {
        control.Width = "W";
      }
      control.Mask = property.InputMask;
      group.Controls.push(control);
    });

    form.Groups.push(group);
    return form;

  }


  public static buildFormDataObjectFromPropertyMetaData(properties: m.PropertyMetaDataViewModel[]): any {

    const formData: any = {};
    const formDataProcessedObjects: string[] = [];

    if (!properties || properties.length === 0) {
      return formData;
    }

    properties.forEach(property => {
      if (property.IsHidden) {
        // No property for input needed for hidden properties
        return; // i.e. continue
      }
      if (property.IsCollection) {
        formData[property.PropertyName] = [];
      } else {
        // Use helper functions that accept string or enum since we are typed as enum but api returns string so we need to check type before comparing.
        if (property.DefaultValue) {
          if (FormHelper.typeCodeIsString(property.DataType)) {
            formData[property.PropertyName] = property.DefaultValue;
          } else if (FormHelper.typeCodeIsInteger(property.DataType)) {
            formData[property.PropertyName] = parseInt(property.DefaultValue, 10);
          } else if (FormHelper.typeCodeIsFloat(property.DataType)) {
            formData[property.PropertyName] = parseFloat(property.DefaultValue);
          } else if (FormHelper.typeCodeIsDateTime(property.DataType)) {
            formData[property.PropertyName] = new Date(property.DefaultValue);
          } else if (FormHelper.typeCodeIsBoolean(property.DataType)) {
            formData[property.PropertyName] = Helper.equals(property.DefaultValue, "true", true);
          }
        } else {
          if (FormHelper.typeCodeIsString(property.DataType)) {
            formData[property.PropertyName] = "";
          } else if (FormHelper.typeCodeIsNumber(property.DataType)) {
            formData[property.PropertyName] = 0;
          } else if (FormHelper.typeCodeIsDateTime(property.DataType)) {
            formData[property.PropertyName] = new Date();
          } else if (FormHelper.typeCodeIsBoolean(property.DataType)) {
            formData[property.PropertyName] = false;
          }
        }
      }
    });

    return formData;

  }


  public static typeCodeIsObject(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return Helper.equals(dataType as string, "Object", true);
    } else {
      return (dataType === m.System.TypeCode.Object);
    }
  }

  public static typeCodeIsString(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return Helper.equals(dataType as string, "String", true);
    } else {
      return (dataType === m.System.TypeCode.String);
    }
  }

  public static typeCodeIsDateTime(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return Helper.equals(dataType as string, "DateTime", true);
    } else {
      return (dataType === m.System.TypeCode.DateTime);
    }
  }

  public static typeCodeIsBoolean(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return Helper.equals(dataType as string, "Boolean", true);
    } else {
      return (dataType === m.System.TypeCode.Boolean);
    }
  }

  public static typeCodeIsInteger(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return (Helper.startsWith(dataType as string, "Int", true) || Helper.startsWith(dataType as string, "UInt", true));
    } else {
      return (dataType === m.System.TypeCode.Int16 || dataType === m.System.TypeCode.Int32 || dataType === m.System.TypeCode.Int64 ||
        dataType === m.System.TypeCode.UInt16 || dataType === m.System.TypeCode.UInt32 || dataType === m.System.TypeCode.UInt64);
    }
  }

  public static typeCodeIsFloat(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    if (Helper.isString(dataType)) {
      return (Helper.equals(dataType as string, "Single", true) || Helper.equals(dataType as string, "Double", true) || Helper.equals(dataType as string, "Decimal", true));
    } else {
      return (dataType === m.System.TypeCode.Single || dataType === m.System.TypeCode.Double || dataType === m.System.TypeCode.Decimal);
    }
  }

  public static typeCodeIsNumber(dataType: m.System.TypeCode | string): boolean {
    if (!dataType) {
      return false;
    }
    return (FormHelper.typeCodeIsInteger(dataType) || FormHelper.typeCodeIsFloat(dataType));
  }



  public static buildFormDataObject(form: m5web.FormEditViewModel): any {

    const formData: any = {};
    const formDataProcessedObjects: string[] = [];

    if (!form || !form.Groups || form.Groups.length === 0) {
      return formData;
    }

    // Process each group in the form (child groups and controls will be handled in the group method)
    form.Groups.forEach(group => {
      FormHelper.buildFormGroupData(group, formData, formDataProcessedObjects);
    });

    return formData;

  }


  protected static buildFormGroupData(group: m5web.FormControlGroupEditViewModel, formData: any, formDataProcessedObjects: string[]) {
    // Process any controls we have in our group
    group.Controls.forEach(control => {
      if (control.ObjectName) {
        if (!formDataProcessedObjects.includes(control.ObjectName)) {
          formData[control.ObjectName] = {};
          formDataProcessedObjects.push(control.ObjectName);
        }
        if (control.PropertyName) {
          if (Helper.equals(control.PropertyDataType, "string", true)) {
            formData[control.ObjectName][control.PropertyName] = control.Contents;
          } else if (Helper.equals(control.PropertyDataType, "boolean", true)) {
            formData[control.ObjectName][control.PropertyName] = false;
          } else if (Helper.equals(control.PropertyDataType, "DateTime", true)) {
            formData[control.ObjectName][control.PropertyName] = null;
          }
        }
        if (control.ObjectChildName) {
          if (!formDataProcessedObjects.includes(control.ObjectName + "." + control.ObjectChildName)) {
            formData[control.ObjectName][control.ObjectChildName] = {};
            formDataProcessedObjects.push(control.ObjectName + "." + control.ObjectChildName);
          }
        }
        if (control.ObjectGrandchildName) {
          if (!formDataProcessedObjects.includes(control.ObjectName + "." + control.ObjectChildName + "." + control.ObjectGrandchildName)) {
            formData[control.ObjectName][control.ObjectChildName][control.ObjectGrandchildName] = {};
            formDataProcessedObjects.push(control.ObjectName + "." + control.ObjectChildName + "." + control.ObjectGrandchildName);
          }
        }
      }
    });
    // Now process child groups
    group.Groups.forEach(child => {
      FormHelper.buildFormGroupData(child, formData, formDataProcessedObjects);
    });
  }


  public static setFormLayout(form: m5web.FormEditViewModel, labelLayout: string, labelBold: boolean, controlLayoutTight: boolean) {
    // Set for the form
    form.LabelLayout = labelLayout;
    form.LabelBold = labelBold;
    form.ControlLayoutTight = controlLayoutTight;
    // Now process each group groups
    form.Groups.forEach(group => {
      FormHelper.setFormGroupLayout(group, labelLayout, labelBold, controlLayoutTight);
    });
  }

  protected static setFormGroupLayout(group: m5web.FormControlGroupEditViewModel, labelLayout: string, labelBold: boolean, controlLayoutTight: boolean) {
    // Set for this group
    group.LabelLayout = labelLayout;
    group.LabelBold = labelBold;
    group.ControlLayoutTight = controlLayoutTight;
    // Process any controls we have in our group
    group.Controls.forEach(control => {
      control.LabelLayout = labelLayout;
      control.LabelBold = labelBold;
    });
    // Now process child groups
    group.Groups.forEach(child => {
      FormHelper.setFormGroupLayout(child, labelLayout, labelBold, controlLayoutTight);
    });
  }




  public static parseProperties(properties: any, type: string): any {
    if (!properties) {
      return {};
    } else if (Helper.isObject(properties)) {
      return properties;
    } else if (properties) {
      try {
        return JSON.parse(properties);
      } catch (err) {
        Log.errorMessage(`Error converting ${type} properties string to json.  Properties: ${properties}`);
        Log.errorMessage(err);
        return {};
      }
    } else {
      return {};
    }
  }


  /**
   * Builds html that uses same layout we use for forms for times we want
   * dynamic html injected like table row expansion html.
   * @param label
   * @param value
   * @returns
   */
  public static buildStaticHtmlFormLayoutForLabelValue(label: string, value: any, tightness: number = 0, boldLabel: boolean = false): string {
    let formGroupClass = "form-group";
    if (tightness == 1) {
      formGroupClass += " form-group-tight";
    } else if (tightness >= 2) {
      formGroupClass += " form-group-tightest";
    }
    if (boldLabel) {
      label = `<strong>${label}</strong>`;
    }
    let html: string = "";
    html += `<div class="${formGroupClass} row">`;
    html += `  <div class="${Constants.Layout.split2label} control-label">`;
    html += `    ${label}`;
    html += `  </div>`;
    html += `  <div class="${Constants.Layout.split2input}">`;
    html += `    ${value}`;
    html += `  </div>`;
    html += `</div>`;
    return html;
  }


}
