import { Component, OnInit, OnChanges, SimpleChanges, forwardRef, Input, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { InputBaseComponent } from 'projects/common-lib/src/lib/input/input-base-component';
import { Helper, Log } from 'projects/core-lib/src/lib/helpers/helper';
import { ApiService } from 'projects/core-lib/src/lib/api/api.service';
import { UxService } from '../../services/ux.service';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputRadioComponent),
  multi: true
};

@Component({
  selector: 'ib-input-radio',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class InputRadioComponent extends InputBaseComponent implements OnInit, OnChanges, ControlValueAccessor {

  // Note that we have several @Input() and @Output() declarations in the base class.
  @Input() public inline: boolean = false;
  @Input() public options: IInputRadioOption[] = [];

  /**
  When true (the default) space where labels appear for other controls is used for spacing
  with this input so the controls align since this control's label appears after the input.
  */
  @Input() alignWithOtherControls: boolean = true;


  constructor(protected apiService: ApiService, protected uxService: UxService) {
    super(apiService, uxService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.configure();
    // console.error(this.options);
  }

  public configure() {

    // Call the base class configure method to handle a lot of this
    super.configure();

    if (!this.inline) {
      this.inputWrapperClass += " form-check";
    }
    // For inline each option has form-check and form-check-inline classes in their own div

    // console.error(this.options);

  }


  protected onPickListReady() {
    super.onPickListReady();
    // For radio buttons we might be using a pick list for our radio options
    if (this.pickList && this.pickList.length > 0 && (!this.options || this.options.length === 0)) {
      this.options = [];
      this.pickList.forEach(item => {
        this.options.push({ value: item.Value, label: item.DisplayText });
      });
    }
  }


}

export interface IInputRadioOption {
  value: any; // string;
  label: string;
}
