// !!! This is generated code... DO NOT modify this file !!!
import * as m from './ngCoreModels';


/**
 * @name Partition Route
 * @description
 * This contains information about a partition route.
 */
export class PartitionRouteEditViewModel {

    /**
     * @name PartitionRouteId
     * @description
     * The partition route id.
     * @servertype Int64
     * @type {number}
     */
    PartitionRouteId: number = 0;

    /**
     * @name Description
     * @description
     * The description for this route.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RouteUrl
     * @description
     * The url for this route.  When this route is entered it will either redirect to the redirect url or render the specified asset
     * or form.  This is a relative route not including host.
     * @servertype String
     * @type {string}
     */
    RouteUrl: string = "";

    /**
     * @name RedirectToUrl
     * @description
     * Url to redirect to when this route is hit.
     * @servertype String
     * @type {string}
     */
    RedirectToUrl: string = "";

    /**
     * @name AssetId
     * @description
     * If the route maps to an asset this is the asset id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name AssetTitle
     * @description
     * If the route maps to an asset this is the read-only asset title.
     * @servertype String
     * @type {string}
     */
    AssetTitle: string = "";

    /**
     * @name FormId
     * @description
     * If the route maps to a form this is the form id.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = 0;

    /**
     * @name FormDescription
     * @description
     * If the route maps to a form this is the read-only form description.
     * @servertype String
     * @type {string}
     */
    FormDescription: string = "";

    /**
     * @name FormContext
     * @description
     * Form context: list, add, edit, or view
     * @servertype String
     * @type {string}
     */
    FormContext: string = "";

    /**
     * @name AllowAnonymousAccess
     * @description
     * True if anonymous access is allowed to the route.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name HideStandardSiteElements
     * @description
     * True if the contents displayed for this route should hide standard site features like header, footer, menu, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    HideStandardSiteElements: boolean = false;

    /**
     * @name Properties
     * @description
     * Properties related to this route.  These are set as route data that can be used at run time by the template rendered for
     * the route to make context based decisions.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationModel
 */
export class ApplicationInformationModel {

    /**
     * @name PartitionId
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionDescription
     * @servertype String
     * @type {string}
     */
    PartitionDescription: string = "";

    /**
     * @name Partition
     * @servertype IB.Web.API.Models.v5.PartitionEditViewModel
     * @type {PartitionEditViewModel}
     */
    Partition: PartitionEditViewModel = null;

    /**
     * @name Domain
     * @servertype IB.Web.API.Models.v5.PartitionDomainEditViewModel
     * @type {PartitionDomainEditViewModel}
     */
    Domain: PartitionDomainEditViewModel = null;

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name Tags
     * @servertype Dictionary<string, List<string>>
     * @type {{[key: string]:  string[]}}
     */
    Tags: {[key: string]:  string[]} = {};

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name AnonymousApiKey
     * @servertype String
     * @type {string}
     */
    AnonymousApiKey: string = "";

    /**
     * @name Theme
     * @servertype String
     * @type {string}
     */
    Theme: string = "";

    /**
     * @name LogoUrl
     * @servertype String
     * @type {string}
     */
    LogoUrl: string = "";

    /**
     * @name DomainName
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name SslEnabled
     * @servertype Boolean
     * @type {boolean}
     */
    SslEnabled: boolean = false;

    /**
     * @name SslRequired
     * @servertype Boolean
     * @type {boolean}
     */
    SslRequired: boolean = false;

    /**
     * @name Services
     * @servertype ApplicationInformationServiceModel array
     * @type {ApplicationInformationServiceModel[]}
     */
    Services: ApplicationInformationServiceModel[] = [];

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name Routes
     * @servertype ApplicationInformationRouteModel array
     * @type {ApplicationInformationRouteModel[]}
     */
    Routes: ApplicationInformationRouteModel[] = [];

    /**
     * @name Logos
     * @servertype ApplicationInformationLogoModel array
     * @type {ApplicationInformationLogoModel[]}
     */
    Logos: ApplicationInformationLogoModel[] = [];

    /**
     * @name Settings
     * @servertype IB.Web.API.Models.v5.AppSettingsEditViewModel
     * @type {AppSettingsEditViewModel}
     */
    Settings: AppSettingsEditViewModel = null;

}



/**
 * @name PartitionEditViewModel
 * @description
 * The Partition table contains information about table partitions typically done in a multi-company scenario where multiple
 * databases is not desired but data needs to be partitioned or in a multi-tenant environment where each partition represents
 * a client.  Note that this represents a logical and not necessarily a physical partition.
 */
export class PartitionEditViewModel {

    /**
     * @name PartitionId
     * @description
     * Unique value that represents this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Description
     * @description
     * The description of this partition.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @description
     * Display name that can be used for this partition.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name Brand
     * @description
     * The brand for this partition.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name Branding
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name PrivateBranded
     * @servertype Boolean
     * @type {boolean}
     */
    PrivateBranded: boolean = false;

    /**
     * @name CustomerName
     * @description
     * The customer name for this partition.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name ContactName
     * @description
     * The contact name for this partition.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactPhone
     * @description
     * The contact phone for this partition.
     * @servertype String
     * @type {string}
     */
    ContactPhone: string = "";

    /**
     * @name ContactEmail
     * @description
     * The contact email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name ContactAlternateEmail
     * @description
     * The contact alternate email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactAlternateEmail: string = "";

    /**
     * @name ContactId
     * @description
     * A Contact Id associated with this partition with contact information for the partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactInformationName
     * @description
     * The contact name for the contact associated with the partition.
     * @servertype String
     * @type {string}
     */
    ContactInformationName: string = "";

    /**
     * @name ProductionPartitionId
     * @description
     * The partition id used as production partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProductionPartitionId: number = null;

    /**
     * @name SandboxPartitionId
     * @description
     * The partition id used as sandbox partition for testing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SandboxPartitionId: number = null;

    /**
     * @name PrimaryShardId
     * @description
     * The shard id for the primary table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrimaryShardId: number = 0;

    /**
     * @name ReportShardId
     * @description
     * The shard id for the report table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReportShardId: number = 0;

    /**
     * @name CacheShardId
     * @description
     * The cache id for the cache table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheShardId: number = 0;

    /**
     * @name LogShardId
     * @description
     * The shard id for the log table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogShardId: number = 0;

    /**
     * @name ActivityShardId
     * @description
     * The shard id for the activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityShardId: number = 0;

    /**
     * @name ArchiveActivityShardId
     * @description
     * The shard id for the archive activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveActivityShardId: number = 0;

    /**
     * @name ArchiveShardId
     * @description
     * The shard id for the archive table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveShardId: number = 0;

    /**
     * @name Status
     * @description
     * Status of this partition.  Possible values are:
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusMessage
     * @description
     * A status message for this partition.
     * @servertype String
     * @type {string}
     */
    StatusMessage: string = "";

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this partition is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this partition is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ValidDateRangeBeforeMessage
     * @description
     * A message to display before the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeBeforeMessage: string = "";

    /**
     * @name ValidDateRangeAfterMessage
     * @description
     * A message to display after the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeAfterMessage: string = "";

    /**
     * @name Features
     * @description
     * A json object of features.  Object includes these properties:
     * InAppUpgradeAdvertising - When true the partition allows in app upgrade advertising.
     * @servertype IB.Web.API.Models.v5.PartitionFeaturesEditViewModel
     * @type {PartitionFeaturesEditViewModel}
     */
    Features: PartitionFeaturesEditViewModel = null;

    /**
     * @name Billing
     * @description
     * A json object of billing properties.  Object includes these properties:
     * ContactId - A billing contact id for this partition.
     * BillingAccountId - A billing account id for this partition.
     * @servertype IB.Web.API.Models.v5.PartitionBillingEditViewModel
     * @type {PartitionBillingEditViewModel}
     */
    Billing: PartitionBillingEditViewModel = null;

    /**
     * @name AllowAnonymousAccess
     * @description
     * True indicates unauthenticated users are not forced to a login page.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Services
     * @description
     * List of services for the partition.
     * @servertype PartitionServiceEditViewModel array
     * @type {PartitionServiceEditViewModel[]}
     */
    Services: PartitionServiceEditViewModel[] = [];

    /**
     * @name Domains
     * @description
     * List of domains for the partition.
     * @servertype PartitionDomainEditViewModel array
     * @type {PartitionDomainEditViewModel[]}
     */
    Domains: PartitionDomainEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionFeaturesEditViewModel
 */
export class PartitionFeaturesEditViewModel {

    /**
     * @name InAppUpgradeAdvertising
     * @description
     * When true the partition allows in app upgrade advertising.
     * @servertype Boolean
     * @type {boolean}
     */
    InAppUpgradeAdvertising: boolean = false;

}



/**
 * @name PartitionBillingEditViewModel
 */
export class PartitionBillingEditViewModel {

    /**
     * @name ContactId
     * @description
     * A billing contact id for this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name BillingAccountId
     * @description
     * A billing account id for this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    BillingAccountId: number = 0;

}



/**
 * @name PartitionServiceEditViewModel
 * @description
 * The Partition Service table contains a list services available for a partition.
 */
export class PartitionServiceEditViewModel {

    /**
     * @name PartitionServiceId
     * @description
     * Unique value that represents this partition service.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionServiceId: number = null;

    /**
     * @name PartitionId
     * @description
     * The partition this row belongs to.
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Description
     * @description
     * The description of this partition service.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name ServiceName
     * @description
     * The service name.
     * @servertype String
     * @type {string}
     */
    ServiceName: string = "";

    /**
     * @name ServiceClass
     * @description
     * The service class.
     * @servertype String
     * @type {string}
     */
    ServiceClass: string = "";

    /**
     * @name ServiceType
     * @description
     * The service type.
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name ServiceModules
     * @description
     * The modules included with service.
     * @servertype string array
     * @type {string[]}
     */
    ServiceModules: string[] = [];

    /**
     * @name Status
     * @description
     * Status of this partition.  Possible values are:
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

    /**
     * @name StatusMessage
     * @description
     * A status message for this partition.
     * @servertype String
     * @type {string}
     */
    StatusMessage: string = "";

    /**
     * @name StatusInformation
     * @description
     * Information about the current status of the service.
     * @servertype String
     * @type {string}
     */
    StatusInformation: string = "";

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this service is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this service is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name ValidDateRangeBeforeMessage
     * @description
     * A message to display before the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeBeforeMessage: string = "";

    /**
     * @name ValidDateRangeAfterMessage
     * @description
     * A message to display after the valid date range.
     * @servertype String
     * @type {string}
     */
    ValidDateRangeAfterMessage: string = "";

    /**
     * @name Features
     * @description
     * A json object of features.  Object includes these properties:
     * FreeTrial - When true the partition is for a free trial.
     * FreeTrialDays - The number of days for the free trial.
     * @servertype IB.Web.API.Models.v5.PartitionServiceFeaturesEditViewModel
     * @type {PartitionServiceFeaturesEditViewModel}
     */
    Features: PartitionServiceFeaturesEditViewModel = null;

    /**
     * @name Billing
     * @description
     * A json object of billing properties.  Object includes these properties:
     * PackageCode - A billing package code for this partition service.
     * PackageType - A billing package type for this partition service.
     * PackageId - A billing package id for this partition service.
     * PackageOccurrenceId - A billing package occurrence id for this partition service.
     * @servertype IB.Web.API.Models.v5.PartitionServiceBillingEditViewModel
     * @type {PartitionServiceBillingEditViewModel}
     */
    Billing: PartitionServiceBillingEditViewModel = null;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionServiceFeaturesEditViewModel
 */
export class PartitionServiceFeaturesEditViewModel {

    /**
     * @name FreeTrial
     * @description
     * True indicates the partition has a free trial in use.
     * @servertype Boolean
     * @type {boolean}
     */
    FreeTrial: boolean = false;

    /**
     * @name FreeTrialDays
     * @description
     * Duration of the free trial in days.  Note that valid end date time must be set independently of this value.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    FreeTrialDays: number = 0;

}



/**
 * @name PartitionServiceBillingEditViewModel
 */
export class PartitionServiceBillingEditViewModel {

    /**
     * @name PackageCode
     * @description
     * A billing package code for this partition service.
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageType
     * @description
     * A billing package type for this partition service.
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageId
     * @description
     * A billing package id for this partition service.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name PackageOccurrenceId
     * @description
     * A billing package occurrence id for this partition service.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageOccurrenceId: number = 0;

}



/**
 * @name PartitionDomainEditViewModel
 * @description
 * The Partition Domain table contains a list domain names available for a partition.
 */
export class PartitionDomainEditViewModel {

    /**
     * @name PartitionDomainId
     * @description
     * Unique value that represents this partition domain.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionDomainId: number = null;

    /**
     * @name PartitionId
     * @description
     * The partition this row belongs to.
     * @servertype Int64
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name PartitionServiceId
     * @description
     * If this domain is tied to one service this is the partition service id for that service.  Otherwise, all services defined
     * for the partition are considered to be in scope.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionServiceId: number = null;

    /**
     * @name Description
     * @description
     * The description of this partition domain.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayName
     * @description
     * Display name that can be used for this partition domain.
     * @servertype String
     * @type {string}
     */
    DisplayName: string = "";

    /**
     * @name DomainName
     * @description
     * The domain name.  For example: abc.myservice.com.
     * @servertype String
     * @type {string}
     */
    DomainName: string = "";

    /**
     * @name IpAddress
     * @description
     * The ip address the specified domain name resolves to.
     * @servertype String
     * @type {string}
     */
    IpAddress: string = "";

    /**
     * @name CustomDomainName
     * @description
     * When true the domain name is a custom domain provided by the client who is then responsible for DNS and SSL certificates.
     * @servertype Boolean
     * @type {boolean}
     */
    CustomDomainName: boolean = false;

    /**
     * @name SslEnabled
     * @description
     * When true SSL is enabled for the domain.
     * @servertype Boolean
     * @type {boolean}
     */
    SslEnabled: boolean = false;

    /**
     * @name SslRequired
     * @description
     * When true SSL is required for the domain.
     * @servertype Boolean
     * @type {boolean}
     */
    SslRequired: boolean = false;

    /**
     * @name SslExpirationDateTime
     * @description
     * The date and time when the SSL certificate expires.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    SslExpirationDateTime: Date = null;

    /**
     * @name Enabled
     * @description
     * When true the domain is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = true;

    /**
     * @name ValidStartDateTime
     * @description
     * The valid start date and time represents the beginning date and time when this domain is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidStartDateTime: Date = null;

    /**
     * @name ValidEndDateTime
     * @description
     * The valid end date and time represents the ending date and time when this domain is valid.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ValidEndDateTime: Date = null;

    /**
     * @name SiteTheme
     * @description
     * Site theme for the domain.
     * @servertype String
     * @type {string}
     */
    SiteTheme: string = "";

    /**
     * @name AllowAnonymousAccess
     * @description
     * True indicates unauthenticated users are not forced to a login page.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name LogoAssets
     * @description
     * A list of asset id values for assets that are used for logos.
     * @servertype long array
     * @type {number[]}
     */
    LogoAssets: number[] = [];

    /**
     * @name BrandId
     * @description
     * The brand to use for this partition domain.
     * @servertype IB.Data.Model.BrandId Enum
     * @type {m.BrandId}
     */
    BrandId: m.BrandId = null;

    /**
     * @name Brand
     * @description
     * A read-only string representation of the brand being used for this partition domain.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name Branding
     * @description
     * Branding information related to the brand.  This is read-only unless private branded.
     * @servertype IB.Data.Model.PartitionBrandModel
     * @type {m.PartitionBrandModel}
     */
    Branding: m.PartitionBrandModel = null;

    /**
     * @name PrivateBranded
     * @description
     * True indicates the domain is private branded.
     * @servertype Boolean
     * @type {boolean}
     */
    PrivateBranded: boolean = false;

    /**
     * @name ShowCopyrightMessage
     * @description
     * True indicates the UI should show the copyright message.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowCopyrightMessage: boolean = false;

    /**
     * @name ShowPoweredByMessage
     * @description
     * True indicates the UI should show the powered by message.
     * @servertype Boolean
     * @type {boolean}
     */
    ShowPoweredByMessage: boolean = false;

    /**
     * @name AssetFilterTags
     * @description
     * A list of asset tags to use for content filtering.
     * @servertype string array
     * @type {string[]}
     */
    AssetFilterTags: string[] = [];

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name ApplicationInformationServiceModel
 */
export class ApplicationInformationServiceModel {

    /**
     * @name Name
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Class
     * @servertype String
     * @type {string}
     */
    Class: string = "";

    /**
     * @name Type
     * @servertype String
     * @type {string}
     */
    Type: string = "";

    /**
     * @name Status
     * @servertype String
     * @type {string}
     */
    Status: string = "";

    /**
     * @name Modules
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

}



/**
 * @name ApplicationInformationRouteModel
 */
export class ApplicationInformationRouteModel {

    /**
     * @name PartitionRouteId
     * @servertype Int64
     * @type {number}
     */
    PartitionRouteId: number = 0;

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name RouteUrl
     * @servertype String
     * @type {string}
     */
    RouteUrl: string = "";

    /**
     * @name RedirectToUrl
     * @servertype String
     * @type {string}
     */
    RedirectToUrl: string = "";

    /**
     * @name AssetId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name FormId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    FormId: number = 0;

    /**
     * @name FormContext
     * @servertype String
     * @type {string}
     */
    FormContext: string = "";

    /**
     * @name AllowAnonymousAccess
     * @servertype Boolean
     * @type {boolean}
     */
    AllowAnonymousAccess: boolean = false;

    /**
     * @name HideStandardSiteElements
     * @servertype Boolean
     * @type {boolean}
     */
    HideStandardSiteElements: boolean = false;

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name ApplicationInformationLogoModel
 */
export class ApplicationInformationLogoModel {

    /**
     * @name Title
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name FriendlyName
     * @servertype String
     * @type {string}
     */
    FriendlyName: string = "";

    /**
     * @name AssetId
     * @servertype Int64
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name Url
     * @servertype String
     * @type {string}
     */
    Url: string = "";

    /**
     * @name FileType
     * @servertype String
     * @type {string}
     */
    FileType: string = "";

    /**
     * @name ContentType
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Height
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Height: number = 0;

    /**
     * @name Width
     * @servertype Int32 (nullable)
     * @type {number}
     */
    Width: number = 0;

}



/**
 * @name AppSettingsEditViewModel
 */
export class AppSettingsEditViewModel {

    /**
     * @name CompanyLongName
     * @servertype String
     * @type {string}
     */
    CompanyLongName: string = "";

    /**
     * @name CompanyShortName
     * @servertype String
     * @type {string}
     */
    CompanyShortName: string = "";

    /**
     * @name SystemAdminGroupId
     * @description
     * The group id used by system administrators.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SystemAdminGroupId: number = 0;

    /**
     * @name AssetAdminGroupId
     * @description
     * The group id used by asset administrators.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetAdminGroupId: number = 0;

    /**
     * @name PortalHomePage
     * @servertype String
     * @type {string}
     */
    PortalHomePage: string = "";

    /**
     * @name PortalHomePageCanBeCustomized
     * @servertype Boolean
     * @type {boolean}
     */
    PortalHomePageCanBeCustomized: boolean = false;

    /**
     * @name LanguageNative
     * @servertype String
     * @type {string}
     */
    LanguageNative: string = "";

    /**
     * @name LanguageDefault
     * @servertype String
     * @type {string}
     */
    LanguageDefault: string = "";

    /**
     * @name LanguagesSupported
     * @servertype string array
     * @type {string[]}
     */
    LanguagesSupported: string[] = [];

    /**
     * @name DefaultCaseTemplateId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DefaultCaseTemplateId: number = 0;

    /**
     * @name UseInternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    UseInternalCaseNumber: boolean = false;

    /**
     * @name UseExternalCaseNumber
     * @servertype Boolean
     * @type {boolean}
     */
    UseExternalCaseNumber: boolean = false;

    /**
     * @name Labels
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Labels: {[key: string]:  string} = {};

    /**
     * @name QuickStartCompletedDateTime
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    QuickStartCompletedDateTime: Date = null;

    /**
     * @name Menu
     * @servertype MenuItem array
     * @type {m.MenuItem[]}
     */
    Menu: m.MenuItem[] = [];

}



/**
 * @name PickListExcelMetaData
 */
export class PickListExcelMetaData {

    /**
     * @name PickListId
     * @description
     * The pick list id.
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name WorksheetName
     * @description
     * The worksheet name for this pick list.
     * @servertype String
     * @type {string}
     */
    WorksheetName: string = "";

    /**
     * @name WorksheetExists
     * @description
     * Flag if the worksheet exists in the file.
     * @servertype Boolean
     * @type {boolean}
     */
    WorksheetExists: boolean = false;

    /**
     * @name ColumnOffsetPickListValueId
     * @description
     * The zero based column offset for pick list value id within the worksheet.  -1 means this value is not included in the worksheet.
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetPickListValueId: number = 0;

    /**
     * @name ColumnOffsetDisplayText
     * @description
     * The zero based column offset for display text within the worksheet.  -1 means this value is not included in the worksheet.
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetDisplayText: number = 0;

    /**
     * @name ColumnOffsetValue
     * @description
     * The zero based column offset for value within the worksheet.  -1 means this value is not included in the worksheet.
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetValue: number = 0;

    /**
     * @name ColumnOffsetDisplayOrder
     * @description
     * The zero based column offset for display order within the worksheet.  -1 means this value is not included in the worksheet.
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetDisplayOrder: number = 0;

    /**
     * @name ColumnOffsetGroupText
     * @description
     * The zero based column offset for group text within the worksheet.  -1 means this value is not included in the worksheet.
     * @servertype Int32
     * @type {number}
     */
    ColumnOffsetGroupText: number = 0;

    /**
     * @name ColumnCount
     * @description
     * The number of columns populated for the pick list.
     * @servertype Int32
     * @type {number}
     */
    ColumnCount: number = 0;

    /**
     * @name PickListHierarchy
     * @description
     * A list of pick list id values that represent the pick list hierarchy for cascading pick lists.
     * @servertype string array
     * @type {string[]}
     */
    PickListHierarchy: string[] = [];

    /**
     * @name PickListHeaders
     * @description
     * A list of pick list headers that represent the headers for the pick list hierarchy for cascading pick lists.
     * @servertype string array
     * @type {string[]}
     */
    PickListHeaders: string[] = [];

}



/**
 * @name PickListImportResult
 */
export class PickListImportResult {

    /**
     * @name OriginalFileName
     * @description
     * The original file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    OriginalFileName: string = "";

    /**
     * @name MimeFileName
     * @description
     * The mime file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    MimeFileName: string = "";

    /**
     * @name FinalFileName
     * @description
     * The final file name of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    FinalFileName: string = "";

    /**
     * @name FinalFileFullPath
     * @description
     * The final file name and path of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    FinalFileFullPath: string = "";

    /**
     * @name Extension
     * @description
     * The extension of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    Extension: string = "";

    /**
     * @name ContentType
     * @description
     * The content type of the document that was uploaded.
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name DryRun
     * @description
     * When true the import is a dry-run showing what actions would be taken if processed in a non-dry-run scenario.  A dry-run
     * enables reviewing the actions that will be taken before they are performed.
     * @servertype Boolean
     * @type {boolean}
     */
    DryRun: boolean = false;

    /**
     * @name PickLists
     * @description
     * A list of pick list result objects one for each pick list processed during the import.
     * @servertype PickListImportSingleResult array
     * @type {PickListImportSingleResult[]}
     */
    PickLists: PickListImportSingleResult[] = [];

}



/**
 * @name PickListImportSingleResult
 */
export class PickListImportSingleResult {

    /**
     * @name PickListType
     * @description
     * The pick list type.
     * @servertype String
     * @type {string}
     */
    PickListType: string = "";

    /**
     * @name PickListId
     * @description
     * The pick list id.
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name ExcelMetaData
     * @description
     * Excel document meta data for this pick list.
     * @servertype IB.Web.API.Models.v5.PickListExcelMetaData
     * @type {PickListExcelMetaData}
     */
    ExcelMetaData: PickListExcelMetaData = null;

    /**
     * @name PickListCount
     * @description
     * The pick list count should match the number of items in the hierarchy.
     * @servertype Int32
     * @type {number}
     */
    PickListCount: number = 0;

    /**
     * @name PickListHierarchy
     * @description
     * A list of strings that represent the pick list hierarchy.  For pick lists that are not cascading this will contain only the
     * one pick list id.
     * @servertype string array
     * @type {string[]}
     */
    PickListHierarchy: string[] = [];

    /**
     * @name PickListEntryCountParsed
     * @description
     * The number of pick list entries parsed from the document.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountParsed: number = 0;

    /**
     * @name PickListEntryCountAdded
     * @description
     * The number of pick list entries that were added due to being new.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAdded: number = 0;

    /**
     * @name PickListEntryCountAlreadyAdded
     * @description
     * The number of pick list entries that would have been added except they were found to have already been added.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAlreadyAdded: number = 0;

    /**
     * @name PickListEntryCountUpdated
     * @description
     * The number of pick list entries that were updated due to being different from the current pick list entry.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountUpdated: number = 0;

    /**
     * @name PickListEntryCountUpdatedReferences
     * @description
     * The number of pick list entry references that were updated due to being different from the current pick list entry.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountUpdatedReferences: number = 0;

    /**
     * @name PickListEntryCountDeleted
     * @description
     * The number of pick list entries that were deleted due to having no value defined.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountDeleted: number = 0;

    /**
     * @name PickListEntryCountAlreadyDeleted
     * @description
     * The number of pick list entries that would have been deleted except they were found to have already been deleted.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountAlreadyDeleted: number = 0;

    /**
     * @name PickListEntryCountNotChanged
     * @description
     * The number of pick list entries that were not changed because they match the current pick list entry.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountNotChanged: number = 0;

    /**
     * @name PickListEntryCountWarning
     * @description
     * The number of warnings encountered during parsing, reading, or updating the pick list.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountWarning: number = 0;

    /**
     * @name PickListEntryCountError
     * @description
     * The number of errors encountered during parsing, reading, or updating the pick list.
     * @servertype Int32
     * @type {number}
     */
    PickListEntryCountError: number = 0;

    /**
     * @name PickListEntriesAdded
     * @description
     * A list of pick list entry id values that were added to the pick list.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAdded: number[] = [];

    /**
     * @name PickListEntriesAlreadyAdded
     * @description
     * A list of pick list entry id values that would have been added except they were found to have already been added.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAlreadyAdded: number[] = [];

    /**
     * @name PickListEntriesUpdated
     * @description
     * A list of pick list entry id values that were updated in the pick list.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesUpdated: number[] = [];

    /**
     * @name PickListEntriesUpdatedReferences
     * @description
     * A list of pick list entry id values that had updated references.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesUpdatedReferences: number[] = [];

    /**
     * @name PickListEntriesDeleted
     * @description
     * A list of pick list entry id values that were deleted from the pick list.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesDeleted: number[] = [];

    /**
     * @name PickListEntriesAlreadyDeleted
     * @description
     * A list of pick list entry id values that would have been deleted except they were found to have already been deleted.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesAlreadyDeleted: number[] = [];

    /**
     * @name PickListEntriesNotChanged
     * @description
     * A list of pick list entry id values that were in the document but had not changed from the current pick list entry.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesNotChanged: number[] = [];

    /**
     * @name PickListEntriesWarnings
     * @description
     * A list of pick list entry id values that encountered warnings during the process.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesWarnings: number[] = [];

    /**
     * @name PickListEntriesErrors
     * @description
     * A list of pick list entry id values that encountered errors during the process.
     * @servertype long array
     * @type {number[]}
     */
    PickListEntriesErrors: number[] = [];

    /**
     * @name Notices
     * @description
     * A list notices other than warnings or errors that occurred during the process.  This could be informational messages including
     * details of operations or proposed operations.
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Notices: PickListImportNotice[] = [];

    /**
     * @name Warnings
     * @description
     * A list warnings that occurred during the process.
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Warnings: PickListImportNotice[] = [];

    /**
     * @name Errors
     * @description
     * A list errors that occurred during the process.
     * @servertype PickListImportNotice array
     * @type {PickListImportNotice[]}
     */
    Errors: PickListImportNotice[] = [];

    /**
     * @name Trace
     * @description
     * A list of trace messages that can be used for debugging purposes.
     * @servertype string array
     * @type {string[]}
     */
    Trace: string[] = [];

}



/**
 * @name PickListImportNotice
 */
export class PickListImportNotice {

    /**
     * @name PickListId
     * @description
     * The pick list id of the pick list.  If null there was an error that was not related to a pick list entry.
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name PickListValueId
     * @description
     * The pick list value id of the pick list entry.  If null there was an error that was not related to a pick list value entry.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = 0;

    /**
     * @name OperationType
     * @description
     * The operation type for this pick list value.  A = Add; E = Edit; D = Delete; R = Read; O = Other; U = Unknown
     * @servertype String
     * @type {string}
     */
    OperationType: string = "";

    /**
     * @name ResultCode
     * @description
     * The result code related to this operation.
     * @servertype IB.Core.StandardResultCode Enum
     * @type {m.StandardResultCode}
     */
    ResultCode: m.StandardResultCode = null;

    /**
     * @name Message
     * @description
     * The message (if any) related to this operation.
     * @servertype String
     * @type {string}
     */
    Message: string = "";

    /**
     * @name CurrentValue
     * @description
     * When possible (e.g. not a read error) this is the current value of the pick list entry.
     * @servertype String
     * @type {string}
     */
    CurrentValue: string = "";

    /**
     * @name RequestedValue
     * @description
     * The requested value for this pick list entry as parsed from the document.
     * @servertype String
     * @type {string}
     */
    RequestedValue: string = "";

    /**
     * @name Details
     * @description
     * Details about operation (if any).
     * @servertype String
     * @type {string}
     */
    Details: string = "";

}



/**
 * @name PickListEditViewModel
 * @description
 * The Pick List table contains a list of pick lists used in the system.
 */
export class PickListEditViewModel {

    /**
     * @name PickListSurrogateId
     * @description
     * A surrogate id for the pick list.  The PickListId value is used as the primary pick list reference.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListSurrogateId: number = null;

    /**
     * @name PickListId
     * @description
     * The id of the pick list.  For example: ItemType, CaseType, etc.
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name PickListCategoryId
     * @description
     * The id of the pick list category for this pick list.
     * @servertype String
     * @type {string}
     */
    PickListCategoryId: string = null;

    /**
     * @name ParentPickListId
     * @description
     * If this is part of a cascading pick list this is the id of the pick list which is the parent of this pick list.
     * @servertype String
     * @type {string}
     */
    ParentPickListId: string = null;

    /**
     * @name Description
     * @description
     * Description for this pick list.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true this pick list is enabled and, therefore, visible in the ui.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description
     * The order to display the pick list in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name PickListSort
     * @description
     * The sorting for this pick list.  Possible values include:
     * D = Display Order Explicitly Set
     * T = Text Order (alphabetical)
     * V = Value Order
     * @servertype String
     * @type {string}
     */
    PickListSort: string = "T";

    /**
     * @name HasDisplayText
     * @description
     * When true this pick list has display text that may differ from the pick list value.
     * @servertype Boolean
     * @type {boolean}
     */
    HasDisplayText: boolean = false;

    /**
     * @name HasGrouping
     * @description
     * When true this pick list has grouping supported and pick list values can be assigned group text that will control what groups
     * the pick list values appear under.
     * @servertype Boolean
     * @type {boolean}
     */
    HasGrouping: boolean = false;

    /**
     * @name Scope
     * @description
     * For pick list that have values that are scoped this defines the scoping options for the pick list values.  For example:
     * OwnerResourceType=CaseTemplate will cause values to have option to be scoped to a specific case template.
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name Icon
     * @description
     * If the list has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name SystemPickListId
     * @description
     * An optional system pick list id for this pick list.  This is used for custom pick lists that need to be known for system
     * purposes.  For example, Report Compiler allows custom pick lists for various template types but if the pick list is used
     * with structural subject matter we need to know which custom pick list maps to 'Structural.ExternalSubject', etc.
     * @servertype String
     * @type {string}
     */
    SystemPickListId: string = "";

    /**
     * @name RequiredModules
     * @description
     * A list of modules required for showing this pick list.  If empty the pick list is displayed; otherwise, at least one of the
     * list modules is required for the list to be displayed.
     * @servertype string array
     * @type {string[]}
     */
    RequiredModules: string[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PickListCategoryEditViewModel
 * @description
 * The Pick List Category table contains a list of categories for various pick lists used in the system.
 */
export class PickListCategoryEditViewModel {

    /**
     * @name PickListCategorySurrogateId
     * @description
     * A surrogate id for the pick list category.  The PickListCategoryId value is used as the primary pick list category reference.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListCategorySurrogateId: number = null;

    /**
     * @name PickListCategoryId
     * @description
     * Identifier for this pick list category.
     * @servertype String
     * @type {string}
     */
    PickListCategoryId: string = "";

    /**
     * @name Description
     * @description
     * Description for this pick list category.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Enabled
     * @description
     * When true this category is enabled and, therefore, visible in the ui.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name DisplayOrder
     * @description
     * The order to display the pick list categories in.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Icon
     * @description
     * If the category has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name RequiredModules
     * @description
     * A list of modules required for showing this pick list category.  If empty the category is displayed; otherwise, at least
     * one of the list modules is required for the category to be displayed.
     * @servertype string array
     * @type {string[]}
     */
    RequiredModules: string[] = [];

    /**
     * @name ParentPickListCategoryId
     * @description
     * The parent pick list category (if any) for this category.
     * @servertype String
     * @type {string}
     */
    ParentPickListCategoryId: string = "";

    /**
     * @name PickLists
     * @description
     * List of pick lists for the pick list category.
     * @servertype PickListEditViewModel array
     * @type {PickListEditViewModel[]}
     */
    PickLists: PickListEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PickListSelectionViewModel
 */
export class PickListSelectionViewModel {

    /**
     * @name PickListId
     * @servertype String
     * @type {string}
     */
    PickListId: string = "";

    /**
     * @name DisplayOrder
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name GroupText
     * @servertype String
     * @type {string}
     */
    GroupText: string = "";

    /**
     * @name DisplayText
     * @servertype String
     * @type {string}
     */
    DisplayText: string = "";

    /**
     * @name Value
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Icon
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconColor
     * @servertype String
     * @type {string}
     */
    IconColor: string = "";

    /**
     * @name TextColor
     * @servertype String
     * @type {string}
     */
    TextColor: string = "";

    /**
     * @name Description
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Properties
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name IsDefaultValue
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefaultValue: boolean = false;

    /**
     * @name IsMutuallyExclusive
     * @servertype Boolean
     * @type {boolean}
     */
    IsMutuallyExclusive: boolean = false;

    /**
     * @name Children
     * @servertype PickListSelectionViewModel array
     * @type {PickListSelectionViewModel[]}
     */
    Children: PickListSelectionViewModel[] = [];

    /**
     * @name PickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = 0;

    /**
     * @name ParentPickListValueId
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentPickListValueId: number = 0;

}



/**
 * @name PickListValueEditViewModel
 * @description
 * The Pick List Value table contains a list of values for various pick lists used in the system.
 */
export class PickListValueEditViewModel {

    /**
     * @name PickListValueId
     * @description
     * The id of the pick list value.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PickListValueId: number = null;

    /**
     * @name PickListId
     * @description
     * The id of the pick list for this pick list value.  There are a few special pick list value categories not found in the pick
     * list table as follows:
     * 1. For pick lists that are used to populate drop down lists for attributes this value is in the format AttributeConfiguration.AttributeConfigurationId.
     *  For example: AttributeConfiguration.95487.
     * 2. For shared pick lists that are used to populate drop down lists for attributes this value is in the format AttributeConfiguration.Shared.ListName.
     *  For example: AttributeConfiguration.Shared.RoomNumber.
     * @servertype String
     * @type {string}
     */
    PickListId: string = null;

    /**
     * @name ParentPickListId
     * @description
     * If this is part of a cascading pick list this is the id of the pick list which is the parent of this pick list.
     * @servertype String
     * @type {string}
     */
    ParentPickListId: string = null;

    /**
     * @name ParentPickListValueId
     * @description
     * If this is part of a cascading pick list this is the id of the parent pick list value for which this pick list value is a
     * valid option.  This is the actual value utilized for matching pick list values.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ParentPickListValueId: number = null;

    /**
     * @name ParentPickListValue
     * @description
     * If this is part of a cascading pick list this is the value of the parent pick list value for which this pick list value is
     * a valid option.  This value is used for picking cascading pick list value that are valid based on the parent value.
     * @servertype String
     * @type {string}
     */
    ParentPickListValue: string = null;

    /**
     * @name DisplayOrder
     * @description
     * The order to display the pick list values in when the pick list is configured to sort by declared sort order.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name GroupText
     * @description
     * For pick lists that support grouping options this is optional group text.  All pick list values that have the same group
     * text are grouped together and sorting is applied within the groups.  Alternate translations can be found in the translation
     * table.
     * @servertype String
     * @type {string}
     */
    GroupText: string = "";

    /**
     * @name DisplayText
     * @description
     * The text to display when this pick list value is presented for selection.  Alternate translations can be found in the translation
     * table.
     * @servertype String
     * @type {string}
     */
    DisplayText: string = "";

    /**
     * @name Value
     * @description
     * Value to be stored when this pick list value is selected.
     * @servertype String
     * @type {string}
     */
    Value: string = "";

    /**
     * @name Icon
     * @description
     * If the pick list value has an icon associated with it this is the icon name.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name IconColor
     * @description
     * The color to use for the pick list value icon (if any).
     * @servertype String
     * @type {string}
     */
    IconColor: string = "";

    /**
     * @name TextColor
     * @description
     * The color to use for the pick list value text (if any).
     * @servertype String
     * @type {string}
     */
    TextColor: string = "";

    /**
     * @name Properties
     * @description
     * Properties related to this pick list value (if needed).
     * @servertype String
     * @type {string}
     */
    Properties: string = "";

    /**
     * @name MutuallyExclusive
     * @description
     * When true this pick list value can only be selected once when presented multiple times in a group of pick lists.
     * @servertype Boolean
     * @type {boolean}
     */
    MutuallyExclusive: boolean = false;

    /**
     * @name OwnerResourceType
     * @description
     * The owner resource type (e.g. table, etc.) for this pick list value allows filtering values to specific scenarios like a
     * specific case template, etc.
     * @servertype String
     * @type {string}
     */
    OwnerResourceType: string = "";

    /**
     * @name OwnerResourceId
     * @description
     * The owner resource id is the key of the owner resource type for this pick list value allows filtering values to specific
     * scenarios like a specific case template, etc.  For example, if the owner resource type is InventoryType this value would
     * be the InventoryTypeId.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    OwnerResourceId: number = null;

    /**
     * @name OwnerResourceId2
     * @description
     * The owner resource id 2 is the key of the owner resource type when that id is a string data type.
     * @servertype String
     * @type {string}
     */
    OwnerResourceId2: string = null;

    /**
     * @name Scope
     * @description
     * For pick list values that are only valid options in certain scenarios the scope provides the ability to filter for those
     * scenarios to present valid pick list options.
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name ContactId
     * @description
     * For pick list values that are custom for a user this is the contact id of that user.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactName
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name Enabled
     * @description
     * When true this pick list value is enabled and, therefore, visible in the ui.  If disabled for a specific contact id it will
     * suppress any appearance of pick list values that have the same value for this contact.  For example, if there is a pick list
     * value of TUESDAY with a null contact id (i.e. visible for everyone) and contact id 4431 would like to hide that from his
     * list a new entry would be created with contact id 4431, value TUESDAY with enabled false.  That would hide TUESDAY for contact
     * id 4431 but leave it visible for others.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Children
     * @servertype PickListValueEditViewModel array
     * @type {PickListValueEditViewModel[]}
     */
    Children: PickListValueEditViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Script
 * @description
 * This contains information about a script.
 */
export class ScriptViewModel {

    /**
     * @name Name
     * @description
     * The name for this script.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @description
     * A description of the script.  This could include things like purpose, what it does, etc.  Long technical descriptions are
     * better for the comments or as read me text in the code, etc.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Version
     * @description
     * The version number for this script.
     * @servertype Int32
     * @type {number}
     */
    Version: number = 0;

    /**
     * @name Enabled
     * @description
     * Flag if the script is enabled.  If disabled it will not compile or execute.  This provides a quick way to turn off a script.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Code
     * @description
     * A list of source code objects that make up the contents of this script.
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    Code: ScriptSourceViewModel[] = [];

    /**
     * @name FullyQualifiedTypeName
     * @description
     * Fully qualified type name that can be used for instantiating the primary class in the script.
     * @servertype String
     * @type {string}
     */
    FullyQualifiedTypeName: string = "";

    /**
     * @name Interface
     * @description
     * Interface being implemented by the primary class in the script.
     * @servertype String
     * @type {string}
     */
    Interface: string = "";

    /**
     * @name InstanceTypedAs
     * @description
     * The type to use for instances of the primary class in the script.  This may be the interface, a base class, or the fully
     * qualified type name depending on the situation.
     * @servertype String
     * @type {string}
     */
    InstanceTypedAs: string = "";

    /**
     * @name EntryPoint
     * @description
     * The entry point to use for the primary class in the script.
     * @servertype String
     * @type {string}
     */
    EntryPoint: string = "";

    /**
     * @name EntryPointParameters
     * @description
     * Parameters for the entry point.
     * @servertype System.Object
     * @type {any}
     */
    EntryPointParameters: any = {};

    /**
     * @name PartialSourceCodeWrappers
     * @description
     * Any source code objects that are partial are concatenated together and inserted into the wrapper code found here based on
     * matching Group.  The concatenated partial code is injected in the wrapper where it says //INJECT-CODE-HERE//
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    PartialSourceCodeWrappers: ScriptSourceViewModel[] = [];

    /**
     * @name Snippets
     * @description
     * Source code snippets available for use in source code.
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    Snippets: ScriptSourceViewModel[] = [];

    /**
     * @name Language
     * @description
     * The primary language used in the source code.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name SupportedLanguages
     * @description
     * A list of supported languages.
     * @servertype string array
     * @type {string[]}
     */
    SupportedLanguages: string[] = [];

    /**
     * @name AllowMultipleLanguages
     * @description
     * When true multiple languages are allowed; otherwise, all source code objects inherit their language from the language setting.
     * @servertype Boolean
     * @type {boolean}
     */
    AllowMultipleLanguages: boolean = false;

    /**
     * @name ReferencedAssemblyDefaultFolder
     * @description
     * The default folder to use for any referenced assemblies that do not contain a folder.
     * @servertype String
     * @type {string}
     */
    ReferencedAssemblyDefaultFolder: string = "";

    /**
     * @name ReferencedAssemblies
     * @description
     * A list of assemblies referenced in any of the source code.  If not found in the default folder these should include the full
     * path to the assembly.
     * @servertype string array
     * @type {string[]}
     */
    ReferencedAssemblies: string[] = [];

    /**
     * @name CompiledCode
     * @description
     * A read-only list of code as submitted to the compiler based on source code, wrappers for partial code, etc.  For transpiled
     * languages like TypeScript this contains the output.
     * @servertype ScriptSourceViewModel array
     * @type {ScriptSourceViewModel[]}
     */
    CompiledCode: ScriptSourceViewModel[] = [];

    /**
     * @name UseCache
     * @description
     * Flag if script can be included in and served from cache.
     * @servertype Boolean
     * @type {boolean}
     */
    UseCache: boolean = false;

    /**
     * @name CompilerOptions
     * @description
     * Compiler options.
     * @servertype System.Object
     * @type {any}
     */
    CompilerOptions: any = {};

    /**
     * @name Properties
     * @description
     * Dynamic properties for the script.  For scripts that accept properties as input parameters this dynamic object is submitted
     * to the script.  This allows configuration settings to be stored in a single location so they can easily be identified and
     * changed as needed.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Any comments regarding this script.  Comments to specific code are typically better done in the source code itself.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name ApprovalPending
     * @description
     * Indicates the script is ready for review and approval.  This may also be triggered by script changes.
     * @servertype Boolean
     * @type {boolean}
     */
    ApprovalPending: boolean = false;

    /**
     * @name ApprovedDateTime
     * @description
     * The date and time the script was approved.  Depending on the scenario, scripts that are not approved may not be executed.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ApprovedDateTime: Date = null;

    /**
     * @name ApprovedByContactId
     * @description
     * The id of the contact who approved or reviewed the script.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ApprovedByContactId: number = null;

    /**
     * @name ApprovedByContactName
     * @description
     * The name of the contact who approved or reviewed the script.
     * @servertype String
     * @type {string}
     */
    ApprovedByContactName: string = "";

    /**
     * @name ApprovedScriptFingerprint
     * @description
     * A fingerprint of the script when it was approved.  Subsequent changes to the script will result in a fingerprint mismatch
     * and, depending on the scenario, scripts with a different fingerprint may not be executed.
     * @servertype String
     * @type {string}
     */
    ApprovedScriptFingerprint: string = "";

    /**
     * @name ApprovalComments
     * @description
     * Comments left by the person who reviewed and approved or declined to approve the script.
     * @servertype String
     * @type {string}
     */
    ApprovalComments: string = "";

}



/**
 * @name Script Source Code
 * @description
 * This contains information about script source code.
 */
export class ScriptSourceViewModel {

    /**
     * @name Group
     * @description
     * Group for this source code.  For partial code the group dictates which partials are combined together.
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name Order
     * @description
     * The order for the source code within the group.  In cases where the data is stored per object as opposed to an ordered collection
     * this is used to dictate the order for display and combining partial code.
     * @servertype Int32
     * @type {number}
     */
    Order: number = 0;

    /**
     * @name Name
     * @description
     * The name for this source code.  This should be reasonably meaningful.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name SourceCode
     * @description
     * The source code.
     * @servertype String
     * @type {string}
     */
    SourceCode: string = "";

    /**
     * @name Enabled
     * @description
     * Flag if the source code is enabled.  Disabled code is ignored and provides a quick mechanism for saving work-in-progress
     * code that is not ready, replacing code with a new version, testing code, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Partial
     * @description
     * Flag if the source code is partial.  Partial code is grouped together based on Group and Language.
     * @servertype Boolean
     * @type {boolean}
     */
    Partial: boolean = false;

    /**
     * @name ExcludeFromCode
     * @description
     * Flag if the source code is excluded from the rest of the code.  Unlike Enabled where the code is completely ignored, code
     * marked as excluded is not compiled but is sometimes delivered for meta purposes.  This is most often used for things like
     * read-me text, html markup used for end-user help, etc.
     * @servertype Boolean
     * @type {boolean}
     */
    ExcludeFromCode: boolean = false;

    /**
     * @name Language
     * @description
     * The language used in the source code.
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Usings
     * @description
     * A list of using references needed in the source.  This is needed when there is partial code that will be consolidated into
     * a single source that needs specific using references.  Non-partial code can have its own using references at the top of the
     * source code or here in this list.
     * @servertype string array
     * @type {string[]}
     */
    Usings: string[] = [];

    /**
     * @name Properties
     * @description
     * Any additional properties needed for the source code.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

}



/**
 * @name Script Library Reference
 * @description
 * This contains information about a library reference for a client script.
 */
export class ScriptLibraryReferenceViewModel {

    /**
     * @name Id
     * @description
     * An id for this library reference which should be unique in a list of referenced libraries.  For URLs this is the URL.  For
     * internal resources (e.g. assets) this is a unique reference for that resource (e.g. Asset12345).
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name ReferenceType
     * @description
     * A string indicating the type of resource: JS, EJS, CSS, ECSS where: JS = JavaScript URL (injected using script tag with src
     * property pointing to the URL in page head); EJS = Embedded JavaScript Code (injected using script tag with script contents
     * in page head); CSS = CSS URL (injected using link tag in page head); ECSS = Embedded CSS Code (injected using style tag in
     * page head)
     * @servertype String
     * @type {string}
     */
    ReferenceType: string = "";

    /**
     * @name Library
     * @description
     * The library URL or contents (for embedded JS or CSS).
     * @servertype String
     * @type {string}
     */
    Library: string = "";

    /**
     * @name WaitBeforeLoad
     * @description
     * A boolean flag if this library needs to wait for others to load before proceeding.  This is typically false unless the library
     * has a dependency on some other library being loaded before it.
     * @servertype Boolean
     * @type {boolean}
     */
    WaitBeforeLoad: boolean = false;

}



/**
 * @name Script Web Application
 * @description
 * This contains information about a script web application.
 */
export class ScriptWebApplicationViewModel {

    /**
     * @name AssetId
     * @description
     * The asset id (if any) for this web application.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AssetId: number = 0;

    /**
     * @name ExternalAssetId
     * @description
     * The external asset id (if any) for this web application.
     * @servertype String
     * @type {string}
     */
    ExternalAssetId: string = "";

    /**
     * @name AssetTitle
     * @description
     * The asset title (if any) for this web application.
     * @servertype String
     * @type {string}
     */
    AssetTitle: string = "";

    /**
     * @name AssetVersion
     * @description
     * The asset version (if any) for this web application.
     * @servertype Int32
     * @type {number}
     */
    AssetVersion: number = 0;

    /**
     * @name ScriptName
     * @description
     * The script name for this web application.
     * @servertype String
     * @type {string}
     */
    ScriptName: string = "";

    /**
     * @name ScriptVersion
     * @description
     * The script version for this web application.
     * @servertype Int32
     * @type {number}
     */
    ScriptVersion: number = 0;

    /**
     * @name Html
     * @description
     * HTML for this web application.
     * @servertype String
     * @type {string}
     */
    Html: string = "";

    /**
     * @name Css
     * @description
     * CSS for this web application.
     * @servertype String
     * @type {string}
     */
    Css: string = "";

    /**
     * @name JavaScript
     * @description
     * JavaScript for this web application.
     * @servertype String
     * @type {string}
     */
    JavaScript: string = "";

    /**
     * @name Data
     * @description
     * Data objects for this web application.
     * @servertype System.Object
     * @type {any}
     */
    Data: any = {};

    /**
     * @name Other
     * @description
     * Other information for this web application.  This is a dictionary with key being the code name and value being the source
     * code that was flagged to be excluded from the code.
     * @servertype Dictionary<string, string>
     * @type {{[key: string]:  string}}
     */
    Other: {[key: string]:  string} = {};

    /**
     * @name Libraries
     * @description
     * A list of library references for this web application.
     * @servertype ScriptLibraryReferenceViewModel array
     * @type {ScriptLibraryReferenceViewModel[]}
     */
    Libraries: ScriptLibraryReferenceViewModel[] = [];

    /**
     * @name Properties
     * @description
     * Properties defined for this web application.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name DataStoragePropertyName
     * @description
     * The property name where data storage can be found for this web app.
     * @servertype String
     * @type {string}
     */
    DataStoragePropertyName: string = "";

}



/**
 * @name ScriptLanguage Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguage {

    /**
     * C#
     */
    CSharp = 0,

    /**
     * Visual Basic
     */
    VisualBasic = 10,

    /**
     * C++
     */
    CPlusPlus = 20,

    /**
     * JavaScript (on server)
     */
    JavaScriptOnServer = 30,

    /**
     * TypeScript (on server)
     */
    TypeScriptOnServer = 40,

    /**
     * JavaScript
     */
    JavaScript = 1100,

    /**
     * TypeScript
     */
    TypeScript = 1101,

    /**
     * Html
     */
    Html = 1200,

    /**
     * CSS
     */
    Css = 1300,

    /**
     * Json
     */
    Json = 1400,

    /**
     * Text
     */
    Text = 1500,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name ScriptLanguageServerCommon Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguageServerCommon {

    /**
     * C#
     */
    CSharp = 0,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name ScriptLanguageClientCommon Enum
 * @readonly
 * @enum {number}
 */
export enum ScriptLanguageClientCommon {

    /**
     * JavaScript
     */
    JavaScript = 1100,

    /**
     * Html
     */
    Html = 1200,

    /**
     * CSS
     */
    Css = 1300,

    /**
     * Json
     */
    Json = 1400,

    /**
     * Text
     */
    Text = 1500,

    /**
     * Inherited from parent object language
     */
    Inherited = 2147483647

}



/**
 * @name TranslationAddMissingViewModel
 */
export class TranslationAddMissingViewModel {

    /**
     * @name NativeText
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name DesiredLanguage
     * @servertype String
     * @type {string}
     */
    DesiredLanguage: string = "";

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

}



/**
 * @name TranslationResponseViewModel
 */
export class TranslationResponseViewModel {

    /**
     * @name TranslatedText
     * @servertype String
     * @type {string}
     */
    TranslatedText: string = "";

    /**
     * @name Language
     * @servertype String
     * @type {string}
     */
    Language: string = "";

    /**
     * @name Information
     * @servertype Dictionary<string, object>
     * @type {{[key: string]:  any}}
     */
    Information: {[key: string]:  any} = {};

}



/**
 * @name TranslationRequestViewModel
 */
export class TranslationRequestViewModel {

    /**
     * @name NativeText
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name DesiredLanguage
     * @servertype String
     * @type {string}
     */
    DesiredLanguage: string = "";

    /**
     * @name Scope
     * @servertype String
     * @type {string}
     */
    Scope: string = "";

    /**
     * @name Parameters
     * @servertype System.Object
     * @type {any}
     */
    Parameters: any = {};

    /**
     * @name AddIfMissing
     * @servertype Boolean
     * @type {boolean}
     */
    AddIfMissing: boolean = false;

    /**
     * @name IgnoreCache
     * @servertype Boolean
     * @type {boolean}
     */
    IgnoreCache: boolean = false;

}



/**
 * @name TranslationEditViewModel
 * @description
 * The translation table contains a list of translation for things like pick list values, extended attributes, etc.
 */
export class TranslationEditViewModel {

    /**
     * @name TranslationId
     * @description
     * The id of the translation item.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TranslationId: number = null;

    /**
     * @name NativeLanguage
     * @description
     * Language of the native text.
     * @servertype String
     * @type {string}
     */
    NativeLanguage: string = "";

    /**
     * @name TranslatedLanguage
     * @description
     * Language identifier that represents the language used for this translation.
     * @servertype String
     * @type {string}
     */
    TranslatedLanguage: string = "";

    /**
     * @name NativeText
     * @description
     * Native text to be translated.  This is the look up value and can also be a key that is unique within the scope to identify
     * the text to be translated.
     * @servertype String
     * @type {string}
     */
    NativeText: string = "";

    /**
     * @name FullNativeText
     * @description
     * Full native text to be translated.  Since native text is a lookup key it is limited to 500 characters but for text longer
     * than that the full text is found here.
     * @servertype String
     * @type {string}
     */
    FullNativeText: string = "";

    /**
     * @name TranslatedText
     * @description
     * Translated text for the native text in the specified language.
     * @servertype String
     * @type {string}
     */
    TranslatedText: string = "";

    /**
     * @name TranslatedDateTime
     * @description
     * The date and time when the translation was done.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    TranslatedDateTime: Date = null;

    /**
     * @name TranslatedByContactId
     * @description
     * The contact id that represents who performed the last translation.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TranslatedByContactId: number = null;

    /**
     * @name TranslatedByContactName
     * @servertype String
     * @type {string}
     */
    TranslatedByContactName: string = "";

    /**
     * @name ReviewedDateTime
     * @description
     * The date and time when the translation was last reviewed.  The status will reflect the outcome of the review.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    ReviewedDateTime: Date = null;

    /**
     * @name ReviewedByContactId
     * @description
     * The contact id that represents who last reviewed this translation.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ReviewedByContactId: number = null;

    /**
     * @name ReviewedByContactName
     * @servertype String
     * @type {string}
     */
    ReviewedByContactName: string = "";

    /**
     * @name ReviewComments
     * @description
     * Comments entered about the review.  This can be used to explain why something was flagged for review or to add notes about
     * the review.
     * @servertype String
     * @type {string}
     */
    ReviewComments: string = "";

    /**
     * @name Status
     * @description
     * The current status of the translation.  Possible values include:
     * P = Pending Translation
     * A = Automatic Translation
     * M = Manual Translation
     * N = Not Approved
     * R = Reviewed
     * @servertype String
     * @type {string}
     */
    Status: string = "P";

    /**
     * @name Scope
     * @description
     * A list of scopes for this translation.  When empty the same translation is used anywhere needed.  When there is a scope provided
     * the translation is limited to that scope.  Possible values include: UI = User Interface (This is for standard UI elements
     * shared across all partitions.)
     * UI-Custom = Custom User Interface
     * Setting = Setting Value
     * Message = Message for Results, Errors, etc.
     * ReportTemplate = Report Template
     * ReportTemplate-[ReportTemplateId] = Report Template with specified id
     * PickList = Pick List
     * PickList-[PickListId] = Pick List with specified id
     * Asset = Asset
     * Asset-[AssetId] = Asset with specified id (internal or external)
     * Form = Form
     * Form-[FormId] = Form with specified id (internal or external)
     * [Custom] = Custom Scope
     * @servertype string array
     * @type {string[]}
     */
    Scope: string[] = [];

    /**
     * @name IncludeInExport
     * @description
     * When true this translation is included in language file exports.
     * @servertype Boolean
     * @type {boolean}
     */
    IncludeInExport: boolean = true;

    /**
     * @name LastUsedDateTime
     * @description
     * Date and time when the translation was last used.  This can be used to identify abandoned translations provided appropriate
     * care is taken to remember that translations that have been exported to external files may be actively used without this value
     * being updated.
     * @servertype DateTime (nullable)
     * @type {Date}
     */
    LastUsedDateTime: Date = null;

    /**
     * @name Comments
     * @description
     * Comments.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name PartitionListViewModel
 * @description
 * The Partition table contains information about table partitions typically done in a multi-company scenario where multiple
 * databases is not desired but data needs to be partitioned or in a multi-tenant environment where each partition represents
 * a client.  Note that this represents a logical and not necessarily a physical partition.
 */
export class PartitionListViewModel {

    /**
     * @name PartitionId
     * @description
     * Unique value that represents this partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PartitionId: number = null;

    /**
     * @name Tag
     * @description
     * A short random string combined with the partition id to create a unique reference that includes the partition id so the object
     * can be found quickly (via id) with a random component (by verifying the tag once found via id).  This may be desired to minimize
     * guessing or war dialing of partition id values especially when one needs to be shared like in partner json tokens.
     * @servertype String
     * @type {string}
     */
    Tag: string = "";

    /**
     * @name Description
     * @description
     * The description of this partition.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name Brand
     * @description
     * The brand for this partition.
     * @servertype String
     * @type {string}
     */
    Brand: string = "";

    /**
     * @name CustomerName
     * @description
     * The customer name for this partition.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name ContactName
     * @description
     * The contact name for this partition.
     * @servertype String
     * @type {string}
     */
    ContactName: string = "";

    /**
     * @name ContactPhone
     * @description
     * The contact phone for this partition.
     * @servertype String
     * @type {string}
     */
    ContactPhone: string = "";

    /**
     * @name ContactEmail
     * @description
     * The contact email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactEmail: string = "";

    /**
     * @name ContactAlternateEmail
     * @description
     * The contact alternate email for this partition.
     * @servertype String
     * @type {string}
     */
    ContactAlternateEmail: string = "";

    /**
     * @name ContactId
     * @description
     * A Contact Id associated with this partition with contact information for the partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ContactId: number = null;

    /**
     * @name ContactInformationName
     * @description
     * The contact name for the contact associated with the partition.
     * @servertype String
     * @type {string}
     */
    ContactInformationName: string = "";

    /**
     * @name ProductionPartitionId
     * @description
     * The partition id used as production partition.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProductionPartitionId: number = null;

    /**
     * @name SandboxPartitionId
     * @description
     * The partition id used as sandbox partition for testing.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    SandboxPartitionId: number = null;

    /**
     * @name PrimaryShardId
     * @description
     * The shard id for the primary table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    PrimaryShardId: number = 0;

    /**
     * @name ReportShardId
     * @description
     * The shard id for the report table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ReportShardId: number = 0;

    /**
     * @name CacheShardId
     * @description
     * The cache id for the cache table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    CacheShardId: number = 0;

    /**
     * @name LogShardId
     * @description
     * The shard id for the log table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    LogShardId: number = 0;

    /**
     * @name ActivityShardId
     * @description
     * The shard id for the activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ActivityShardId: number = 0;

    /**
     * @name ArchiveActivityShardId
     * @description
     * The shard id for the archive activity table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveActivityShardId: number = 0;

    /**
     * @name ArchiveShardId
     * @description
     * The shard id for the archive table group for this partition.
     * @servertype Int32 (nullable)
     * @type {number}
     */
    ArchiveShardId: number = 0;

    /**
     * @name Status
     * @description
     * Status of this partition.  Possible values are:
     * P = Pending
     * H = Hold
     * A = Active
     * D = Disabled
     * C = Closed
     * E = Expired
     * @servertype String
     * @type {string}
     */
    Status: string = "A";

}



/**
 * @name Subscription Config
 * @description
 * Configuration settings for subscription process.
 */
export class SubscriptionConfigViewModel {

    /**
     * @name SubscriptionConfigId
     * @description
     * The id for the subscription configuration.
     * @servertype Int64
     * @type {number}
     */
    SubscriptionConfigId: number = 0;

    /**
     * @name Name
     * @description
     * The name of this config.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @description
     * The description for the subscription configuration.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name IsDefault
     * @description
     * If true, this is the default config.
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefault: boolean = false;

    /**
     * @name DefaultFreeTrialDays
     * @description
     * The default number of free trial days.
     * @servertype Int32
     * @type {number}
     */
    DefaultFreeTrialDays: number = 0;

    /**
     * @name MinimumFreeTrialDays
     * @description
     * The minimum number of free trial days.
     * @servertype Int32
     * @type {number}
     */
    MinimumFreeTrialDays: number = 0;

    /**
     * @name MaximumFreeTrialDays
     * @description
     * The maximum number of free trial days.
     * @servertype Int32
     * @type {number}
     */
    MaximumFreeTrialDays: number = 0;

    /**
     * @name DefaultFreeTrialUnits
     * @description
     * The default number of free trial units.
     * @servertype Int32
     * @type {number}
     */
    DefaultFreeTrialUnits: number = 0;

    /**
     * @name MinimumFreeTrialUnits
     * @description
     * The minimum number of free trial units.
     * @servertype Int32
     * @type {number}
     */
    MinimumFreeTrialUnits: number = 0;

    /**
     * @name MaximumFreeTrialUnits
     * @description
     * The maximum number of free trial units.
     * @servertype Int32
     * @type {number}
     */
    MaximumFreeTrialUnits: number = 0;

    /**
     * @name FreeTrialUnitType
     * @description
     * The type of free trial unit.
     * @servertype String
     * @type {string}
     */
    FreeTrialUnitType: string = "";

    /**
     * @name Groups
     * @description
     * This is a list of all supported product tier groups.
     * @servertype SubscriptionConfigOptionGroupViewModel array
     * @type {SubscriptionConfigOptionGroupViewModel[]}
     */
    Groups: SubscriptionConfigOptionGroupViewModel[] = [];

    /**
     * @name ExistingOrganizationHideAllElements
     * @description
     * When true all elements are hidden when an existing organization is selected.
     * @servertype Boolean
     * @type {boolean}
     */
    ExistingOrganizationHideAllElements: boolean = false;

    /**
     * @name SignUpAlwaysTrialMode
     * @description
     * When true sign up is always a trial mode sign up.
     * @servertype Boolean
     * @type {boolean}
     */
    SignUpAlwaysTrialMode: boolean = false;

    /**
     * @name DataSourcePartitionId
     * @description
     * The partition id to use as data source for new partitions created for organizations.  Can be overridden by subscription option
     * setting.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourcePartitionId: number = 0;

    /**
     * @name AdminRoleId
     * @description
     * The role id to use for admin for new subscription for organizations.  Note this may get mapped to a copy if local partition
     * copies are being executed.  Can be overridden by subscription option setting.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminRoleId: number = 0;

    /**
     * @name AdminGroupId
     * @description
     * The group id to use for admin for new subscription for organizations.  Note this may get mapped to a copy if local partition
     * copies are being executed.  Can be overridden by subscription option setting.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminGroupId: number = 0;

    /**
     * @name NotificationGroupId
     * @description
     * The notification group id to trigger when a new subscription is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name ProcessTemplateId
     * @description
     * The process template id to trigger when a new subscription is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @description
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name CaseTemplateId
     * @description
     * The case template id to trigger when a new subscription is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name WebhookId
     * @description
     * The webhook id to trigger when a new subscription is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name TriggerAssetId
     * @description
     * The trigger asset id to trigger when a new subscription is created.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = 0;

    /**
     * @name Properties
     * @description
     * Properties for this subscription config.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments for this subscription config.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name Steps
     * @description
     * A list of steps for the config.
     * @servertype SubscriptionConfigStepViewModel array
     * @type {SubscriptionConfigStepViewModel[]}
     */
    Steps: SubscriptionConfigStepViewModel[] = [];

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subscription Config Option Group
 * @description
 * Describes and links to a group of subscription options that are available with this config.
 */
export class SubscriptionConfigOptionGroupViewModel {

    /**
     * @name Group
     * @description
     * A group that refers to a subscription option group.
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name Description
     * @description
     * A description for this group.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DisplayOrder
     * @description
     * The display order for this group.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name IsDefault
     * @description
     * If true, this is the default group.
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefault: boolean = false;

}



/**
 * @name Subscription Config Step
 * @description
 * Configuration settings for a step in the subscription process.
 */
export class SubscriptionConfigStepViewModel {

    /**
     * @name Enabled
     * @description
     * When true the step is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Title
     * @description
     * Title for this step.
     * @servertype String
     * @type {string}
     */
    Title: string = "";

    /**
     * @name ContentType
     * @description
     * The content type for this step. Allows the step to be tied to specific HTML.
     * @servertype String
     * @type {string}
     */
    ContentType: string = "";

    /**
     * @name Icon
     * @description
     * Icon for this step.
     * @servertype String
     * @type {string}
     */
    Icon: string = "";

    /**
     * @name EnabledStepContent
     * @description
     * A list of which content is enabled for this step.
     * @servertype string array
     * @type {string[]}
     */
    EnabledStepContent: string[] = [];

    /**
     * @name ContentTop
     * @description
     * Content to show above step contents.
     * @servertype String
     * @type {string}
     */
    ContentTop: string = "";

    /**
     * @name EnableContentTop
     * @description
     * When true, Content Top will be visible.
     * @servertype Boolean
     * @type {boolean}
     */
    EnableContentTop: boolean = false;

    /**
     * @name InstructionsTop
     * @description
     * Instructions to display at the top of the step.
     * @servertype String
     * @type {string}
     */
    InstructionsTop: string = "";

    /**
     * @name EnableInstructionsTop
     * @description
     * When true, Instructions Top will be visible.
     * @servertype Boolean
     * @type {boolean}
     */
    EnableInstructionsTop: boolean = false;

    /**
     * @name InstructionsTopStyle
     * @description
     * Style to use for instructions displayed at the top of the step.
     * @servertype String
     * @type {string}
     */
    InstructionsTopStyle: string = "";

    /**
     * @name InstructionsBottom
     * @description
     * Instructions to display at the bottom of the step.
     * @servertype String
     * @type {string}
     */
    InstructionsBottom: string = "";

    /**
     * @name EnableInstructionsBottom
     * @description
     * When true, Instructions Bottom will be visible.
     * @servertype Boolean
     * @type {boolean}
     */
    EnableInstructionsBottom: boolean = false;

    /**
     * @name InstructionsBottomStyle
     * @description
     * Style to use for instructions displayed at the bottom of the step.
     * @servertype String
     * @type {string}
     */
    InstructionsBottomStyle: string = "";

    /**
     * @name ContentBottom
     * @description
     * Content to show below step contents.
     * @servertype String
     * @type {string}
     */
    ContentBottom: string = "";

    /**
     * @name EnableContentBottom
     * @description
     * When true, Content Bottom will be visible.
     * @servertype Boolean
     * @type {boolean}
     */
    EnableContentBottom: boolean = false;

    /**
     * @name PreviousButtonText
     * @description
     * The text to use for the previous button for this step.
     * @servertype String
     * @type {string}
     */
    PreviousButtonText: string = "";

    /**
     * @name PreviousButtonIcon
     * @description
     * The icon to use for the previous button for this step.
     * @servertype String
     * @type {string}
     */
    PreviousButtonIcon: string = "";

    /**
     * @name PreviousButtonColor
     * @description
     * The color to use for the previous button for this step.
     * @servertype String
     * @type {string}
     */
    PreviousButtonColor: string = "";

    /**
     * @name NextButtonText
     * @description
     * The text to use for the next button for this step.
     * @servertype String
     * @type {string}
     */
    NextButtonText: string = "";

    /**
     * @name NextButtonIcon
     * @description
     * The icon to use for the next button for this step.
     * @servertype String
     * @type {string}
     */
    NextButtonIcon: string = "";

    /**
     * @name NextButtonColor
     * @description
     * The color to use for the next button for this step.
     * @servertype String
     * @type {string}
     */
    NextButtonColor: string = "";

    /**
     * @name CancelButtonText
     * @description
     * The text to use for the cancel button for this step.
     * @servertype String
     * @type {string}
     */
    CancelButtonText: string = "";

    /**
     * @name CancelButtonIcon
     * @description
     * The icon to use for the cancel button for this step.
     * @servertype String
     * @type {string}
     */
    CancelButtonIcon: string = "";

    /**
     * @name CancelButtonColor
     * @description
     * The color to use for the cancel button for this step.
     * @servertype String
     * @type {string}
     */
    CancelButtonColor: string = "";

}



/**
 * @name Subscription Sign Up
 * @description
 * This describes a subscription sign up request.
 */
export class SubscriptionSignUpRequestViewModel {

    /**
     * @name SubscriptionOptionId
     * @description
     * The id for the selected subscription option.
     * @servertype Int64
     * @type {number}
     */
    SubscriptionOptionId: number = 0;

    /**
     * @name SubscriptionOptionName
     * @description
     * The name for the selected subscription option.
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionName: string = "";

    /**
     * @name SubscriptionOptionPriceId
     * @description
     * The id of the subscription option price selected.
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionPriceId: string = "";

    /**
     * @name SubscriptionOptionPriceDescription
     * @description
     * The description of the subscription option price selected.
     * @servertype String
     * @type {string}
     */
    SubscriptionOptionPriceDescription: string = "";

    /**
     * @name Trial
     * @description
     * A flag if the subscription sign-up request is for a trial.
     * @servertype Boolean
     * @type {boolean}
     */
    Trial: boolean = false;

    /**
     * @name CustomerName
     * @description
     * The customer name for the subscription request.
     * @servertype String
     * @type {string}
     */
    CustomerName: string = "";

    /**
     * @name AdminFirstName
     * @description
     * The first name for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminFirstName: string = "";

    /**
     * @name AdminLastName
     * @description
     * The last name for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminLastName: string = "";

    /**
     * @name AdminPhone
     * @description
     * The phone for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminPhone: string = "";

    /**
     * @name AdminEmail
     * @description
     * The email for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminEmail: string = "";

    /**
     * @name AdminLogin
     * @description
     * The login for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminLogin: string = "";

    /**
     * @name AdminPassword
     * @description
     * The password for the admin for this subscription request.
     * @servertype String
     * @type {string}
     */
    AdminPassword: string = "";

    /**
     * @name CaptchaId
     * @description
     * The Id for the captcha account being used.
     * @servertype String
     * @type {string}
     */
    CaptchaId: string = "";

    /**
     * @name CaptchaType
     * @description
     * The type of captcha being used.
     * @servertype String
     * @type {string}
     */
    CaptchaType: string = "";

    /**
     * @name CaptchaValue
     * @description
     * The response value given by the captcha after the user completes it.
     * @servertype String
     * @type {string}
     */
    CaptchaValue: string = "";

    /**
     * @name QueryParameters
     * @description
     * Dynamic object for all query string parameters.
     * @servertype System.Object
     * @type {any}
     */
    QueryParameters: any = {};

}



/**
 * @name Subscription Option
 * @description
 * This describes a subscription option.
 */
export class SubscriptionOptionViewModel {

    /**
     * @name SubscriptionOptionId
     * @description
     * The id for this subscription option.
     * @servertype Int64
     * @type {number}
     */
    SubscriptionOptionId: number = 0;

    /**
     * @name Enabled
     * @description
     * A flag if this option is enabled.
     * @servertype Boolean
     * @type {boolean}
     */
    Enabled: boolean = false;

    /**
     * @name Group
     * @description
     * The group for this subscription option.  The group setting can be used to present only subscription options belonging to
     * the same group.
     * @servertype String
     * @type {string}
     */
    Group: string = "";

    /**
     * @name TargetAudienceDescription
     * @description
     * A description of the target audience for this subscription option.  This may help users select the option best for their
     * scenario.
     * @servertype String
     * @type {string}
     */
    TargetAudienceDescription: string = "";

    /**
     * @name Name
     * @description
     * The name for this subscription option.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @description
     * The description for this subscription option.  May include html and be used as a tooltip to provide more details.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DescriptionTitle
     * @description
     * A title for the description for this subscription option.  May include html and be used as a tooltip to provide more details.
     * @servertype String
     * @type {string}
     */
    DescriptionTitle: string = "";

    /**
     * @name IsFeatured
     * @description
     * When true the option is the featured subscription option.
     * @servertype Boolean
     * @type {boolean}
     */
    IsFeatured: boolean = false;

    /**
     * @name FeaturedDescription
     * @description
     * A description for the featured subscription option.
     * @servertype String
     * @type {string}
     */
    FeaturedDescription: string = "";

    /**
     * @name IsDefault
     * @description
     * When true the option is the default (i.e. preselected) subscription option.
     * @servertype Boolean
     * @type {boolean}
     */
    IsDefault: boolean = false;

    /**
     * @name Brand
     * @description
     * The brand for this subscription option.  This is saved as the partition brand.
     * @servertype IB.Data.Model.BrandId Enum
     * @type {m.BrandId}
     */
    Brand: m.BrandId = null;

    /**
     * @name ServiceName
     * @description
     * The service name for this subscription option.  This is saved as the partition service name.
     * @servertype String
     * @type {string}
     */
    ServiceName: string = "";

    /**
     * @name ServiceClass
     * @description
     * The service class for this subscription option.  This is saved as the partition service class.
     * @servertype String
     * @type {string}
     */
    ServiceClass: string = "";

    /**
     * @name ServiceType
     * @description
     * The service type for this subscription option.  This is saved as the partition service type.
     * @servertype String
     * @type {string}
     */
    ServiceType: string = "";

    /**
     * @name Modules
     * @description
     * A list of modules for this subscription option.  This is merged with any modules for the subscription option price.
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name PackageType
     * @description
     * A package type for this subscription option.  This can be overridden by a package type for the subscription option price.
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @description
     * A package code for this subscription option.  This can be overridden by a package code for the subscription option price.
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageId
     * @description
     * A package id for this subscription option.  This can be overridden by a package id for the subscription option price.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name ButtonSignUpLabel
     * @description
     * The label to use for the sign up button.
     * @servertype String
     * @type {string}
     */
    ButtonSignUpLabel: string = "";

    /**
     * @name ButtonSignUpIcon
     * @description
     * The icon to use for the sign up button.
     * @servertype String
     * @type {string}
     */
    ButtonSignUpIcon: string = "";

    /**
     * @name ButtonTrialLabel
     * @description
     * The label to use for the trial button.
     * @servertype String
     * @type {string}
     */
    ButtonTrialLabel: string = "";

    /**
     * @name ButtonTrialIcon
     * @description
     * The icon to use for the trial button.
     * @servertype String
     * @type {string}
     */
    ButtonTrialIcon: string = "";

    /**
     * @name TrialDays
     * @description
     * The number of days for trial.
     * @servertype Int32
     * @type {number}
     */
    TrialDays: number = 0;

    /**
     * @name DataSourcePartitionId
     * @description
     * The partition id to use as data source for new partitions created for organizations choosing this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    DataSourcePartitionId: number = 0;

    /**
     * @name AdminRoleId
     * @description
     * The role id to use for admin for new subscription for organizations choosing this subscription option.  Note this may get
     * mapped to a copy if local partition copies are being executed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminRoleId: number = 0;

    /**
     * @name AdminGroupId
     * @description
     * The group id to use for admin for new subscription for organizations choosing this subscription option.  Note this may get
     * mapped to a copy if local partition copies are being executed.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    AdminGroupId: number = 0;

    /**
     * @name NotificationGroupId
     * @description
     * The notification group id to trigger when a new subscription is created with this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    NotificationGroupId: number = 0;

    /**
     * @name ProcessTemplateId
     * @description
     * The process template id to trigger when a new subscription is created with this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    ProcessTemplateId: number = 0;

    /**
     * @name ProcessPeerId
     * @description
     * For processes that must execute peer operations chronologically this is a peer id used to identify peers.  When not specified
     * the process template id will be used to identify peers but providing a value here allows more definitive identification of
     * peers for scenarios where a template is used by non-peers.
     * @servertype String
     * @type {string}
     */
    ProcessPeerId: string = "";

    /**
     * @name CaseTemplateId
     * @description
     * The case template id to trigger when a new subscription is created with this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    CaseTemplateId: number = 0;

    /**
     * @name WebhookId
     * @description
     * The webhook id to trigger when a new subscription is created with this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    WebhookId: number = 0;

    /**
     * @name TriggerAssetId
     * @description
     * The trigger asset id to trigger when a new subscription is created with this subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    TriggerAssetId: number = 0;

    /**
     * @name Properties
     * @description
     * Properties for this subscription option.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments for this subscription option.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name EmailVerificationRequired
     * @description
     * When true, email verification is required.
     * @servertype Boolean
     * @type {boolean}
     */
    EmailVerificationRequired: boolean = false;

    /**
     * @name DisablePublicEmailServices
     * @description
     * When true, public email services are disabled.
     * @servertype Boolean
     * @type {boolean}
     */
    DisablePublicEmailServices: boolean = false;

    /**
     * @name BlockedEmailDomains
     * @description
     * A list of blocked email domains. Ex: outlook.com, gmail.com, yahoo.com, etc.
     * @servertype string array
     * @type {string[]}
     */
    BlockedEmailDomains: string[] = [];

    /**
     * @name Features
     * @description
     * A list of features for this subscription option.
     * @servertype SubscriptionOptionFeatureViewModel array
     * @type {SubscriptionOptionFeatureViewModel[]}
     */
    Features: SubscriptionOptionFeatureViewModel[] = [];

    /**
     * @name Prices
     * @description
     * A list of prices for this subscription option.
     * @servertype SubscriptionOptionPriceViewModel array
     * @type {SubscriptionOptionPriceViewModel[]}
     */
    Prices: SubscriptionOptionPriceViewModel[] = [];

    /**
     * @name DisplayOrder
     * @description
     * The display order for this subscription option.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

    /**
     * @name Private
     * @description
     * A flag if this subscription option is private.
     * @servertype Boolean
     * @type {boolean}
     */
    Private: boolean = false;

    /**
     * @name TimeZone
     * @description
     * The time zone that all date time properties are expressed in.
     * @servertype String
     * @type {string}
     */
    TimeZone: string = "";

    /**
     * @name MetaData
     * @description
     * Meta data about the object.
     * @servertype IB.Data.Model.IMetaDataModel
     * @type {m.MetaDataModel}
     */
    MetaData: m.MetaDataModel = new m.MetaDataModel();

}



/**
 * @name Subscription Option Feature
 * @description
 * This describes a feature for a subscription option.
 */
export class SubscriptionOptionFeatureViewModel {

    /**
     * @name Name
     * @description
     * The name for this subscription option feature.  May include html.
     * @servertype String
     * @type {string}
     */
    Name: string = "";

    /**
     * @name Description
     * @description
     * The description for this subscription option feature.  May include html and be used as a tooltip to provide more details.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name DescriptionTitle
     * @description
     * A title for the description for this subscription option feature.
     * @servertype String
     * @type {string}
     */
    DescriptionTitle: string = "";

    /**
     * @name Properties
     * @description
     * Properties for this subscription option feature.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments for this subscription option feature.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name DisplayOrder
     * @description
     * The display order for this subscription option feature.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

}



/**
 * @name Subscription Option Price
 * @description
 * This describes a price for a subscription option.
 */
export class SubscriptionOptionPriceViewModel {

    /**
     * @name Id
     * @description
     * A unique id for this subscription option price.
     * @servertype String
     * @type {string}
     */
    Id: string = "";

    /**
     * @name Description
     * @description
     * The description for this subscription option price.
     * @servertype String
     * @type {string}
     */
    Description: string = "";

    /**
     * @name PriceDescription
     * @description
     * A description for the price to display instead of building the description from other inputs.
     * @servertype String
     * @type {string}
     */
    PriceDescription: string = "";

    /**
     * @name PriceUnitDescription
     * @description
     * A description for the price unit to display instead of building the description from other inputs.
     * @servertype String
     * @type {string}
     */
    PriceUnitDescription: string = "";

    /**
     * @name IsoCurrencyCode
     * @description
     * The ISO currency code for this subscription option price.
     * @servertype String
     * @type {string}
     */
    IsoCurrencyCode: string = "";

    /**
     * @name Amount
     * @description
     * The amount for this subscription option price.
     * @servertype Double
     * @type {number}
     */
    Amount: number = 0;

    /**
     * @name IsPerUnit
     * @description
     * When true the price is per unit.
     * @servertype Boolean
     * @type {boolean}
     */
    IsPerUnit: boolean = false;

    /**
     * @name UnitDescription
     * @description
     * A description for the unit.  For example: User
     * @servertype String
     * @type {string}
     */
    UnitDescription: string = "";

    /**
     * @name TimePeriod
     * @description
     * The time period for this subscription option price.
     * @servertype IB.Web.API.Models.v5.SubscriptionOptionPriceTimePeriod Enum
     * @type {SubscriptionOptionPriceTimePeriod}
     */
    TimePeriod: SubscriptionOptionPriceTimePeriod = null;

    /**
     * @name Modules
     * @description
     * A list of modules for this subscription option price.  This is merged with any modules for the subscription option itself.
     * @servertype string array
     * @type {string[]}
     */
    Modules: string[] = [];

    /**
     * @name PackageType
     * @description
     * A package type for this subscription option price.  This will override a package type for the subscription option.
     * @servertype String
     * @type {string}
     */
    PackageType: string = "";

    /**
     * @name PackageCode
     * @description
     * A package code for this subscription option price.  This will override a package code for the subscription option.
     * @servertype String
     * @type {string}
     */
    PackageCode: string = "";

    /**
     * @name PackageId
     * @description
     * A package id for this subscription option price.  This will override a package id for the subscription option.
     * @servertype Int64 (nullable)
     * @type {number}
     */
    PackageId: number = 0;

    /**
     * @name Properties
     * @description
     * Properties for this subscription option price.
     * @servertype System.Object
     * @type {any}
     */
    Properties: any = {};

    /**
     * @name Comments
     * @description
     * Comments for this subscription option price.
     * @servertype String
     * @type {string}
     */
    Comments: string = "";

    /**
     * @name DisplayOrder
     * @description
     * The display order for this subscription option price.
     * @servertype Int32
     * @type {number}
     */
    DisplayOrder: number = 0;

}



/**
 * @name SubscriptionOptionPriceTimePeriod Enum
 * @readonly
 * @enum {number}
 */
export enum SubscriptionOptionPriceTimePeriod {

    /**
     * Month
     */
    Month = 0,

    /**
     * Year
     */
    Year = 1,

    /**
     * None
     */
    None = 2

}



