import { Component, Input, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api/menuitem';
import { Dock, DockModule } from 'primeng/dock';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { MenuService } from 'projects/core-lib/src/lib/services/menu.service';
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { CommonModule } from '@angular/common';
import { DockBackgroundTheme, DockPosition } from 'projects/core-lib/src/lib/config/AppConfig';
import { Helper } from 'projects/core-lib/src/lib/helpers/helper';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { NgbDropdownModule, NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { PanelMenuModule } from 'primeng/panelmenu';
import { ConfigHelpers } from 'projects/core-lib/src/lib/helpers/config-helpers';

@Component({
  standalone: true,
  imports: [DockModule, CommonModule, OverlayPanelModule, MenuModule, NgbModule, NgbPopoverModule, NgbDropdownModule, PanelMenuModule],
  selector: 'ib-image-dock',
  templateUrl: './image-dock.component.html',
  styleUrls: ['./image-dock.component.css']
})
export class ImageDockComponent extends BaseComponent {
  @Input() menuItems: MenuItem[] = [];
  @Input() position: DockPosition = "bottom";

  imageDockBackgroundTheme: DockBackgroundTheme = "transparent-dark";

  /** 
   * When a dock image is clicked and does not have a defined route but has children, a p-overlaypanel is 
   * shown with the children displayed as a p-menu
   */
  overlayPanelMenu: MenuItem[] = [];

  /** When an image in the dock is clicked, we store the corresponding menuItem in this variable. */
  lastClickedMenuItem: MenuItem | undefined = undefined;

  imageToolTipPosition: "bottom" | "top" | "left" | "right" = "bottom";

  /** Added to p-overlaypanel as a styleClass so we can find it's wrapper div in the DOM */
  overlayHijack = "overlay-hijack";

  @ViewChild('dockOverlayPanel') overlayPanel: OverlayPanel;

  constructor(
    protected menuService: MenuService,
    protected appService: AppService) {
    super();
  }


  ngOnInit() {
    this.imageDockBackgroundTheme = ConfigHelpers.getImageDockBackgroundThemeSetting();

    if (this.position === 'top') {
      this.imageToolTipPosition = 'bottom';
    } else if (this.position === 'bottom') {
      this.imageToolTipPosition = 'top';
    } else if (this.position === 'left') {
      this.imageToolTipPosition = 'right';
    } else if (this.position === 'right') {
      this.imageToolTipPosition = 'left';
    }
  }

  /**
   * There was a bug where the overlay panel would open in weird positions if you clicked one image
   * that opened an overlay panel and then clicked another image that opened an overlay panel without
   * manually closing the first overlay panel. This method closes the overlay panel when the mouse
   * enters an image that is not the last clicked image.
   */
  onMouseEnterImage($event, item: MenuItem) {
    if (item !== this.lastClickedMenuItem) {
      this.overlayPanel.hide();
    }
  }
  

  onImgSelect($event: MouseEvent, item: MenuItem) {
    this.lastClickedMenuItem = item;

    // For this to work, the menu item at root level can't have a route link,
    // otherwise we would route to that when they click it instead of opening the menu to see the children.
    if (item && item.items && item.items.length > 0) {
      this.overlayPanelMenu = item.items;
      this.overlayPanel.show($event);

      // Why not put an id on p-overlaypanel and use that to find it?
      // We need to adjust the styles of the div that wraps the overlay panel and primeNg adds the styleClasses 
      // to that wrapper div.
      // Why not pass in the styles via styleClass? PrimeNg overrides passed in styles when it comes to fixed 
      // position, changing the location, or the transformOrigin.
      let overlayDiv: HTMLElement | undefined = undefined;

      // without a timeout of 0, these values will be undefined.
      setTimeout(() => {
        const collection = document.getElementsByClassName('overlay-hijack');
        
        if (collection && collection.length > 0) {
          overlayDiv = collection[0] as HTMLElement;
        }

        // Now we can tweak the styles of the overlayPanelDiv. I experimented with manually changing it's position,
        // and that works, but what I really wanted to do is change transformOrigin so it opens up or down as desired,
        // but that doesn't work. I think it's because the overlay panel is already rendered and it's too late to change
        if (overlayDiv) {
          // The image dock is set to be fixed so make sure the overlay is as well
          overlayDiv.style.position = 'fixed';
        }
      }, 0);
    }
  }

  onMenuClick($event) {
    // For whatever reason, it's not closing when we click a menu item so we
    // manually close it here.
    this.overlayPanel.hide();
  }

  // Warning if you try to use these dock events, they don't work as expected:
  // Focus seems to only work if you click the padding around the image.
  // Blur only works if it has previously been focused, which is hard to do since you need to click
  // the padding around the image for focus to work.
  // onDockFocus($event) {
  //   console.log("onDockFocus: ", $event);
  // }
  // onDockBlur($event) {
  //   console.log("onDockBlur: ", $event);
  // }
}
