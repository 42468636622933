import { Component, Input } from '@angular/core';
import { NavService } from '../nav/nav.service';
import { TaskListStatusViewModel } from 'projects/core-lib/src/lib/models/ngModels5';
import * as m5sec from "projects/core-lib/src/lib/models/ngModelsSecurity5";
import { AppService } from 'projects/core-lib/src/lib/services/app.service';
import { BaseComponent } from 'projects/core-lib/src/lib/helpers/base-component';
import { takeUntil } from 'rxjs';
import { NgbPlacement } from 'projects/core-lib/src/lib/models/model-helpers';
import { AvatarComponent, AvatarTooltipPlacement } from '../../image/avatar/avatar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DecimalPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
  standalone: true,
  imports: [AvatarComponent, NgStyle, NgIf, NgClass, RouterLink, DecimalPipe, NgbModule],
  selector: 'ib-user-options',
  templateUrl: './user-options.component.html',
  styleUrls: ['./user-options.component.css']
})
export class UserOptionsComponent extends BaseComponent {
  
  @Input() dropdownPlacement: NgbPlacement = "bottom-right";
  @Input() buttonTooltipPlacement: NgbPlacement = "left";
  @Input() avatarTooltipPlacement: AvatarTooltipPlacement = "top";
  
  public taskStatus: TaskListStatusViewModel = new TaskListStatusViewModel();

  /** Currently authenticated user. */
  public get user(): m5sec.AuthenticatedUserViewModel {
    return this.appService.user;
  }

  get userName(): string {
    return this.appService.userOrDefault.ContactName;
  }

  constructor(
    public nav: NavService,
    protected appService: AppService
  ) { 
    super();
  }


  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.appService.taskStatusMonitor().pipe(takeUntil(this.ngUnsubscribe)).subscribe((status: TaskListStatusViewModel) => {
      if (status) {
        this.taskStatus = status;
      }
    });
  }

  logout() {
    this.appService.logout();
  }
}
